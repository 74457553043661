import { Container, Grid, makeStyles } from '@material-ui/core';
import CustomModal from 'components/CustomModal';
import TextField from 'components/CustomForms/TextField';
import ModalContents from 'components/CustomModal/ModalContents';
import ModalHeader from 'components/CustomModal/ModalHeader';
import React from 'react';
import { Box } from '@material-ui/core'
import { Editor, EditorContainer, EditorToolbar, InlineToggleButton, ToggleButtonGroup } from 'draft-js-wysiwyg';
import { EditorState } from 'draft-js';
import { FormatBold, FormatItalic } from '@material-ui/icons';
import styles  from "assets/jss/views/admin/broadcastViewStyle"
import Button from "components/CustomForms/Button"
import Select from 'react-select'
import Async, { useAsync } from 'react-select/async';


const useStyles = makeStyles(styles)

const CreateBroadcast = (props) => {
    
    const classes = useStyles()

    const {open, closeModal} = props

    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty()
      );
    
      const editor = React.useRef(null);
    
    //   React.useEffect(() => {
    //     if(editor){
    //         editor.current.focus();
    //     }
        
    //   }, []);
    

    return (
        <CustomModal open={open} closeModal={closeModal}>
            <ModalHeader title="Skapa nytt sändningsmeddelande" closeModal={closeModal}/>
            <ModalContents>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12} xs={12}>
                            {/* <TextField
                                labelText="Recepient"
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Inmatningsmottagare",
                                    
                                }}
                            /> */}
                            {/* <Select
                                isMulti
                                name="to_user_ids"
                                options={} */}
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <TextField
                                labelText="Ämne"
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Inmatningsämne",
                                    
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                        <EditorContainer
                            editorState={editorState}
                            style={{
                                border: '1px solid #323F4B',
                                borderRadius : '20px 20px 0px 0px'
                            }}
                            onChange={setEditorState}
                        >
                            <EditorToolbar style={{
                                borderBottom: '1px solid #323F4B'
                            }}>
                                <ToggleButtonGroup size="small">
                                <InlineToggleButton value="BOLD">
                                    <FormatBold style={{color: 'white'}}/>
                                </InlineToggleButton>
                                <InlineToggleButton value="ITALIC">
                                    <FormatItalic style={{color: 'white'}} />
                                </InlineToggleButton>
                                </ToggleButtonGroup>
                            </EditorToolbar>
                            <Editor className={classes.editor} ref={editor} placeholder="Skriv in lite text.." />
                        </EditorContainer>
                        </Grid>
                    </Grid>
                </Container>
            </ModalContents>
            <Box className={classes.modalFooter} textAlign="right">
                <Button variant="simple" onClick={ closeModal }>
                    Annulleraera
                </Button>
                <Button variant="contained" color="green" onClick={ closeModal }>
                    Spara
                </Button>
            </Box>
        </CustomModal>
    )
}

export default CreateBroadcast