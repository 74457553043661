import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import styles from "assets/jss/views/admin/dashboardViewStyle"
import { getTransactionService } from 'services/TransactionService'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const OrdersSection = () => {

  const classes = useStyles()
  const [transacitons, setTransaction] = useState([])
  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
    filter_by_date_string: 'today'
  })
  const getTransactionData = async() => {
    try{
      const res = await getTransactionService(query)
      setTransaction(res?.data ?? [])
    }catch(err) {

    }
  }

  useEffect(() => {
    getTransactionData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    getTransactionData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid item lg={5} md={5} sm={12} xs={12}>
      <Box className={classes.ordersSection}>
        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderTitleSection}>
          <Typography variant="body1">
            Order är idag
          </Typography>
          <FormControl variant="filled" className={classes.formControl}>          
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={query.filter_by_date_string}
              onChange={(e) => setQuery({...query, ...{filter_by_date_string: e.target.value}})}
            >              
              <MenuItem value="today">I dag</MenuItem>
              <MenuItem value="week">Vecka</MenuItem>
              <MenuItem value="month">Månad</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {
          transacitons.map((order,index) => (
          <Box className={classes.orderLine} key={index}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderDateLine}>
              <Typography variant="body1">
                { order.invoice_number }
              </Typography>
              <Typography variant="body1">
                { order.date }
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderPriceLine}>
              <Typography variant="body1">
                { numberFormat(order.grand_total) } {currency} 
              </Typography>
              <Box>
                <Typography variant="body1" className={classNames(classes.approved, 
                  {
                    [classes.approved]: order.status === "done",
                    [classes.pending]: order.status === "pending",
                    [classes.failed]: order.status === "void",
                  })}>
                  { order.transaction_status.name }
                </Typography>
              </Box>
            </Box>
          </Box>
          ))
        }                
      </Box>
    </Grid>
  )
}

export default OrdersSection
