import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, UPDATE_USER } from "./authType";

const STORAGE_VERSION = process.env.REACT_APP_STORAGE_VERSION

const initState = {
    isLoggedIn: false,
    token: '',
    user: {
        name: '',
        user_profile: '',
        roles: [],
        notification_pending_count : 0
    }
}

const authReducer = persistReducer(
    { storage, key: `auth-${STORAGE_VERSION}`, whitelist: ['isLoggedIn', 'token', 'user'] },
    (state = initState, action) => {

        const { type, payload } = action
        switch (type) {
            case LOGIN_SUCCESS:
                return {
                    user: payload.user,
                    token : payload.token,
                    isLoggedIn: true
                }
            case UPDATE_USER:
                return {
                    ...state,
                    user : payload
                };
            case LOGIN_FAIL:
                return initState
            case LOGOUT:
                return initState
            default:
                return state;
        }
    }
)



export default authReducer