import React, { useState, Fragment } from 'react'
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom"

import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import NotificationsIcon from '@material-ui/icons/Notifications'

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from '@material-ui/core/IconButton'
import { Box } from '@material-ui/core'
import Button from 'components/CustomForms/Button'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Fab from '@material-ui/core/Fab'

import userAvatar from "assets/img/checkout/user.png"
import styles from "assets/jss/components/staffNavbarStyle"

import Logo from "assets/img/footer-logo.png";
import { useDispatch, useSelector } from 'react-redux';
const useStyles = makeStyles(styles)

const StaffNavbar = (props) => {

    const classes = useStyles()

    const history = useHistory()

    const auth = useSelector((state) => state.user)

    const { user } = auth

    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        dispatch({ type: 'LOGOUT', payload: null })
        history.replace('/staff')
    }

    return (
        <Fragment>
            <AppBar position="sticky" className={classNames({ [classes.navbar]: true })}>
                <Toolbar>
                    <Fragment>
                        <Link
                            to={"/staff/dashboard"}
                        >
                            <img src={Logo} height={52} alt="logo" />
                        </Link>
                        <Box className={classes.grow} />
                        <IconButton className={classes.cartBtn} onClick={() => { history.push('/staff/notification') }}>
                            <Badge badgeContent={user?.notification_pending_count ?? 0} color="primary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <Button
                            className={classes.loggedBtn}
                            onClick={handleOpenMenu}
                        >
                            <img src={userAvatar} alt="Avatar" width="52px" height="52" />
                            {user.name}
                            <KeyboardArrowDownIcon />
                        </Button>
                    </Fragment>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        className={classes.profileMenu}
                    >
                        <MenuItem onClick={logout}>Logga Ut</MenuItem>
                    </Popover>
                </Toolbar>
            </AppBar>
        </Fragment>
    )

}

export default StaffNavbar