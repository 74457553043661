import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL + '/dashboard'

export const getBadgeDataDashboardService = props => {
    return request({
        url: BASE_URL + '/badge-data',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getChartDataDashboardService = props => {
    return request({
        url: BASE_URL + '/chart-data',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}