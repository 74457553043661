import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, FormHelperText, Hidden, MenuItem, Select } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/views/admin/userViewStyle"
import { Form, withFormik } from 'formik'
import * as Yup from 'yup'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { Alert, AlertTitle, InputLabel } from '@mui/material'
import { storeMdLocationPostalCodeService, updateMdLocationPostalCodeService } from 'services/MdLocationPostalCodeService'
import { getMdLocationStateService } from 'services/MdLocationStateService'
import { getMdLocationCityService } from 'services/MdLocationCityService'

const useStyles = makeStyles(styles)

const FormSchema = Yup.object().shape({
  md_location_city_id: Yup.string()
    .required('maximalt antal tecken 191'),
  postal_code: Yup.string()
    .min(5, 'minsta tecken är 5')
    .max(5, 'maximalt antal tecken 5')
    .required('Postnummer krävs'),
  price: Yup.number()
    .min(0, 'Minsta är 0')
    .required('Pris krävs')
})



const FormEnchancer = withFormik({
  mapPropsToValues: (props) => ({
    id: props.item.id || null,
    md_location_city_id: props.item.md_location_city_id || null,
    postal_code: props.item.postal_code || '',
    price: props.item.price || 0,
  }),
  validationSchema: props => FormSchema,
  enableReinitialize: true,
  handleSubmit: (values) => {

  },
  isInitialValid: props => FormSchema.isValidSync(props.item)
})

const EditMdLocationPostalCode = props => {

  const classes = useStyles()

  const {
    open,
    closeModal,
    item,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    handleReset,
    setFieldValue
  } = props;

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [cities, setCities] = useState([])

  const storeOrUpdate = async () => {
    try {
      if (!values.id) {
        await storeMdLocationPostalCodeService({ ...values })
      } else {
        await updateMdLocationPostalCodeService({ ...values })
      }
    } catch (error) {
      throw error
    }

  }

  const getStateData = async () => {
    try {
      const data = await getMdLocationCityService({
        limit: 1000,
        keyword: '',
        page: 1
      })

      setCities(data.data ?? [])
    } catch (err) {
      throw err
    }
  }

  const handleSubmit = async () => {

    if (isValid) {
      try {
        setLoading(true)
        await storeOrUpdate()
        setTimeout(() => {

          setLoading(false)
          // setIsNew(true)
          setErrorMessage('')
          closeModal()
          handleReset()
        }, 3000)

      } catch (error) {
        setLoading(false)
        setErrorMessage(error)
      }
    }
  }

  useEffect(() => {
    getStateData()
  }, [])

  return (
    <CustomModal open={open} closeModal={closeModal} >
      <Form>
        <ModalHeader title={`${!values.id ? 'Skapa ny' : 'Redigera befintliga '} Ort`} closeModal={closeModal} />
        <ModalContents>
          <Container>
            <Grid container spacing={3}>
              {errorMessage && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Alert severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                </Grid>
              )}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputLabel className={classes.labelSelect}>
                Välj Stad</InputLabel>
                <Select
                  className={classes.customSelect}
                  fullWidth
                  defaultValue={values.md_location_city_id}
                  onChange={(e) => setFieldValue('md_location_city_id', e.target.value)}
                >
                  <MenuItem value={null}>
                    Välj State
                  </MenuItem>
                  {
                    cities.map((item) => (
                      <MenuItem value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Postnummer"
                  error={touched.postal_code && errors.postal_code}
                  inputProps={{
                    id: 'postal_code',
                    name: 'postal_code',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.postal_code,
                    type: "text",
                    fullWidth: true,
                    placeholder: "12345",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                  labelText="Pris"
                  error={touched.price && errors.price}
                  inputProps={{
                    id: 'price',
                    name: 'price',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.price,
                    type: "number",
                    fullWidth: true,
                    placeholder: "0",
                  }}
                />
            </Grid>
          </Container>
        </ModalContents>
        <Box className={classes.modalFooter} textAlign="right">
          <Button variant="simple" className={classes.cancelBtn} onClick={closeModal}>
            Annullera
          </Button>
          <LoadingButton type="submit" loading={loading} onClick={handleSubmit} variant="contained" color="green">
            {!values.id ? 'Skapa' : 'Uppdatering'}
          </LoadingButton>
        </Box>
      </Form>
    </CustomModal>
  )
}
export default FormEnchancer(EditMdLocationPostalCode)
