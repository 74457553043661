import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, Hidden } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"
import CustomButton from 'components/CustomForms/Button'

import styles from "assets/jss/views/admin/userViewStyle"

const useStyles = makeStyles(styles)

const UserDetail = (props) => {

  const classes = useStyles() 

  const { open, closeModal, user } = props

  return (
    <CustomModal open={open} closeModal={closeModal} size="small">
      <ModalHeader title="Detaljerad användare" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box className={classes.detailWrapper}>
                <Box className={classes.userDetailItem}>
                  <Typography variant="body1" className={classes.detailLabel}>
                    namn:
                  </Typography>
                  <Typography variant="body1" className={classes.detailInfo}>
                    {user.name}
                  </Typography>
                </Box>
                <Box className={classes.userDetailItem}>
                  <Typography variant="body1" className={classes.detailLabel}>
                    E-postadress:
                  </Typography>
                  <Typography variant="body1" className={classes.detailInfo}>
                    {user.email}
                  </Typography>
                </Box>
                <Box className={classes.userDetailItem}>
                  <Typography variant="body1" className={classes.detailLabel}>
                    Telefonnummer:
                  </Typography>
                  <Typography variant="body1" className={classes.detailInfo}>
                    {user.phone_number}
                  </Typography>
                </Box>
              </Box>              
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {user.user_addresses.map(item => (
                <Box className={classes.userAddress}>
                <Typography variant="body1" className={classes.detailLabel}>
                  Adress:
                </Typography>
                <Typography variant="body1" className={classes.detailInfo}>
                  {item.recepient_street_address}, {item.recepient_street_number}
                </Typography>
              </Box>
              ))}
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Hidden smDown>
        <Box className={classes.modalFooter} textAlign="right">        
          <CustomButton variant="contained" color="green" onClick={ closeModal }>
            Stänga
          </CustomButton>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.modalFooter} display="flex" textAlign="right">        
          <Box textAlign="right" width="50%">        
            <CustomButton variant="simple" fullWidth color="green" onClick={ closeModal }>
              Annullera
            </CustomButton>
          </Box>
        </Box>        
      </Hidden>
    </CustomModal>
  )
}

export default UserDetail
