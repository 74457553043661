import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, Button, Divider, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import styles from "assets/jss/views/profileViewStyle"

import userAvatar from "assets/img/user.png"
import editIcon from "assets/img/edit.png"
import EditUser from './EditUser'

const useStyles = makeStyles(styles)

const ProfileDataPanel = () => {

  const classes = useStyles()
  const [openEditPersonalDataModal, setOpenEditPersonalDataModal] = useState(false)

  return (
    <Box className={classes.profileDataSection} display="flex" justifyContent="space-between">
      <Box className={classes.avatar} position="relative">
        <img src={userAvatar} alt="User" width="100%" />
        <IconButton className={classes.editProfile}>
          <img src={editIcon} alt="Edit" />
        </IconButton>
      </Box>
      <Box className={classes.profileDataCol}>
        <Box className={classes.profileDataFieldRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Personlig information
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Användarnamn
          </Typography>
          <Typography variant="body1">
            noahjorberg
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Name
          </Typography>
          <Typography variant="body1">
            Noah J Öberg
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Födelsedatum
          </Typography>
          <Typography variant="body1">
            22 August 1998
          </Typography>
        </Box>
      </Box>

      <Hidden smDown>
        <Divider className={classes.profileDivider} orientation="vertical" flexItem />
      </Hidden>
      <Hidden mdUp>
        <Divider className={classes.profileDivider} />
      </Hidden>      

      <Box className={classes.profileDataCol}>
        <Box className={classes.profileDataFieldRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Kontaktinformation
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            E-postadress
          </Typography>
          <Typography variant="body1">
            noahjo@email.com
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Telefonnummer
          </Typography>
          <Typography variant="body1">
            +1234567890
          </Typography>
        </Box>        
      </Box>
      <Hidden smDown>
        <Divider className={classes.profileDivider} orientation="vertical" flexItem />
      </Hidden>
      <Hidden mdUp>
        <Divider className={classes.profileDivider} />
      </Hidden>
      <Box className={classes.profileDataCol}>
        <Box className={classes.profileDataFieldRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Security
          </Typography>
          <Button onClick={() => setOpenEditPersonalDataModal(true)}>Uppdatering</Button>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Lösenord
          </Typography>
          <Typography variant="body1">
            *********
          </Typography>
        </Box>             
      </Box>
      <EditUser 
       open={openEditPersonalDataModal}
       closeModal={() => setOpenEditPersonalDataModal(false)}
      />
    </Box>
  )
}

export default ProfileDataPanel
