import request from  '../../../../utils/request'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getPaginateData = props => {

    const {
        limit,
        page,
        keyword,
        date,
        status,
        payment_method
    } = props

    return request({
        url : BASE_URL + '/transaction',
        method : 'GET',
        queryParams : {
            limit : limit ?? 10,
            page : page ?? 1,
            keyword : keyword ?? '' ,
            date: date,
            payment_method: payment_method,
            status : status
        }
    })
    .then(res => {
        return res
    })
    .catch(error => {
        throw error.message
    })

}