import Landing from "views/Market/Landing"
import AllProduct from "views/Market/AllProduct"
import ProductDetail from "views/Market/ProductDetail"
import Login from "views/Auth/Login"
import AdminLogin from "views/Auth/AdminLogin"
import StaffLogin from "views/Auth/StaffLogin"
import Register from "views/Auth/Register"
import ForgotPassword from "views/Auth/ForgotPassword"
import TermsOfPurchase from "views/Market/TermsOfPurchase"
import Cart from "views/Market/Cart"
import Checkout from "views/Market/Checkout"
import Profile from "views/Market/Profile"
import TransactionHistory from "views/Market/TransactionHistory"
import Blog from "views/Market/Blog"
import BlogDetail from "views/Market/BlogDetail"

import Dashboard from "views/Admin/Dashboard"
import Product from "views/Admin/Product"
import AddProduct from "views/Admin/Product/AddProduct"
import Order from "views/Admin/Order"
import User from "views/Admin/User"
import Discount from "views/Admin/Discount"
import Staff from "views/Admin/Staff"
import BroadcastMessage from "views/Admin/BroadcastMessage"
import Notification from "views/Admin/Notification"
import ProfileAdmin from "views/Admin/ProfileAdmin/ProfileAdmin"

import StaffDashboard from "views/Staff/Dashboard"
import StaffProduct from "views/Staff/Product"
import StripeCard from "views/Market/Payment/StripeCard"
import AddBlog from "views/Admin/Blog/AddBlog"
import AdminBlog from "views/Admin/Blog/Blog"
import Category from "views/Admin/Category/Category"
import EditCategory from "views/Admin/Category/EditCategory"
import Faq from "views/Market/Faq/Faq"
import CategoryIcon from '@material-ui/icons/Category';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FaqAdmin from "views/Admin/Faq/Faq"
import EditFaq from "views/Admin/Faq/EditFaq"
import TermsOfPurchaseAdmin from "views/Admin/TermsOfPurchase/TermsOfPurchase"
import EditTermsOfPurchase from "views/Admin/TermsOfPurchase/EditTermsOfPurchase"
import SwishQr from "views/Market/Payment/SwishQr"
import MdLocationState from "views/Admin/MdLocationState/MdLocationState"
import MdLocationCity from "views/Admin/MdLocationCity/MdLocationCity"
import MdLocationPostalCode from "views/Admin/MdLocationPostalCode"

const HomeSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16" viewBox="0 0 16.001 16">
        <g transform="translate(0.001)">
            <path id="Vector" className="cls-1" d="M15.569,6.959h0L9.041.432a1.473,1.473,0,0,0-2.083,0L.434,6.955l-.007.007a1.472,1.472,0,0,0,.98,2.51l.045,0h.26v4.8A1.726,1.726,0,0,0,3.438,16H5.991a.469.469,0,0,0,.469-.469V11.766a.787.787,0,0,1,.786-.786H8.752a.787.787,0,0,1,.786.786v3.766a.469.469,0,0,0,.469.469h2.553a1.726,1.726,0,0,0,1.724-1.724v-4.8h.241a1.473,1.473,0,0,0,1.042-2.514Z" transform="translate(-0.001)" />
        </g>
    </svg>
)
const ProductSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g className="cls-1" transform="translate(0)">
            <g>
                <g>
                    <path id="Vector" className="cls-2" d="M15.921,7.709,14.188,5.157,15.921,2.6a.468.468,0,0,0-.242-.7L10.023.025a.466.466,0,0,0-.538.184L8,2.437,6.515.209A.466.466,0,0,0,5.977.025L.32,1.9a.468.468,0,0,0-.242.7L1.812,5.157.079,7.709a.468.468,0,0,0,.242.7l5.656,1.875a.469.469,0,0,0,.538-.184L8,7.877,9.485,10.1a.468.468,0,0,0,.538.184L15.68,8.414a.468.468,0,0,0,.242-.7ZM8,6.538,3.857,5.157,8,3.776l4.143,1.381Z" transform="translate(0 0.499)" />
                </g>
            </g>
            <g>
                <g>
                    <path id="Vector-2" data-name="Vector" className="cls-2" d="M8.414,1.611A1.405,1.405,0,0,1,6.8,1.057L6.094,0l-.7,1.057a1.4,1.4,0,0,1-1.617.553L0,.353V3.09a.468.468,0,0,0,.32.444L5.943,5.408a.423.423,0,0,0,.051.014h0a.488.488,0,0,0,.1.011.478.478,0,0,0,.1-.012l.046-.013,5.623-1.875a.468.468,0,0,0,.32-.445V.353Z" transform="translate(1.906 10.067)" />
                </g>
            </g>
        </g>
    </svg>
)
const OrderSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g className="cls-1">
            <g>
                <g>
                    <path id="Vector" className="cls-2" d="M13.846,6.769a1.384,1.384,0,0,1-1.141-.6l-.678-.986a4.618,4.618,0,0,1-8.62-1.8h.439a.462.462,0,0,0,.38-.723l-.673-.979L2.534.2a.462.462,0,0,0-.761,0L.081,2.662a.462.462,0,0,0,.38.723H.938A7.077,7.077,0,0,0,13,7.927a7.125,7.125,0,0,0,.94-1.162C13.912,6.768,13.879,6.769,13.846,6.769Z" transform="translate(0 5.077)" />
                </g>
            </g>
            <g>
                <g>
                    <path id="Vector-2" data-name="Vector" className="cls-2" d="M1.868,2.461h-.7A.242.242,0,0,1,.923,2.22V1.868a.242.242,0,0,1,.242-.242h.7a.242.242,0,0,1,.242.242.462.462,0,0,0,.923,0A1.166,1.166,0,0,0,1.978.709V.462a.462.462,0,0,0-.923,0V.709A1.166,1.166,0,0,0,0,1.868V2.22A1.166,1.166,0,0,0,1.165,3.385h.7a.242.242,0,0,1,.242.242v.352a.242.242,0,0,1-.242.242h-.7a.242.242,0,0,1-.242-.242.462.462,0,0,0-.923,0A1.166,1.166,0,0,0,1.055,5.137v.247a.462.462,0,0,0,.923,0V5.137A1.166,1.166,0,0,0,3.033,3.978V3.626A1.166,1.166,0,0,0,1.868,2.461Z" transform="translate(6.484 5.077)" />
                </g>
            </g>
            <g>
                <g>
                    <path id="Vector-3" data-name="Vector" className="cls-2" d="M13.891,6.862a.462.462,0,0,0-.408-.247h-.476A7.078,7.078,0,0,0,5.944,0a7.031,7.031,0,0,0-5,2.073A7.125,7.125,0,0,0,0,3.235c.033,0,.065,0,.1,0a1.384,1.384,0,0,1,1.141.6l.678.986a4.618,4.618,0,0,1,8.62,1.8H10.1a.462.462,0,0,0-.38.723l.673.979L11.41,9.8a.462.462,0,0,0,.761,0l.611-.889,1.081-1.573A.462.462,0,0,0,13.891,6.862Z" transform="translate(2.056 0.923)" />
                </g>
            </g>
        </g>
    </svg>
)
const UsersSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g className="cls-1">
            <g>
                <path id="Vector" className="cls-2" d="M2.738,6.651A3.075,3.075,0,0,0,5.476,3.326C5.476,1.489,5.074,0,2.738,0S0,1.489,0,3.326A3.075,3.075,0,0,0,2.738,6.651Z" transform="translate(5.262 1.2)" />
                <path id="Vector-2" data-name="Vector" className="cls-2" d="M10.337,4.689C10.287,1.489,9.869.577,6.671,0a1.917,1.917,0,0,1-1.5.574A1.917,1.917,0,0,1,3.672,0C.509.571.066,1.469.008,4.585,0,4.839,0,4.852,0,4.823c0,.055,0,.158,0,.336,0,0,.761,1.535,5.171,1.535s5.171-1.535,5.171-1.535c0-.115,0-.194,0-.249A2.01,2.01,0,0,1,10.337,4.689Z" transform="translate(2.828 8.106)" />
                <path id="Vector-3" data-name="Vector" className="cls-2" d="M1.015,5.4A2.5,2.5,0,0,0,3.238,2.7c0-1.492-.327-2.7-2.224-2.7a3.171,3.171,0,0,0-.83.1,5.451,5.451,0,0,1,.5,2.572A4.56,4.56,0,0,1,0,5.1,1.9,1.9,0,0,0,1.015,5.4Z" transform="translate(10.785 1.855)" />
                <path id="Vector-4" data-name="Vector" className="cls-2" d="M4.3,3.808C4.257,1.209,3.918.469,1.321,0A1.557,1.557,0,0,1,.1.466l-.1,0A3.417,3.417,0,0,1,1.4,1.545a6.35,6.35,0,0,1,.8,3.712C3.945,4.911,4.3,4.19,4.3,4.19c0-.094,0-.158,0-.2A1.538,1.538,0,0,1,4.3,3.808Z" transform="translate(11.696 7.465)" />
                <path id="Vector-5" data-name="Vector" className="cls-2" d="M2.224,5.4a1.906,1.906,0,0,0,1.015-.3A4.56,4.56,0,0,1,2.555,2.67,5.452,5.452,0,0,1,3.054.1a3.17,3.17,0,0,0-.83-.1C.327,0,0,1.209,0,2.7A2.5,2.5,0,0,0,2.224,5.4Z" transform="translate(1.976 1.855)" />
                <path id="Vector-6" data-name="Vector" className="cls-2" d="M4.3.463l-.1,0A1.557,1.557,0,0,1,2.982,0C.385.469.046,1.209,0,3.808a1.577,1.577,0,0,1,0,.18c0,.044,0,.109,0,.2,0,0,.358.721,2.105,1.066a6.351,6.351,0,0,1,.8-3.712A3.417,3.417,0,0,1,4.3.463Z" transform="translate(0 7.465)" />
            </g>
        </g>
    </svg>
)
const DiscountSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g>
            <g>
                <path id="Vector" className="cls-1" d="M14.612,8.207a.469.469,0,0,1,0-.414l.6-1.221a1.389,1.389,0,0,0-.6-1.844l-1.2-.637a.469.469,0,0,1-.244-.335l-.235-1.338a1.389,1.389,0,0,0-1.569-1.14l-1.345.19a.469.469,0,0,1-.394-.128L8.646.395a1.389,1.389,0,0,0-1.939,0l-.977.945a.469.469,0,0,1-.394.128L3.99,1.277a1.389,1.389,0,0,0-1.569,1.14L2.187,3.755a.469.469,0,0,1-.244.335l-1.2.637a1.389,1.389,0,0,0-.6,1.844l.6,1.221a.469.469,0,0,1,0,.414l-.6,1.221a1.389,1.389,0,0,0,.6,1.844l1.2.637a.469.469,0,0,1,.244.335l.235,1.338a1.388,1.388,0,0,0,1.369,1.154,1.427,1.427,0,0,0,.2-.014l1.345-.19a.469.469,0,0,1,.394.128l.977.945a1.389,1.389,0,0,0,1.939,0l.977-.945a.469.469,0,0,1,.394-.128l1.345.19a1.389,1.389,0,0,0,1.569-1.14l.235-1.338a.469.469,0,0,1,.244-.335l1.2-.637a1.389,1.389,0,0,0,.6-1.844ZM5.831,3.847A1.692,1.692,0,1,1,4.139,5.539,1.694,1.694,0,0,1,5.831,3.847Zm-.873,7.524a.461.461,0,0,1-.652-.653l6.09-6.09a.461.461,0,1,1,.653.653Zm4.564.781a1.692,1.692,0,1,1,1.692-1.692A1.694,1.694,0,0,1,9.522,12.153Z" transform="translate(0.323 0)" />
            </g>
        </g>
        <g>
            <g>
                <path id="Vector-2" data-name="Vector" className="cls-1" d="M.769,0a.769.769,0,1,0,.769.769A.77.77,0,0,0,.769,0Z" transform="translate(9.076 9.692)" />
            </g>
        </g>
        <g>
            <g>
                <path id="Vector-3" data-name="Vector" className="cls-1" d="M.769,0a.769.769,0,1,0,.769.769A.77.77,0,0,0,.769,0Z" transform="translate(5.385 4.77)" />
            </g>
        </g>
    </svg>
)
const StaffSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g className="cls-1">
            <path id="Vector" className="cls-2" d="M14.167,0H1.833A1.835,1.835,0,0,0,0,1.833v8.333A1.835,1.835,0,0,0,1.833,12H14.167A1.835,1.835,0,0,0,16,10.167V1.833A1.835,1.835,0,0,0,14.167,0ZM5,2.667A1.667,1.667,0,1,1,3.333,4.333,1.669,1.669,0,0,1,5,2.667ZM8,8.833a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5V8.5A1.835,1.835,0,0,1,3.833,6.667H6.167A1.835,1.835,0,0,1,8,8.5Zm5.5.5H9.833a.5.5,0,0,1,0-1H13.5a.5.5,0,0,1,0,1Zm0-2.667H9.833a.5.5,0,0,1,0-1H13.5a.5.5,0,0,1,0,1ZM13.5,4H9.833a.5.5,0,0,1,0-1H13.5a.5.5,0,0,1,0,1Z" transform="translate(0 2)" />
        </g>
    </svg>
)
const BroadcastSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g>
            <g>
                <path id="Vector" className="cls-1" d="M2.159.12l-.028,0A2.673,2.673,0,0,1,1.489,0L.063,2.469a.469.469,0,0,0,.177.643l1.4.787a.469.469,0,0,0,.636-.174L4.36.12Z" transform="translate(1.862 11.216)" />
                <g>
                    <path id="Vector-2" data-name="Vector" className="cls-1" d="M4.235.07,0,2.663v7.467l4.241,2.519a.469.469,0,0,0,.708-.4V.469a.469.469,0,0,0-.714-.4Z" transform="translate(7.702 0.826)" />
                    <g>
                        <path id="Vector-3" data-name="Vector" className="cls-1" d="M1.517,3.675V.526A2.519,2.519,0,0,1,1.572,0H.891A.89.89,0,0,0,0,.89V3.31a.89.89,0,0,0,.891.89h.682A2.534,2.534,0,0,1,1.517,3.675Z" transform="translate(0 5.078)" />
                        <path id="Vector-4" data-name="Vector" className="cls-1" d="M4.31,6.338H1.594A1.676,1.676,0,0,1,0,4.692V1.543A1.517,1.517,0,0,1,1.594,0H4.31Z" transform="translate(2.454 4.061)" />
                    </g>
                </g>
            </g>
            <g>
                <path id="Vector-5" data-name="Vector" className="cls-1" d="M1.415,1.883a.468.468,0,0,1-.332-.137L.137.8A.469.469,0,0,1,.8.137l.946.946a.469.469,0,0,1-.332.8Z" transform="translate(13.295 9.126)" />
                <path id="Vector-6" data-name="Vector" className="cls-1" d="M.469,1.883a.469.469,0,0,1-.332-.8L1.083.137A.469.469,0,1,1,1.746.8L.8,1.746a.467.467,0,0,1-.332.137Z" transform="translate(13.295 3.501)" />
                <path id="Vector-7" data-name="Vector" className="cls-1" d="M1.807.938H.469A.469.469,0,1,1,.469,0H1.807a.469.469,0,1,1,0,.938Z" transform="translate(13.724 6.786)" />
            </g>
        </g>
    </svg>
)

const NewsSvg = (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M14 4v-2h-14v11c0 0.552 0.448 1 1 1h13.5c0.828 0 1.5-0.672 1.5-1.5v-8.5h-2zM13 13h-12v-10h12v10zM2 5h10v1h-10zM8 7h4v1h-4zM8 9h4v1h-4zM8 11h3v1h-3zM2 7h5v5h-5z"></path></svg>
)

const BoxSvg = (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g>
                <g>
                    <path d="M155,345H85c-5.523,0-10,4.477-10,10v60c0,5.523,4.477,10,10,10h70c5.523,0,10-4.477,10-10v-60
				C165,349.477,160.523,345,155,345z M145,405H95v-40h50V405z" />
                    <rect x="180" y="360" width="45" height="20" />
                    <rect x="180" y="390" width="115" height="20" />
                    <rect x="375" y="400" width="40" height="20" />
                    <rect x="375" y="370" width="40" height="20" />
                    <path d="M489.383,256.543l-35-95C452.935,157.612,449.19,155,445,155h-60V35c0-5.523-4.477-10-10-10H115c-5.523,0-10,4.477-10,10
				v120H45c-4.124,0-7.825,2.532-9.32,6.376l-35,90c-1.197,3.077-0.798,6.546,1.066,9.27C3.61,263.371,6.699,265,10,265h25v190
				c0,5.523,4.477,10,10,10h400c5.523,0,10-4.477,10-10V270h25c3.269,0,6.332-1.598,8.203-4.28
				C490.073,263.038,490.514,259.611,489.383,256.543z M125,45h160v30H125V45z M125,95h160v60H125V95z M24.619,245l27.222-70h278.54
				l-27.222,70H24.619z M335,445H55V265h255c4.124,0,7.825-2.532,9.32-6.375l15.68-40.32V445z M305,155V45h60v110h-20H305z M435,445
				h-80V209.471l25.962,54.81c1.655,3.493,5.173,5.719,9.038,5.719h45V445z M396.328,250l-35.526-75h77.225l27.631,75H396.328z" />
                    <rect x="320" y="65" width="30" height="20" />
                    <rect x="320" y="95" width="30" height="20" />
                </g>
            </g>
        </g>
    </svg>
)

var routes = [
    
    {
        path: "/admin",
        name: "Logga in",
        component: AdminLogin,
        layout: "/",
    },
    {
        path: "/staff",
        name: "Logga in",
        component: StaffLogin,
        layout: "/",
    },
    {
        path: "/login",
        name: "Logga in",
        component: Login,
        layout: "",
    },
    
    {
        path: "/auth/register",
        name: "Registrera dig",
        component: Register,
        layout: ""
    },
    {
        path: "/auth/forgot-password",
        name: "Glömt lösenord",
        component: ForgotPassword,
        layout: ""
    },
    {
        path: "/terms-of-purchase",
        name: "Köpvillkor",
        component: TermsOfPurchase,
        layout: "",
    },
    {
        path: "/faq",
        name: "Köpvillkor",
        component: Faq,
        layout: "",
    },
    {
        path: "/all-product",
        name: "Alla produkter",
        component: AllProduct,
        layout: ""
    },
    {
        path: "/product-detail/:slug",
        name: "Produktinformation",
        component: ProductDetail,
        layout: ""
    },
    {
        path: "/cart",
        name: "Kärra",
        component: Cart,
        layout: ""
    },
    {
        path: "/checkout",
        name: "Kassa",
        component: Checkout,
        layout: ""
    },
    {
        path: "/profile",
        name: "Profil",
        component: Profile,
        layout: "",
        protected: true
    },
    {
        path: "/transaction-history",
        name: "Transaktionshistorik",
        component: TransactionHistory,
        layout: "",
        protected: true
    },
    {
        path: "/blog/:slug",
        name: "Blogg detalj",
        component: BlogDetail,
        layout: ""
    },
    {
        path: "/blog",
        name: "Blogg",
        component: Blog,
        layout: ""
    },
    {
        path: "/swish-payment/:id",
        name: "Betalkort",
        component: SwishQr,
        layout: ""
    },
    {
        path: "/card-payment/:id",
        name: "Betalkort",
        component: StripeCard,
        layout: ""
    },
    {
        path: "/",
        name: "Hemsida",
        component: Landing,
        layout: ""
    },
    {
        path: "/dashboard",
        name: "Överblick",
        component: Dashboard,
        icon: HomeSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/product/add",
        name: "Skapa produkt",
        component: AddProduct,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/product/edit/:id",
        name: "Redigera produkt",
        component: AddProduct,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/product",
        name: "Hantera produkter",
        component: Product,
        icon: ProductSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/order",
        name: "Hantera order",
        component: Order,
        icon: OrderSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/user",
        name: "Hantera användare",
        component: User,
        icon: UsersSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/discount",
        name: "Hantera rabatt",
        component: Discount,
        icon: DiscountSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/staff",
        name: "Hantera personal",
        component: Staff,
        icon: StaffSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/broadcast-message",
        name: "Broadcast-meddelande",
        component: BroadcastMessage,
        icon: BroadcastSvg,
        layout: "/admin",
        protected: true
    },
    {
        path: "/blog/update/:slug",
        name: "Uppdatera blogg",
        component: AddBlog,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/blog/add",
        name: "Skapa blogg",
        component: AddBlog,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/blog",
        name: "Hantera bloggar",
        component: AdminBlog,
        icon: NewsSvg,
        layout: "/admin",
        protected: true
    },

    {
        path: "/category/update/:slug",
        name: "Uppdatera Kategori",
        component: EditCategory,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/category/create",
        name: "Skapa kategori",
        component: EditCategory,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/category",
        name: "Hantera Kategori",
        component: Category,
        icon: <CategoryIcon/>,
        layout: "/admin",
        protected: true
    },

    {
        path: "/faq/edit/:id",
        name: "Redigera faq",
        component: EditFaq,
        icon: <QuestionAnswerIcon/>,
        layout: "/admin",
        protected: true,
        subView: true,
    },

    {
        path: "/faq/create",
        name: "Skapa FAQ",
        component: EditFaq,
        layout: "/admin",
        subView: true,
        protected: true
    },
    
    {
        path: "/faq",
        name: "Hantera FAQ",
        component: FaqAdmin,
        icon: <QuestionAnswerIcon/>,
        layout: "/admin",
        protected: true
    },

    {
        path: "/terms-of-purchase/edit/:id",
        name: "Redigera köpvillkor",
        component: EditTermsOfPurchase,
        layout: "/admin",
        protected: true,
        subView: true,
    },

    {
        path: "/terms-of-purchase/create",
        name: "Skapa köpvillkor",
        component: EditTermsOfPurchase,
        layout: "/admin",
        subView: true,
        protected: true
    },

    {
        path: "/terms-of-purchase",
        name: "Hantera köpvillkor",
        component: TermsOfPurchaseAdmin,
        icon: <AttachFileIcon/>,
        layout: "/admin",
        protected: true
    },

    {
        path: "/md-location-state",
        name: "stater",
        component: MdLocationState,
        icon: <AttachFileIcon/>,
        layout: "/admin",
        protected: true
    },

    {
        path: "/md-location-city",
        name: "Städer",
        component: MdLocationCity,
        icon: <AttachFileIcon/>,
        layout: "/admin",
        protected: true
    },

    {
        path: "/md-location-postal-code",
        name: "Frakt",
        component: MdLocationPostalCode,
        icon: <AttachFileIcon/>,
        layout: "/admin",
        protected: true
    },


    {
        path: "/notification",
        name: "Anmälan",
        component: Notification,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/profile",
        name: "Profil",
        component: ProfileAdmin,
        layout: "/admin",
        subView: true,
        protected: true
    },
    {
        path: "/dashboard",
        name: "Instrumentbräda",
        component: StaffDashboard,
        layout: "/staff",
        protected: true
    },
    {
        path: "/notification",
        name: "Meddelanden",
        component: Notification,
        layout: "/staff",
        protected: true
    },
    {
        path: "/product",
        name: "Produkter",
        component: StaffProduct,
        layout: "/staff",
        protected: true
    },
    {
        path: '/payment',
        name: 'Personal',
        component: Checkout,
        layout: "/staff",
        protected: true
    }
]

export default routes
