import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import { Box, Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import TextField from "components/CustomForms/TextField"
import CustomButton from 'components/CustomForms/Button'
import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import ConfirmModal from "components/CustomModal/ConfirmModal"
// import StaffItem from "./components/StaffItem"
// import EditStaff from "./components/EditStaff"
// import MessageStaff from "./components/MessageStaff"

import styles from "assets/jss/views/admin/broadcastViewStyle"
import CreateBroadcast from './components/CreateBroadcast'
import BroadcastItem from './components/BroadcastItem'
import { getNotificationService } from 'services/NotificationService'
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'
import moment from 'moment'
import CustomDateField from 'components/CustomForms/CustomDateField'
import ProductCustomPagination from 'components/CustomPagination/ProductCustomPagination'

const useStyles = makeStyles(styles)

const BroadcastMessage = () => {

  const classes = useStyles() 

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openCreateBroadcastModal, setOpenCreateBroadcastModal] = useState(false)
  const [notification, setNotifications] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [query, setQuery] = useState({
    limit : 10,
    page : 1,
    keyword : '',
    date : ''
  })
  const getNotifications = async () => {
    try {
      setLoading(true)
      const data = await getNotificationService(query)
      setNotifications(data.data ?? [])
      setTotalPage(data.total_page)
      setTotalData(data.total_data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props })
  }

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{page : page}})
  }

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getNotifications()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const columns = [
    {
      selector: row => row.date,
      name: "Datum",      
      wrap: 'true',
      sortable: true,
      grow: 2,
      cell: (row) => (
        <Typography>
          {moment(row.created_at).format('Y-m-d H:m:s')}
        </Typography>
      )
    },
    {
      selector: row => row.title,
      name: "Ämne",
      sortable: true,
      grow: 3
    },
    {
      selector: row => row.to_user?.email || row.to_role?.name,
      name: "Mottagare",
      sortable: true,
      grow: 2
    }
  ]

  

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AdminViewTitles mainTitle="Sänd Meddelande" subTitle="" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.tableWrapper}>
          <Box className={classes.filterWrapper}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <TextField  
                  disableLabel
                  className={classes.searchForm}
                  inputProps={{
                    type: "text",
                    fullWidth: true,
                    placeholder: "Sök",
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    onChange: (e) => {changeQuery({keyword: e.target.value})}
                  }}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <CustomDateField
                  value={moment(query.created_at, 'YYYYY/MM/DD').format()}
                  handleChange={(e) => {

                    if (e) {
                      if (moment(e).isValid()) {
                        changeQuery({ created_at: moment(e).format('YYYY/MM/DD') })
                      }

                    } else {
                      changeQuery({ created_at: '' })
                    }


                  }}
                />
              </Grid>
              <Hidden smDown>
                <Box flexGrow={1} />
              </Hidden>
              <Hidden smDown>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  <CustomButton variant="contained" color="green" onClick={() => setOpenCreateBroadcastModal(true)} fullWidth startIcon={<AddIcon />} >
                    Lägg till
                  </CustomButton>
                </Grid>
              </Hidden>              
            </Grid>
          </Box>
          <Hidden smDown>
            <Box className={classes.dataTable}>
              <CustomDataTable
                columns={ columns }
                data={ notification }
                striped={ false }
                center={true}
                highlightOnHover={true}
                responsive={false}
                persistTableHead = {false}
                progressPending={loading}
                pagination
                paginationServer
                currentPage={query.page}
                paginationPerPage={query.limit}
                paginationTotalRows={totalData}
                onChangePage={(page) => changePage(page)}
              />
            </Box>    
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.ordersList}>
              {
                notification.map((broadcast, index) => (
                    index < 3 && 
                    <BroadcastItem 
                      key={broadcast.id} 
                      broadcast={ broadcast }
                      handleClickDelete={() => setOpenConfirmModal(true)}
                    />
                ))
              }
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Hidden mdUp>
        <Grid item sm={12} xs={12}>
          <Box mt={3}>
            <ProductCustomPagination 
                currentLimit={query.limit} 
                changeCurrentLimit={val => changeQuery({ limit: val })} 
                currentPage={query.page} 
                changePage={val => changeQuery({ page: val })} 
                pageCount={totalPage}
                totalData={totalData}
              />
          </Box>
          <Box mt={2}>
            <CustomButton variant="contained" color="green" onClick={() => setOpenCreateBroadcastModal(true)} fullWidth startIcon={<AddIcon />} >
              Add Broadcast
            </CustomButton>
          </Box>
        </Grid>
      </Hidden>     
      <ConfirmModal 
        open={openConfirmModal} 
        closeModal={() => setOpenConfirmModal(false)} 
        text={
          <Typography variant="body1" align="center">
            Är du säker på att du vill ta bort personal “<span>{}</span>”?
          </Typography>
        }
        confirmAction={() => setOpenConfirmModal(false)}
      />
      <CreateBroadcast 
        open={openCreateBroadcastModal}
        closeModal={ () => setOpenCreateBroadcastModal(false) }
      />
    </Grid>
  )
}

export default BroadcastMessage
