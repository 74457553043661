import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL + '/products'

export const getProductService = props => {

    const {
        limit,
        page,
        keyword
    } = props

    return request({
        url : BASE_URL,
        method : 'GET',
        queryParams : {
            limit : limit ?? 10,
            page : page ?? 1,
            keyword : keyword ?? '',
            category_id : null
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(error => {
        throw error.message
    })

}

export const getDetailProductService = props => {
    const {
        id
    } = props

    return request({
        url: BASE_URL + '/' + id + '/detail',
        method : 'GET'
    })
    .then(res => res.data)
    .catch(error => {
        throw error.message
    })
}

export const storeProductService = props => {
    return request({
        url : BASE_URL + '/store',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .catch(error => {
        throw error.message
    })
}

export const updateProductService = props => {
    return request({
        url : `${BASE_URL}/${props.id}/update`,
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .catch(error => {
        throw error.message
    })
}

export const destroyProductService = props => {
    return request({
        url : `${BASE_URL}/${props.id}/destroy`,
        method: 'POST',
    })
    .then(res => res.data)
    .catch(error => {
        throw error.message
    })
}

export const getDetailProductBySlugService = props => {
    return request({
        url: `${BASE_URL}/${props.slug}/by-slug`,
    })
    .then(res => res.data)
    .catch(error => {
        throw error.message
    })
}