import { Grid } from '@material-ui/core'
import AdminViewTitles from 'components/Breadcrumb/AdminViewTitles'
import React from 'react'
import ProfileDataPanel from './components/ProfileDataPanel'

const ProfileAdmin = (props) => {

    return(
        <Grid container>
             <Grid item lg={12} md={12} sm={12} xs={12}>
                <AdminViewTitles mainTitle="Profil" subTitle="Lorem ipsum dolor sit amet" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <ProfileDataPanel />
            </Grid>
        </Grid>
    )

}

export default ProfileAdmin