import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

// import VisibilityIcon from '@material-ui/icons/Visibility'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded'

import { Box, Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import TextField from "components/CustomForms/TextField"
import CustomButton from 'components/CustomForms/Button'
import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import CustomIconButton from "components/CustomForms/CustomIconButton"
import ConfirmModal from "components/CustomModal/ConfirmModal"
import StaffItem from "./components/StaffItem"
import EditStaff from "./components/EditStaff"
import MessageStaff from "./components/MessageStaff"

import styles from "assets/jss/views/admin/staffViewStyle"
import { getUserService } from 'services/UserService'
import UserProps from '../User/crud/userProps'
import { destroyUserService } from 'services/UserService'
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'
import ProductCustomPagination from 'components/CustomPagination/ProductCustomPagination'

const useStyles = makeStyles(styles)

const Staff = () => {

  const classes = useStyles() 

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openSendModal, setOpenSendModal] = useState(false)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(UserProps)
  const [totalPage, setTotalPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [query, setQuery] = useState({
    limit : 10,
    page : 1,
    keyword : '',
    roles : 'staff'
  })

  const getUser = async() => {
    try{
      const data = await getUserService(query)
      setUsers(data.data ?? [])
      setTotalPage(data.total_page)
      setTotalData(data.total_data)
      setLoading(false)
    }catch(err) {

    }
  }

  useEffect(() => {
    if (!openEditModal) {
      setSelectedUser(UserProps)
      getUser()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditModal])

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{page : page}})
  }

  const changeQuery = (props) => {
    setQuery({...query, ...props})
  }

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getUser()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {

    const updateTable = () => {
      const delauyDebounceFn = setTimeout(() => {
        getUser()
      }, 2000)

      return () => clearTimeout(delauyDebounceFn)
    }

    if(!openEditModal) {
      updateTable()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditModal])

  useEffect(() => {

    const updateTable = () => {
      const delauyDebounceFn = setTimeout(() => {
        getUser()
      }, 2000)

      return () => clearTimeout(delauyDebounceFn)
    }

    if(!openConfirmModal) {
      updateTable()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConfirmModal])

  const columns = [
    {
      selector: row => row.name,
      name: "namn",      
      wrap: 'true',
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.email,
      name: "E-postadress",
      sortable: true,
      grow: 3
    },
    {
      selector: row => row.phone_number,
      name: "Telefonnummer",
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.gender,
      name: "Gender",
      sortable: true,
      grow: 2
    },      
    {  
      selector: row => row.id,
      name: "Action",
      grow: 1,
      cell: (row) => (
        <Box className={classes.actionList}>
          <CustomIconButton onClick={() => {setSelectedUser(row); setOpenEditModal(true)}}><EditIcon /></CustomIconButton>
          <CustomIconButton onClick={() => {setSelectedUser(row); setOpenConfirmModal(true)}}><DeleteIcon /></CustomIconButton>
          <CustomIconButton onClick={() => {setSelectedUser(row); setOpenSendModal(true)}}><QuestionAnswerRoundedIcon /></CustomIconButton>
        </Box>
      )  
    }
  ]

  
  

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AdminViewTitles mainTitle="Hantera personal" subTitle="Hantera personalkonto" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.tableWrapper}>
          <Box className={classes.filterWrapper}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <TextField  
                  disableLabel
                  className={classes.searchForm}
                  inputProps={{
                    type: "text",
                    fullWidth: true,
                    placeholder: "Sök personalens namn",
                    value: query.keyword,
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    onChange: (e) => {changeQuery({keyword: e.target.value})}
                  }}
                />
              </Grid>
              <Hidden smDown>
                <Box flexGrow={1} />
              </Hidden>
              <Hidden smDown>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  <CustomButton variant="contained" color="green" onClick={() => setOpenEditModal(true)} fullWidth startIcon={<AddIcon />} >
                    Lägg till
                  </CustomButton>
                </Grid>
              </Hidden>              
            </Grid>
          </Box>
          <Hidden smDown>
            <Box className={classes.dataTable}>
              <CustomDataTable
                columns={columns}
                data={users}
                striped={false}
                center={true}
                highlightOnHover={true}
                responsive={false}
                progressPending={loading}
                persistTableHead={false}
                pagination
                paginationServer
                currentPage={query.page}
                paginationPerPage={query.limit}
                paginationTotalRows={totalData}
                onChangePage={(page) => changePage(page)}
              />
            </Box>    
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.ordersList}>
              {
                users.map((staff) => (
                    <StaffItem 
                      key={staff.id} 
                      staff={ staff }
                      handleClickDelete={() => { setSelectedUser(staff); setOpenConfirmModal(true)}}
                      handleEdit={() => {setSelectedUser(staff); setOpenEditModal(true)}}
                      handleSendMessage={() => {setSelectedUser(staff); setOpenSendModal(true)} }
                    />
                ))
              }
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Hidden mdUp>
        <Grid item sm={12} xs={12}>
          <Box mt={3}>
            <ProductCustomPagination 
                currentLimit={query.limit} 
                changeCurrentLimit={val => changeQuery({ limit: val })} 
                currentPage={query.page} 
                changePage={val => changeQuery({ page: val })} 
                pageCount={totalPage}
                totalData={totalData}
              />
          </Box>
          <Box mt={2}>
            <CustomButton variant="contained" color="green" onClick={() => setOpenEditModal(true)} fullWidth startIcon={<AddIcon />} >
              Lägg till
            </CustomButton>
          </Box>
        </Grid>
      </Hidden>     
      <ConfirmModal 
        open={openConfirmModal}
        closeModal={() => setOpenConfirmModal(false)}
        text={
          <Typography variant="body1" align="center">
            Är du säker på att du vill radera personal “<span>{selectedUser.name}</span>”?
          </Typography>
        }
        confirmAction={async () => {
          await destroyUserService(selectedUser)
          setOpenConfirmModal(false)
        }}
      />
      <EditStaff 
        open={ openEditModal }
        user={ selectedUser }
        closeModal={ () => setOpenEditModal(false) }
      />
      <MessageStaff 
        open={ openSendModal }
        user={ selectedUser }
        closeModal={ () => setOpenSendModal(false) }
      />
    </Grid>
  )
}

export default Staff
