import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL


export const login = (username, password, remember_me) => {

    return request({
        url : BASE_URL + '/auth/login',
        method : 'POST',
        body : {
            username : username,
            password : password
        }
    })
    .then(res => {
        localStorage.setItem('_token', res.data?.token)
        
        return res
    })
    .catch(error => {
        throw error.message
    })
}

export const register = props => {

    return request({
        url : BASE_URL + '/auth/register',
        method : 'POST',
        body : props
    })
    .then(res => {
        localStorage.setItem('_token', res.data?.token)

        return res
    })
    .catch(error => {
        throw error.message
    })
}