import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import Breadcrumb from "components/Breadcrumb"
import Button from "components/CustomForms/Button"

import styles from "assets/jss/views/allProductViewStyle"
import { getProductService } from 'services/ProductService'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const HeaderSection = () => {

  const classes = useStyles()
  const history = useHistory()

  const [product, setProduct] = useState()

  const getProducts = async () => {
    const res = await getProductService({
      limit: 1,
      page: 1,
      keyword: ''
    })

    const data = res?.data ?? []

    if (data.length > 0) {
      setProduct(data[0])
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  return (
    <Box className={classes.headerSection} style={{ backgroundImage: `url(${product?.thumbnail ?? ''})` }}>
      <Breadcrumb breadcrumb={["Hem", "Alla produkter"]} />

      {
        product && (
          <Box className={classes.headerContents}>
            <Typography variant="body1">
              {product.name}
            </Typography>
            <Button
              onClick={() => history.push('/product-detail/' + product.slug)}
              variant="contained" color="green">
              Köp Nu
            </Button>
          </Box>
        )
      }

    </Box>
  )
}

export default HeaderSection
