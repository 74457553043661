import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Button from "components/CustomForms/Button"

import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/components/organicModalDetail"

const useStyles = makeStyles(styles)

const FaqDetail = (props) => {

  const classes = useStyles()

  const { open, closeModal, data } = props

  return (
    <CustomModal open={open} closeModal={closeModal}>
      <ModalHeader title="FAQ Detail" closeModal={closeModal} />
      <ModalContents>
        <Container className={classes.container}>

          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.content}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  Fråga
                </Typography>
                <Typography variant="body1">
                  {data.question}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  Svar
                </Typography>
                <Typography variant="body1">
                  {data.answer}
                </Typography>
              </Box>
            </Grid>
          </Grid>

        </Container>
      </ModalContents>
      <Box className={classes.footer} textAlign="right">
        <Button onClick={closeModal} className={classes.closeBtn}>
          Stänga
        </Button>
      </Box>
    </CustomModal>
  )
}

export default FaqDetail
