import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import VisibilityIcon from '@material-ui/icons/Visibility'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import { Box, Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import TextField from "components/CustomForms/TextField"
import CustomButton from 'components/CustomForms/Button'
import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import CustomIconButton from "components/CustomForms/CustomIconButton"
import CustomMobilePagination from "components/CustomPagination/CustomMobilePagination"
import ConfirmModal from "components/CustomModal/ConfirmModal"
import DiscountItem from "./components/DiscountItem"
import EditDiscount from "./components/EditDiscount"

import styles from "assets/jss/views/admin/discountViewStyle"
import { getVoucherService } from 'services/VoucherService'
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { destroyVoucherService } from 'services/VoucherService'
import ProductCustomPagination from 'components/CustomPagination/ProductCustomPagination'

const useStyles = makeStyles(styles)

const InitProps = {
  id: null,
  voucher_code: '',
  amount_type: 'fixed',
  amount: 1,
  start_date: moment().add(1, 'days'),
  end_date: null,
  quota: 0,
  description: '',
  min_order: 0
}

const Discount = () => {

  const classes = useStyles()
  const history = useHistory()
  const [openConfirmToDeleteModal, setOpenConfirmToDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedData, setSelectedData] = useState(InitProps);
  const [totalData, setTotalData] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(0)


  const [data, setData] = useState([])

  const [query, setQuery] = useState({
    keyword: '',
    page: 1,
    limit: 10,
    is_published: false
  })
  const getData = async () => {
    try {
      const data = await getVoucherService(query)
      if (data?.data) {
        data.data.map(discount => {
          discount.period = moment(discount.start_date).format('YYYY/MM/DD') + ' - ' +
            (discount.end_date ? moment(discount.end_date).format('YYYY/MM/DD') : 'Inte definierad')
        })
      }
      setData(data?.data ?? [])
      setTotalPage(data?.total_page ?? 0)
      setTotalData(data?.total_data ?? 0)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{ page: page } })
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getData()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (!openConfirmToDeleteModal) {
      setSelectedData(InitProps)
    }
  }, [openConfirmToDeleteModal])

  const columns = [
    {
      selector: row => row.name,
      name: "Kupongnamn",
      wrap: 'true',
      sortable: true,
      grow: 3
    },
    {
      selector: row => row.amount_type,
      name: "Rabatt typ",
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.amount,
      name: "Belopp",
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.period,
      name: "period",
      sortable: true,
      grow: 3
    },
    {
      selector: row => row.quota,
      name: "Kvot",
      sortable: true,
      grow: 3
    },
    {
      selector: row => row.id,
      name: "Handling",
      grow: 1,
      cell: (row) => (
        <Box className={classes.actionList}>
          <CustomIconButton onClick={() => { }}><VisibilityIcon /></CustomIconButton>
          <CustomIconButton onClick={() => { setSelectedData(row); setOpenEditModal(true) }}><EditIcon /></CustomIconButton>
          <CustomIconButton onClick={() => { setSelectedData(row); setOpenDeleteModal(true) }}><DeleteIcon /></CustomIconButton>
        </Box>
      )
    }
  ]


  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AdminViewTitles mainTitle="Hantera rabatt" subTitle="Hantera rabatt" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.tableWrapper}>
          <Box className={classes.filterWrapper}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <TextField
                  disableLabel
                  className={classes.searchForm}
                  inputProps={{
                    type: "text",
                    fullWidth: true,
                    placeholder: "Sök",
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    onChange: (e) => { changeQuery({ keyword: e.target.value }) }
                  }}
                />
              </Grid>
              <Hidden smDown>
                <Box flexGrow={1} />
              </Hidden>
              <Hidden smDown>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  <CustomButton variant="contained" color="green" onClick={() => setOpenEditModal(true)} fullWidth startIcon={<AddIcon />} >
                    Lägg till
                  </CustomButton>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
          <Hidden smDown>
            <Box className={classes.dataTable}>
              <CustomDataTable
                columns={columns}
                data={data}
                striped={false}
                center={true}
                highlightOnHover={true}
                responsive={true}
                persistTableHead={false}
                progressPending={loading}
                pagination
                paginationServer
                currentPage={query.page}
                paginationPerPage={query.limit}
                paginationTotalRows={totalData}
                onChangePage={(page) => changePage(page)}
              />
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.ordersList}>
              {
                data.map((discount) => (
                  <DiscountItem
                    key={discount.id}
                    discount={discount}
                    handleEdit={() => {
                      setSelectedData(discount)
                      setOpenEditModal(true)
                    }}
                    handleClickDelete={() => {
                      setSelectedData(discount)
                      setOpenDeleteModal(true)
                    }}
                  />
                ))
              }
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Hidden mdUp>
        <Grid item sm={12} xs={12}>
          <Box mt={3}>
            <ProductCustomPagination
              currentLimit={query.limit}
              changeCurrentLimit={val => changeQuery({ limit: val })}
              currentPage={query.page}
              changePage={val => changeQuery({ page: val })}
              pageCount={totalPage}
              totalData={totalData}
            />
          </Box>
          <Box mt={2}>
            <CustomButton variant="contained" onClick={() => {
              setSelectedData(InitProps)
              setOpenEditModal(true)
            }} color="green" fullWidth startIcon={<AddIcon />} >
              Lägg till
            </CustomButton>
          </Box>
        </Grid>
      </Hidden>
      <ConfirmModal
        open={openDeleteModal}
        closeModal={() => setOpenDeleteModal(false)}
        text={
          <Typography variant="body1" align="center">
            Är du säker på att du vill radera rabatt “<span>{selectedData.name}</span>”?
          </Typography>
        }
        confirmAction={async () => {
          await destroyVoucherService(selectedData)
          await getData()
          await setOpenDeleteModal(false)
          setSelectedData(InitProps)
        }}
      />
      <EditDiscount
        open={openEditModal}
        data={selectedData}
        closeModal={async () => {
          await getData()
          setOpenEditModal(false)
          setSelectedData(InitProps)
        }}
      />
    </Grid>
  )
}

export default Discount
