import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import { Box, FormHelperText, Hidden } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"
import RadioCard from "components/RadioCard"

import styles from "assets/jss/views/admin/userViewStyle"
import { Form, withFormik } from 'formik'
import * as Yup from 'yup'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { storeUserService } from 'services/UserService'
import { Alert, AlertTitle } from '@mui/material'
import { updateUserService } from 'services/UserService'

const useStyles = makeStyles(styles)

const UserStoreSchema = Yup.object().shape({
  name: Yup.string()
    .max(191, 'maximalt antal tecken 191')
    .required('Namn krävs'),
  username: Yup.string()
    .max(191, 'Too Long!, maximum length character is 191')
    .required('Requeired!'),
  password: Yup.string()
    .required('Required!'),
  c_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  email: Yup.string().email('Invalid Email')
    .required(),
  date_of_birth: Yup.date()
    .nullable(),
  gender: Yup.string()
    .required('Required!'),
  phone_number: Yup.string()
    .nullable(),
})

const UserUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .max(191, 'maximalt antal tecken 191')
    .required('Namn krävs'),
  username: Yup.string()
    .max(191, 'Too Long!, maximum length character is 191')
    .required('Requeired!'),
  password: Yup.string()
    .nullable(),
  c_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  email: Yup.string().email('Invalid Email')
    .required(),
  date_of_birth: Yup.date()
    .nullable(),
  gender: Yup.string()
    .required('Required!'),
  phone_number: Yup.string()
    .nullable(),
})



const FormEnchancer = withFormik({
  mapPropsToValues: (props) => ({
    id: props.user.id || null,
    name: props.user.name || '',
    username: props.user.username || '',
    password: props.user.password || '',
    c_password: props.user.c_password || '',
    email: props.user.email || '',
    date_of_birth: props.user.date_of_birth || '',
    gender: props.user.gender || '',
    phone_number: props.user.phone_number || '',
    user_addresses : props.user.user_addresses || '',
  }),
  validationSchema: props => props.user.id ? UserUpdateSchema : UserStoreSchema,
  enableReinitialize: true,
  handleSubmit: (values) => {

  },
  isInitialValid: props => props.user.id ? UserUpdateSchema.isValidSync(props.user) : UserStoreSchema.isValidSync(props.user)
})

const EditStaff = props => {
  
  const classes = useStyles()

  const {
    open,
    closeModal,
    user,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue,
    handleReset
  } = props;

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  

  const storeOrUpdateUser = async () => {
    try {
      if (!values.id) {
        await storeUserService({...values, ...{role: 'staff'}})
      } else {
        await updateUserService({ id: user.id, data: {...values, ...{role: 'staff'}} })
      }
    } catch (error) {
      throw error
    }

  }

  const handleSubmit = async () => {
    
    if (isValid) {
      try {
        setLoading(true)
        await storeOrUpdateUser()
        setTimeout(() => {

          setLoading(false)
          // setIsNew(true)
          setErrorMessage('')
          closeModal()
          handleReset()
        }, 3000)

      } catch (error) {
        setLoading(false)
        setErrorMessage(error)
      }
    }
  }

  return (
    <CustomModal open={open} closeModal={closeModal} >
      <Form>
        <ModalHeader title={`${!values.id ? 'Skapa ny' : 'Redigera befintliga '} Personal`} closeModal={closeModal} />
        <ModalContents>
          <Container>
            <Grid container spacing={3}>
              {errorMessage && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Alert severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                </Grid>
              )}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Användarnamn"
                  error={touched.username && errors.username}
                  inputProps={{
                    id: 'username',
                    name: 'username',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.username,
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ange Användarnamn",
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Fullständiga namn"
                  error={touched.name && errors.name}
                  inputProps={{
                    id: 'name',
                    name: 'name',
                    onChange: handleChange,
                    onBlur: handleBlur,

                    value: values.name,
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ange Din Name",
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Email"
                  error={touched.email && errors.email}
                  inputProps={{
                    id: 'email',
                    name: 'email',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.email,
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ange Din E-postadress"
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Telefon"
                  error={touched.phone_number && errors.phone_number}
                  inputProps={{
                    id: 'phone_number',
                    name: 'phone_number',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.phone_number,
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ange ditt telefonnummer (+ din landskod) 123xxxx"
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" className={classes.radioLabel}>
                  Gender <span>*</span>
                </Typography>
                <Hidden smDown>
                  <FormControl component="fieldset" error={true}>
                    <RadioGroup
                      id="gender"
                      name="gender"
                      value={values.gender}
                      onChange={(e) => setFieldValue('gender', e.target.value)}
                      handleBlur={handleBlur}
                    >
                      <Grid container className={classes.methodContainer}>
                        <Grid item lg={4} md={4}>
                          <RadioCard value="male" label="Manlig" small selected={values.gender === "male"} />
                        </Grid>
                        <Grid item lg={4} md={4}>
                          <RadioCard value="female" label="Kvinna" small selected={values.gender === "female"} />
                        </Grid>
                        <Grid item lg={4} md={4}>
                          <RadioCard value="others" label="Andra" small selected={values.gender === "others"} />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                    {!!touched.gender && errors.gender && (
                      <FormHelperText error id="gender">
                        {errors.gender}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Hidden>
                <Hidden mdUp>
                  <Select
                    id="gender"
                    type="text"
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    className={classes.select}
                    fullWidth
                  >
                    <MenuItem value="male">
                      Male
                    </MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </Hidden>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Lösenord"
                  error={touched.password && errors.password}
                  inputProps={{
                    id: 'password',
                    name: 'password',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.password,
                    type: "password",
                    fullWidth: true,
                    placeholder: "Lösenord",
                    endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Repeat Lösenord"
                  error={touched.c_password && errors.c_password}
                  inputProps={{
                    id: 'c_password',
                    name: 'c_password',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.c_password,
                    type: "password",
                    fullWidth: true,
                    placeholder: "Lösenord Confirmation",
                    endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </ModalContents>
        <Box className={classes.modalFooter} textAlign="right">
          <Button variant="simple" className={classes.cancelBtn} onClick={closeModal}>
            Annullera
          </Button>
          <LoadingButton type="submit" loading={loading} onClick={handleSubmit} variant="contained" color="green">
            {!values.id ? 'Skapa' : 'Uppdatering'}
          </LoadingButton>
        </Box>
      </Form>
    </CustomModal>
  )
}
export default FormEnchancer(EditStaff)
