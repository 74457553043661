import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { addItemToCart, clearItemFromCart, removeItemFromCart } from "./cartAction"
import { CLEAR_CART } from "./cartType"
import { REMOVE_ITEM_FROM_CART, CLEAR_ITEM_FROM_CART, ADD_ITEM_TO_CART } from "./cartType"

const STORAGE_VERSION = process.env.REACT_APP_STORAGE_VERSION

const initState = {
    items : [],
    total : 0,
    grand_total : 0,
    discount_total : 0,
}

const cartReducer = persistReducer(
    { storage, key: `cart-${STORAGE_VERSION}`, whitelist: ['items', 'total', 'grand_total', 'discount_total'] },
    (state = initState, action) => {
        const {type, payload} = action

        switch(type) {
            case ADD_ITEM_TO_CART:
                const new_data = {...state, items: addItemToCart(state, payload)}
                return new_data
            case REMOVE_ITEM_FROM_CART:
                return {...state, items: removeItemFromCart(state, payload)}
            case CLEAR_ITEM_FROM_CART:
                return {...state, items: clearItemFromCart(state, payload)}
            case CLEAR_CART:
                return  {
                    items : [],
                    total : 0,
                    grand_total : 0,
                    discount_total : 0,
                }
            default:
                return state;
        }
    }
)

export default cartReducer