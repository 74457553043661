import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getPaymentService = () => {
    return request({
        url: BASE_URL + '/payments'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => err.message)
}