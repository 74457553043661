import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/views/profileViewStyle.js"
import LoadingButton from 'components/CustomForms/LoadingButton'
import { updateMeService } from '../../../../services/UserService';

const useStyles = makeStyles(styles)

const EditPassword = (props) => {
  const classes = useStyles()

  const { open, closeModal } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  }

  const updateUserSubmit = async (e) => {
    e.preventDefault();
    if(data.newPassword !== data.confirmPassword){
      alert("Please confirm Password again");
      return;
    }
    setLoading(true)
    const res = await updateMeService({
      data
    });
    setLoading(false);
    if(res.status === 0){
      alert(res.msg);
    }else{
      alert('Password changed successfully!');
      closeModal();
    }
  }

  return (
    <CustomModal open={open} closeModal={closeModal} size="small">
      <ModalHeader title="Redigera lösenord" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                labelText="Gammalt lösenord"
                inputProps={{
                  type: "password",
                  fullWidth: true,
                  placeholder: "Input old password",
                  onChange: handleChange,
                  name: 'currentPassword',
                  endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                labelText="Nytt lösenord"
                inputProps={{
                  type: "password",
                  fullWidth: true,
                  placeholder: "Input new password",
                  onChange: handleChange,
                  name: 'newPassword',
                  endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                labelText="Bekräftelse av nytt lösenord"
                inputProps={{
                  type: "password",
                  fullWidth: true,
                  placeholder: "Input again new password",
                  onChange: handleChange,
                  name: 'confirmPassword',
                  endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Box className={classes.modalFooter} textAlign="right">
        <Button className={classes.cancelBtn} onClick={closeModal}>
          Cancel
        </Button>
        <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={updateUserSubmit}>
          Uppdatering Data
        </LoadingButton>
      </Box>
    </CustomModal>
  )
}

export default EditPassword
