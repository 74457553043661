import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Box, Hidden, Button, FormHelperText } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CustomTextField from "components/CustomForms/TextField"
import * as Yup from 'yup'

import styles from "assets/jss/views/admin/addFaqViewStyle"
import { Form, withFormik } from 'formik'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { getDetailFaqService, updateFaqService, storeFaqService } from 'services/FaqService'


const useStyles = makeStyles(styles)

const BASE_URL = process.env.REACT_APP_BASE_URL

const FormSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    question: Yup.string()
        .max(191)
        .required(),
    answer: Yup.string()
        .required(),
    is_published: Yup.bool()
        .notRequired()

})

const FormProps = {
    id: null,
    question: '',
    answer: '',
    is_published: false
}

const FormEnchancer = withFormik({
    mapPropsToValues: (props) => ({
        id: props.data?.id || null,
        question: props.data?.question || '',
        answer: props.data?.answer || '',
        is_published: props.data?.is_published || false
    }),
    validationSchema: FormSchema,
    enableReinitialize: true,
    handleSubmit: (values) => {

    },
    isInitialValid: props => FormSchema.isValidSync(FormProps),
})

const EditFaq = props => {

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        setFieldValue,
    } = props

    const classes = useStyles()

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()

    const { id } = params

    const handleSubmit = async () => {
        if (isValid) {
            try {
                setLoading(true)
                if (id) {
                    await updateFaqService(values)
                } else {
                    await storeFaqService(values)
                }

                setTimeout(() => {
                    setLoading(false)

                    history.replace('/admin/faq')
                }, 3000)


            } catch (error) {
                setLoading(false)
                setErrorMessage(error)
            }
        }
    }

    const getData = async () => {
        try {
            const data = await getDetailFaqService({ id: id })
            setFieldValue('id', data.id)
            setFieldValue('question', data.question)
            setFieldValue('answer', data.answer)
            setFieldValue('is_published', data.is_published)

        } catch (error) {

        }
    }

    useEffect(() => {
        if (id) {
            getData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container >
            <Form>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button startIcon={<ArrowBackIcon />} className={classes.backBtn} onClick={() => history.push('/admin/faq')}>
                        Tillbaka
                    </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.contentSection}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h3">
                                Lägg till Faq
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <CustomTextField
                                labelText="Fråga"
                                error={touched.question && errors.question}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Fråga",
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.question,
                                    name: "question",
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomTextField
                                labelText="Svar"
                                error={touched.answer && errors.answer}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Svar",
                                    multiline: true,
                                    rows: 6,
                                    defaultValue: '',
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.answer,
                                    name: "answer",
                                }}
                            />
                        </Grid>
                        <Hidden smDown>
                            <Grid item lg={12} md={12} sm={12}>
                                <Box textAlign="right" mt={3}>
                                    <Button className={classes.cancelBtn}>
                                        Annullera
                                    </Button>
                                    <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={handleSubmit}>
                                        Spara
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid item sm={6} xs={6}>
                        <Button className={classes.cancelBtn} fullWidth>
                            Annullera
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <LoadingButton type="submit" loading={loading} variant="contained" color="green" fullWidth onClick={handleSubmit}>
                            Spara
                        </LoadingButton>
                    </Grid>
                </Hidden>
            </Form>
        </Grid>
    )
}

export default FormEnchancer(EditFaq)
