import { Box, IconButton, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core"
import { MoreHoriz } from "@material-ui/icons"

import  styles from 'assets/jss/views/admin/faqViewStyle'
import { useState } from "react"

const useStyles = makeStyles(styles)

const TermsOfPurchaseItem = (props) => {

    const classes = useStyles()

    const {data, handleClickDelete, handleEdit, handleView, handlePublishedModal} = props

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box className={classes.staffItem}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.actionsMenu}>
                <Typography variant="body1">
                    {data.title}
                </Typography>
                <IconButton onClick={ handleClick }>
                    <MoreHoriz />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {  handleView(); }}>View</MenuItem>
                    <MenuItem onClick={() => {  handleEdit(); }}>Edit</MenuItem>
                    <MenuItem onClick={() => {  handlePublishedModal(); }}>{data.is_published ? 'Un Publish' : 'Publish'}</MenuItem>
                    <MenuItem onClick={() => { handleClickDelete(); }}>Delete</MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

export default TermsOfPurchaseItem