import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import SearchIcon from '@material-ui/icons/Search'

import { Box, Hidden, IconButton, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import AddIcon from '@material-ui/icons/Add'
import TextField from "components/CustomForms/TextField"
import CustomPagination from "components/CustomPagination"
import CustomDateField from "components/CustomForms/CustomDateField"
import OrderItem from "./components/OrderItem"
import CustomMobilePagination from "components/CustomPagination/CustomMobilePagination"
import OrderDetail from "./components/OrderDetail"
import ConfirmModal from "components/CustomModal/ConfirmModal"
import SetDeliveryModal from "./components/SetDeliveryModal"

import styles from "assets/jss/views/admin/orderViewStyle"
import FilterIcon from 'assets/img/filter.png'
import CustomButton from 'components/CustomForms/Button'
import { useHistory } from 'react-router'
import { getPaginateData } from 'views/Admin/Order/_redux/orderCrud'
import { getPaymentService } from 'services/PaymentService'
import moment from 'moment'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'

const useStyles = makeStyles(styles)

const OrderSection = () => {

  const classes = useStyles() 

  const [status, setStatus] = useState("all")

  const [paymentMethods, setPaymentMethods] = useState([])
  const [openDetail, setOpenDetail] = useState(false)
  const [openDeliveryModal, setDeliveryModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null);
  const [totalData, setTotalData] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(0) 
  const [data, setData] = useState([])

  const history = useHistory()

  const [query, setQuery] = useState({
    keyword: '',
    date: '',
    status: '',
    payment_method: ''
  })

  const columns = [
    {
      selector: row => row.invoice_number,
      name: "Invoice",
      wrap: 'true',
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.created_at,
      name: "Datum",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <Typography>
          {moment(row.created_at).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      )
    },
    {
      selector: row => row.recepient_name,
      name: "Billing Name",
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.payment,
      name: "Payment",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <Typography>
          {row.payment?.name ?? ''}
        </Typography>
      )
    },
    {
      selector: row => row.status,
      name: "Status",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <Box className={classNames(
          classes.statusBadge,
          {
            [classes.approvedStatus]: row.status === "paid",
            [classes.pendingStatus]: row.status === "pending",
            [classes.failedStatus]: row.status === "void"
          }
        )}>
          {row.status}
        </Box>
      )
    },
    {
      selector: row => row.grand_total,
      name: "Total",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <Typography variant="body1" className={classes.totalPrice}>
          {numberFormat(row.grand_total)} {currency}
        </Typography>
      )
    },
    {
      selector: row => row.id,
      name: "Action",
      grow: 4,
      cell: (row) => (
        <Box>
          {
            (row.status === "paid") && (
              <Button variant="contained" onClick={() => setDeliveryModal(true)} className={classNames(classes.actionButton, classes.deliveryBtn)} startIcon={<LocalShippingIcon />}>
                Set Delivery
              </Button>
            )
          }
          <Button variant="contained" onClick={() => { setSelectedData(row); setOpenDetail(true) }} className={classes.actionButton} startIcon={<VisibilityIcon />}>
            Detail
          </Button>

        </Box>
      )
    }
  ]

  const getData = async () => {
    try {
      const data = await getPaginateData(query)
      setData(data?.data?.data ?? [])
      setTotalPage(data?.data?.total_page ?? 0)
      setTotalData(data?.data?.total_data ?? 0)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{ page: page } })
  }

  const getPaymentMethods = async () => {
    try {
      const data = await getPaymentService()
      setPaymentMethods(data)
    } catch (err) {
      console.log(err)
    }
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props })
  }

  useEffect(() => {
    getData()
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getData()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (!openDetail) {
      setSelectedData(null)
    }
  }, [openDetail])

  useEffect(() => {
    if (!openDeliveryModal) {
      setSelectedData(null)
    }
  }, [openDeliveryModal])

  

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.tableWrapper}>
          <Box className={classes.filterWrapper}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <TextField  
                  disableLabel
                  className={classes.searchForm}
                  inputProps={{
                    type: "text",
                    fullWidth: true,
                    placeholder: "Search product",
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    onChange: (e) => { changeQuery({ keyword: e.target.value }) }
                  }}
                />
                <Hidden mdUp>                  
                  <IconButton>
                    <img src={FilterIcon} alt="Filter" />
                  </IconButton>              
                </Hidden>
              </Grid>              
              <Hidden smDown>
                <Grid item lg={3} md={3} sm={12}>
                  <CustomDateField />
                </Grid>
                <Grid item lg={3} md={3} sm={12} className={classes.selectWrapper}>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className={classes.select}
                    fullWidth
                  >
                    <MenuItem value="all">
                      All Status
                    </MenuItem>
                    <MenuItem value="option 1">Option 1</MenuItem>
                    <MenuItem value="option 2">Option 2</MenuItem>
                  </Select>
                </Grid>
                <Grid item lg={3} md={3} sm={12} className={classes.selectWrapper}>
                <CustomButton variant="contained" color="green" onClick={() => history.push('/staff/product')} fullWidth startIcon={<AddIcon />} >
                    Add New Order
                  </CustomButton>
                </Grid>
              </Hidden>              
            </Grid>
          </Box>
          <Hidden smDown>
            <Box className={classes.dataTable}>
            <CustomDataTable
                columns={columns}
                data={data}
                striped={false}
                center={true}
                highlightOnHover={true}
                responsive={true}
                persistTableHead={false}
                progressPending={loading}
                pagination
                paginationServer
                currentPage={query.page}
                paginationPerPage={query.limit}
                paginationTotalRows={totalData}
                onChangePage={(page) => changePage(page)}
              />
            </Box>    
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.ordersList}>
              {
                data.map((order, index) => (
                    index < 3 && 
                    <OrderItem 
                      key={order.id} 
                      order={order} 
                      openDetailModal={() => {
                        setSelectedData(order); 
                        setOpenDetail(true) 
                        }
                      }
                      openDeleteConfirmModal={() => {}}
                      openDeliveryModal={() => setDeliveryModal(true)}
                    />
                ))
              }
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Hidden mdUp>
        <Grid item sm={12} xs={12}>
          <Box mt={3}>
            <CustomMobilePagination />
          </Box>
        </Grid>
      </Hidden>
      <OrderDetail
        open={openDetail}
        closeModal={() => setOpenDetail(false)}
      />
      <SetDeliveryModal 
        open={openDeliveryModal}
        closeModal={() => setDeliveryModal(false)}
      />
    </Grid>
  )
}

export default OrderSection
