import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Button from "components/CustomForms/Button"

import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/components/orderDetailModalStyle"
import moment from 'moment'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const OrderDetail = (props) => {

  const classes = useStyles()

  const { open, closeModal, transaction } = props

  return (
    <CustomModal open={open} closeModal={closeModal}>
      <ModalHeader title={`Detaljbeställning ${transaction.invoice_number}`} closeModal={closeModal} />
      <Typography variant="body1" className={classes.orderDate} align="center">
        {moment(transaction.created_at).format('MMMM Do YYYY, h:mm:ss a')}
      </Typography>
      <ModalContents>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.contentBlock, classes.billingInfoSection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Faktureringsinformation
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingLeft}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Frakt Metod
                    </Typography>
                    <Typography variant="body1">
                      Type {transaction.shipping_method?.id}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Spårningsnummer
                    </Typography>
                    <Typography variant="body1">
                      {transaction.tracking_number}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Anteckningar
                    </Typography>
                    <Typography variant="body1">
                      {transaction.recepient_notes ?? '-'}
                    </Typography>
                  </Box>
                </Grid>

                <Hidden mdUp>
                  <Grid item xs={12} className={classes.setPadding}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Hidden>

                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingRight}>
                  <Typography variant="body1">
                    {transaction.recepient_name}
                  </Typography>
                  <Typography variant="body1">
                    {transaction.recepient_email}
                  </Typography>
                  <Typography variant="body1">
                    {transaction.recepient_phone_number}
                  </Typography>
                  <Typography variant="body1">
                    {transaction.recepient_stree_address}
                  </Typography>
                  <Typography variant="body1">
                    {transaction.recepient_street_number}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.orderListSection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Orderlista
              </Typography>
              {transaction.transaction_items.map((item) => (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderLine}>
                    <Box>
                      <Typography variant="body1">
                        {item.product_variant.product_name}
                      </Typography>
                      <Typography variant="body1">
                        {numberFormat(item.quantity)} X {numberFormat(item.selling_price_per_item)} {currency}
                      </Typography>
                    </Box>
                    <Typography variant="body1">
                      {numberFormat(item.total)} {currency}
                    </Typography>
                  </Box>
                  <Divider className={classes.divider} />
                </>
              ))}
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.summarySection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Ordersammanfattning
              </Typography>
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Betalningsmetod
                </Typography>
                <Typography variant="body1">
                  {transaction.payment.name}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Delsumma
                </Typography>
                <Typography variant="body1">
                  {transaction.transaction_items.length > 0 ? numberFormat(transaction.transaction_items.reduce((prev, next) => prev + (next.selling_price_per_item * next.quantity), 0)) : 0} {currency}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Fraktkostnad
                </Typography>
                <Typography variant="body1">
                  {numberFormat(transaction.shipping_price)} {currency}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" justifyContent="space-between" className={classes.totalPayment}>
                <Typography variant="body1">
                  Total betalning
                </Typography>
                <Typography variant="body1">
                  {numberFormat(transaction.grand_total)} {currency}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Box className={classes.footer} textAlign="right">
        <Button onClick={closeModal} className={classes.closeBtn}>
          Stänga
        </Button>
        {transaction.statun === 'paid' && (
          <Hidden smDown>
            <Button variant="contained" color="green" onClick={closeModal}>
              Setleverans
            </Button>
          </Hidden>
        )}

      </Box>
    </CustomModal>
  )
}

export default OrderDetail
