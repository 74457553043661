import { Box, IconButton, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core"
import { MoreHoriz } from "@material-ui/icons"

import  styles from 'assets/jss/views/admin/categoryViewStyle'
import { useState } from "react"

const useStyles = makeStyles(styles)

const CategoryItem = (props) => {

    const classes = useStyles()

    const {data, handleClickDelete, handleEdit, handleView} = props

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box className={classes.staffItem}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.actionsMenu}>
                <Typography variant="body1">
                    {data.name}
                </Typography>
                <IconButton onClick={ handleClick }>
                    <MoreHoriz />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {  handleEdit(); }}>Redigera</MenuItem>
                    <MenuItem onClick={() => { handleClickDelete(); }}>Ta bort</MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

export default CategoryItem