import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Box, useScrollTrigger } from '@material-ui/core'
import Button from 'components/CustomForms/Button'
import Link from '@material-ui/core/Link'
import Badge from '@material-ui/core/Badge'
import Hidden from '@material-ui/core/Hidden'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'

import marketNavbarStyles from "assets/jss/components/marketNavbarStyles"

import userAvatar from "assets/img/checkout/user.png"

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'
import { getTotalQuantity } from 'views/Market/Cart/_redux/cartAction'


import Logo from "assets/img/footer-logo.png"
import MobileLogo from "assets/img/baloon-company-mobile.png"


const useStyles = makeStyles(marketNavbarStyles)

const ElevationScroll = (props) => {



	const { children, window } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined
	})

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
		style: trigger ? { backgroundColor: '#0F1317' } : { backgroundColor: 'transparent' }
	})
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,

}

const MarketNavbar = (props) => {

	const classes = useStyles()

	const auth = useSelector((state) => state.user)
	const cart = useSelector((state) => state.cart)

	const { user, isLoggedIn } = auth

	const dispatch = useDispatch()

	const history = useHistory()

	const [showMenu, setShowMenu] = useState(false)

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	const handleOpenMenu = (e) => {
		setAnchorEl(e.currentTarget)
	}
	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleMobileMenu = () => {
		setShowMenu(!showMenu)
	}

	const location = useLocation()

	const login = () => {
		history.replace('/login')
	}

	const logout = () => {

		dispatch({ type: 'LOGOUT', payload: null })
		localStorage.removeItem('_token')
		history.replace('/login')

	}

	const isRoleExists = (roleName) => {
		if (!user) {
			return false
		}

		const findRole = user.roles.findIndex(
			(item) => item.name === roleName
		)

		if (findRole > -1) {
			return true
		}

		return false

	}

	return (
		<Box>
			<ElevationScroll {...props}>
				<AppBar position="fixed" className={classNames({ [classes.mobileNavbar]: showMenu, [classes.navbar]: true })}>
					<Toolbar>
						{
							!location.pathname.includes('auth') && (
								<Fragment>
									<Hidden smDown>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/') }}
										>
											<Hidden xsDown>
												<img src={Logo} alt="Logo" width={60} />
											</Hidden>
											<Hidden smUp>
												<img src={MobileLogo} alt="Logo" width={60}  />
											</Hidden>
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/') }}
										>
											Hem
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/all-product') }}
										>
											Alla produkter
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/blog') }}
										>
											Blogg
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/faq') }}
										>
											FAQ
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/terms-of-purchase') }}
										>
											Köpvillkor
										</Link>
									</Hidden>

									<Hidden smDown>
										<Box className={classes.grow} />
										<IconButton className={classes.cartBtn} onClick={() => history.push('/cart')}>
											<Badge badgeContent={getTotalQuantity(cart.items)} color="primary">
												<ShoppingCartIcon />
											</Badge>
										</IconButton>
										{
											isLoggedIn === true ? (
												<Button
													className={classes.loggedBtn}
													onClick={handleOpenMenu}
												>
													{console.log('ffff', user)}
													<img src={user.user_profile ?? userAvatar} alt="Avatar" width="52px" height="52" />
													{user.name}
													<KeyboardArrowDownIcon />
												</Button>
											) : (
												<Button
													variant="contained"
													color="green"
													className={classes.loginBtn}
													onClick={login}
												>
													Logga in
												</Button>
											)
										}
									</Hidden>
								</Fragment>
							)
						}

						<Hidden mdUp>
							<Box className={classes.mobileNav} display="flex" alignItems="center">
								<IconButton className={classes.menuBtn} onClick={handleMobileMenu}>
									<MenuIcon />
								</IconButton>
								<Box className={classes.grow} />
								{
									!showMenu ? (
										<Fragment>
											<IconButton className={classes.cartBtn}>
												<Badge badgeContent={getTotalQuantity(cart.items)} color="primary" onClick={() => history.push('/cart')}>
													<ShoppingCartIcon />
												</Badge>
											</IconButton>
											{
												isLoggedIn ? (
													<Button
														className={classes.loggedBtn}
														onClick={handleOpenMenu}
													>
														<img src={user.user_profile ?? userAvatar} alt="Avatar" width="52px" height="52" />
														<KeyboardArrowDownIcon />
													</Button>
												) : (
													<Button
														variant="contained"
														color="green"
														className={classes.loginBtn}
														onClick={login}
													>
														Logga in
													</Button>
												)
											}
										</Fragment>
									) : (
										<IconButton className={classes.closeBtn} onClick={handleMobileMenu}>
											<CloseIcon />
										</IconButton>
									)
								}
							</Box>
							{
								showMenu &&
								<Box className={classes.mobileMenu}>
									<FormControl fullWidth className={classes.searchForm} variant="outlined">
										<OutlinedInput
											placeholder="Search product"
											startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
										/>
									</FormControl>
									<Box display="flex" flexWrap="wrap" mt={3}>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/') }}
										>
											Hem
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/all-product') }}
										>
											Alla produkter
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/blog') }}
										>
											Blogg
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/faq') }}
										>
											FAQ
										</Link>
										<Link
											href="/"
											className={classes.menuItem}
											onClick={(e) => { e.preventDefault(); history.push('/terms-of-purchase') }}
										>
											Köpvillkor
										</Link>
									</Box>
								</Box>
							}
						</Hidden>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleCloseMenu}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							className={classes.profileMenu}
						>
							<MenuItem onClick={() => history.push('/profile')}>Profil</MenuItem>
							<MenuItem onClick={() => history.push('/transaction-history')}>Transaktionshistorik</MenuItem>
							<MenuItem onClick={logout}>Logga ut</MenuItem>
						</Popover>
					</Toolbar>
				</AppBar>
			</ElevationScroll>
		</Box>
	)
}

export default MarketNavbar
