import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getVoucherService = props => {
    
    const {page, limit, keyword, is_published} = props
    
    return request({
        url: BASE_URL + '/voucher',
        queryParams : {
            page : page ?? 1,
            limit : limit ?? 10,
            keyword: keyword ?? '',
            is_published: is_published ?? true
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const storeVoucherService  = props => {
    return request({
        url: BASE_URL + '/voucher',
        body: props,
        method : 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getVoucherDetailService = props => {

    const {slug} = props

    return request({
        url: BASE_URL  + '/voucher/' + slug + '/by-code',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export  const updateVoucherService = props => {
    return request({
        url: BASE_URL + '/voucher/' + props.id + '/update',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const destroyVoucherService = props => {
    return request({
        url: BASE_URL + '/voucher/' + props.id + '/destroy',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const validateVoucherService = props => {
    return request({
        url: BASE_URL + '/voucher/validate-voucher',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}