import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import Market from "layouts/Market"
import Admin from "layouts/Admin"
import Staff from 'layouts/Staff'
import "assets/scss/index.scss"
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import ScrollToTop from 'components/ScrollToTop'
import { PersistGate } from 'redux-persist/integration/react'
import ForbiddenAccess from 'views/Errors/ForbiddenAccess'
import InitComponent from 'components/InitComponent'


const theme = createTheme({
  palette: {
    green: {
      main: "#1DB954"
    },
    black: {
      main: "#0F1317"
    }
  }
})

ReactDOM.render(
  <Provider store={store} >
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <InitComponent>
          <ScrollToTop>
            <Switch>
              <Route path="/admin" component={Admin} />
              <Route path="/staff" component={Staff} />
              <Route path="/403" component={ForbiddenAccess} />
              <Route path="/" component={Market} />
            </Switch>
          </ScrollToTop>
          </InitComponent>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
