import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { Box, Hidden, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import CustomIconButton from "components/CustomForms/CustomIconButton"

import styles from "assets/jss/views/admin/productViewStyle"
import numberFormat from 'utils/number-format'
import { useHistory } from 'react-router-dom'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const ProductItem = (props) => {

  const classes = useStyles()
  const history = useHistory()
  const { product, onClickDelete } = props

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.productItem}>
      <Hidden mdUp>
        <Box textAlign="right" className={classes.actionsMenu}>
          <IconButton onClick={ handleClick }>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => history.push('/admin/product/edit/' + product.id)}>Edit</MenuItem>
            <MenuItem onClick={() => {
              onClickDelete()
            }}>Delete</MenuItem>
          </Menu>
        </Box>  
      </Hidden>          
      <img src={product.thumbnail} alt="Product" width="82px" height="82px" />
      <Box className={classes.productInfo}>
        <Typography variant="body1" className={classes.productTitle}>
          { product.name }
        </Typography>
        <Box display="flex">
          <Box className={classes.infoItem}>
            <Typography variant="body1">
              Tidigare pris :
            </Typography>
            <Typography variant="body1">
              { `${numberFormat(product.product_variants[0].undiscounted_selling_price)} ${currency}` }
            </Typography>
          </Box>
          <Box className={classes.infoItem}>
            <Typography variant="body1">
              Pris :
            </Typography>
            <Typography variant="body1">
              { `${numberFormat(product.product_variants[0].selling_price)} ${currency}` }
            </Typography>
          </Box>
        </Box>        
      </Box>
      <Hidden smDown>
        <Box className={classes.actionList}>
          {/* <CustomIconButton><VisibilityIcon /></CustomIconButton> */}
          <CustomIconButton onClick={() => history.push('/admin/product/edit/' + product.id)}><EditIcon /></CustomIconButton>
          <CustomIconButton onClick={() => {
            onClickDelete()
          }}><DeleteIcon /></CustomIconButton>
        </Box>
      </Hidden>     
    </Box>
  )
}

export default ProductItem
