import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { Box, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import styles from "assets/jss/views/admin/broadcastViewStyle"
import moment from 'moment'

const useStyles = makeStyles(styles)

const BroadcastItem = (props) => {

  const classes = useStyles() 

  const { broadcast, handleClickDelete } = props

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.staffItem}>
      <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.actionsMenu}>
        <Typography variant="body1">
          { broadcast.title }
        </Typography>
        <IconButton onClick={ handleClick }>
          <MoreHorizIcon />
        </IconButton>
      </Box>
      <Typography variant="body1">
        { moment(broadcast.created_at).format('Y-m-d H:m:s') }
      </Typography>
      <Typography variant="body1">
        { broadcast.to_user?.email || broadcast.to_role?.name }
      </Typography>      
    </Box>
  )
}

export default BroadcastItem
