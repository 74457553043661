import { Button, Grid, Hidden, InputAdornment } from '@material-ui/core'
import React from 'react'
import OrderSection from './sections/order/OrderSection'
import StatisticSection from './sections/statistic/StatisticsSection'


const Dashboard = () => {

    return(
        <Grid container spacing={3}>
            <OrderSection/>
        </Grid>
    )
}

export default Dashboard