import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'


import SearchIcon from '@material-ui/icons/Search'

import { Box, Container, Grid, Hidden } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'

import Breadcrumb from "components/Breadcrumb"
import Button from "components/CustomForms/Button"

import styles from "assets/jss/views/blogViewStyle"

import { getBlogService } from 'services/BlogService'
import BlogItem from 'components/BlogItem/BlogItem'
import { Stack } from '@mui/material'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles(styles)

const Blog = () => {

  const classes = useStyles()

  const [blogs, setBlogs] = useState([])
  const [query, setQuery] = useState({
    keyword: '',
    page: 1,
    limit: 10,
    is_published: false
  })

  const [totalPage, setTotalPage] = useState(1)


  const getData = async () => {
    try {
      const data = await getBlogService(query)
      setBlogs(data?.data ?? [])
      setTotalPage(data?.total_page ?? 0)
    } catch (err) {
      console.log(err)
    }
  }

  const changePage = (page) => {
    setQuery({ ...setQuery, ...{ page: page } })
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getData()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.headerSection}>
        <Hidden smDown>
          <Breadcrumb breadcrumb={["Hem", "Blogg"]} />
        </Hidden>
        <Box className={classes.headerContents}>
          <Typography variant="h3" fontWeight={700} align="center">
            BLOGG
          </Typography>
          <Typography variant="body1" align="center">
            Blogg
          </Typography>
          <Box className={classes.searchFormWrapper}>
            <FormControl fullWidth className={classes.searchForm} variant="outlined">
              <OutlinedInput
                placeholder="Sök blogg"
                value={query.keyword}
                onChange={e => changeQuery({keyword: e.target.value})}
                endAdornment={<InputAdornment position="start"><Button className={classes.searchBtn}><SearchIcon /></Button></InputAdornment>}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box className={classes.blogContents}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {
              blogs.map(blog => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={blog.id}>
                  <BlogItem blog={blog} />
                </Grid>
              ))
            }
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" className={classes.pagination}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={query.page}
                  onChange={async (e, value) => {
                    changePage(value)
                  }}
                  variant="outlined"
                  shape="rounded" />
              </Stack>
            </Box>
          </Grid>
        </Container>

      </Box>
    </Box>
  )
}

export default Blog
