import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, FormHelperText, Hidden } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/views/admin/userViewStyle"
import { Form, withFormik } from 'formik'
import * as Yup from 'yup'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { Alert, AlertTitle } from '@mui/material'
import { storeMdLocationStateService, updateMdLocationStateService } from 'services/MdLocationStateService'

const useStyles = makeStyles(styles)

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .max(191, 'maximalt antal tecken 191')
    .required('Namn krävs')
})



const FormEnchancer = withFormik({
  mapPropsToValues: (props) => ({
    id: props.item.id || null,
    name: props.item.name || ''
  }),
  validationSchema: props => FormSchema,
  enableReinitialize: true,
  handleSubmit: (values) => {

  },
  isInitialValid: props => FormSchema.isValidSync(props.item)
})

const EditMdLocationState = props => {

  const classes = useStyles()

  const {
    open,
    closeModal,
    item,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    handleReset
  } = props;

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  

  const storeOrUpdate = async () => {
    try {
      if (!values.id) {
        await storeMdLocationStateService({...values})
      } else {
        await updateMdLocationStateService({...values})
      }
    } catch (error) {
      throw error
    }

  }

  const handleSubmit = async () => {
    
    if (isValid) {
      try {
        setLoading(true)
        await storeOrUpdate()
        setTimeout(() => {

          setLoading(false)
          // setIsNew(true)
          setErrorMessage('')
          closeModal()
          handleReset()
        }, 3000)

      } catch (error) {
        setLoading(false)
        setErrorMessage(error)
      }
    }
  }

  return (
    <CustomModal open={open} closeModal={closeModal} >
      <Form>
        <ModalHeader title={`${!values.id ? 'Skapa ny' : 'Redigera befintliga '} Ort`} closeModal={closeModal} />
        <ModalContents>
          <Container>
            <Grid container spacing={3}>
              {errorMessage && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Alert severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                </Grid>
              )}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Namn"
                  error={touched.name && errors.name}
                  inputProps={{
                    id: 'name',
                    name: 'name',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.name,
                    type: "text",
                    fullWidth: true,
                    placeholder: "Namn",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </ModalContents>
        <Box className={classes.modalFooter} textAlign="right">
          <Button variant="simple" className={classes.cancelBtn} onClick={closeModal}>
            Annullera
          </Button>
          <LoadingButton type="submit" loading={loading} onClick={handleSubmit} variant="contained" color="green">
            {!values.id ? 'Skapa' : 'Uppdatering'}
          </LoadingButton>
        </Box>
      </Form>
    </CustomModal>
  )
}
export default FormEnchancer(EditMdLocationState)
