import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CustomButton from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/views/admin/staffViewStyle"
import LoadingButton from 'components/CustomForms/LoadingButton'
import * as Yup from 'yup';
import { Form, withFormik } from 'formik'
import { storeNotificaitonService } from 'services/NotificationService'
import { Alert, AlertTitle } from '@mui/material'
const useStyles = makeStyles(styles)


const MessageSchema = Yup.object().shape({
  title: Yup.string()
    .max(191, 'maximalt antal tecken 191')
    .required('Title is Required'),
  to_user_ids: Yup.array()
    .required('User receiver is required!'),
    content: Yup.string()
    .required('Message is required!')
})

const FormEnchancer = withFormik({
  mapPropsToValues: (props) => ({
    to_user_ids: [props.user.id],
    title: 'Message From Admin',
    content: '',
  }),
  validationSchema: props => MessageSchema,
  enableReinitialize: true,
  handleSubmit: (values) => {

  },
  isInitialValid: props => MessageSchema.isValidSync(props.user)
})

const MessageStaff = (props) => {

  const classes = useStyles()

  const {
    open,
    closeModal,
    user,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue,
    handleReset,
  } = props;
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleSubmit = async () => {
    console.log(values)
    if (isValid) {
      try {
        setLoading(true)
        await storeNotificaitonService(values)
        setTimeout(() => {

          setLoading(false)
          // setIsNew(true)
          setErrorMessage('')
          closeModal()
          handleReset()
        }, 3000)

      } catch (error) {
        setLoading(false)
        setErrorMessage(error)
      }
    }
  }

  return (
    <CustomModal open={open} closeModal={closeModal} size="small" >
      <ModalHeader title="Message Staff" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            {errorMessage && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Alert severity='error'>
                  <AlertTitle>Fel</AlertTitle>
                  {errorMessage}
                </Alert>
              </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="body1" className={classes.selectedStaff}>
                Vald personal: <span>{user.name}</span>
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                labelText="Meddelande"
                error={touched.content && errors.content}
                inputProps={{
                  id: 'content',
                  name: 'content',
                  onChange: handleChange,
                  onBlur: handleBlur,

                  value: values.content,
                  type: "text",
                  multiline: true,
                  rows: 4,
                  fullWidth: true,
                  placeholder: `Ange ditt meddelande till ${user.name}`,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Box className={classes.modalFooter} textAlign="right">
        <CustomButton variant="simple" className={classes.cancelBtn} onClick={closeModal}>
          Annullera
        </CustomButton>
        <LoadingButton loading={loading} variant="contained" color="green" onClick={handleSubmit}>
          Skicka
        </LoadingButton>
      </Box>
    </CustomModal>
  )
}

export default FormEnchancer(MessageStaff)
