import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from "components/CustomForms/Button"

import Breadcrumb from "components/Breadcrumb"
import ProductItem from "components/ProductItem"

import styles from "assets/jss/views/productDetailViewStyle"
import { useParams } from 'react-router-dom'
import request from 'utils/request'
import numberFormat from 'utils/number-format'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import currency from 'utils/currency'
const useStyles = makeStyles(styles)

const ProductDetail = () => {

	const classes = useStyles()

	const [products, setProducts] = useState([])
	const [product, setProduct] = useState({
		product_images: []
	})
	const [selectedImage, setSelectedImage] = useState('')
	const [productVariant, setProductVariant] = useState(null)
	const { slug } = useParams()

	const dispatch = useDispatch()
	const cart = useSelector(item => item.cart)

	const {items } = cart

	const cartItem = items.find((item) => {
		if(productVariant){
			if(productVariant.id === item.id) {
				return item;
			}
		}
	})

	useEffect(() => {



		const BASE_URL = process.env.REACT_APP_BASE_URL

		const getProducts = () => {

			request({
				url: BASE_URL + '/products',
				method: 'GET',
			})
				.then(res => {
					setProducts(res.data?.data ?? [])
				})
				.catch(error => {
					throw error.message
				})
		}

		const getProduct = () => {

			const getId = slug.split('-').at(-1)

			request({
				url: BASE_URL + '/products/' + getId + '/detail',
				method: 'GET',
			})
				.then(res => {
					setProduct(res.data)
					setSelectedImage(res.data.thumbnail)
					setProductVariant(res.data.product_variants[0])
				})
				.catch(error => {
					throw error.message
				})
		}

		getProducts()
		getProduct()

		// eslint-disable-next-line react-hooks/exhaustive-deps
		
	}, [slug])


	return (
		<Box className={classes.wrapper}>
			<Breadcrumb breadcrumb={["Hem", "Cas Container", product.name]} />

			<Box className={classes.detailSection}>
				<Container maxWidth="xl">
					<Grid container spacing={3}>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Box className={classes.productImg} textAlign="center">
								<img src={selectedImage} alt="Product" />
							</Box>
							<Box display="flex" className={classes.imageList} justifyContent="space-between">
								{product.product_images.map((item) => (
									<img src={item.image_path} alt="Product" onClick={() => setSelectedImage(item.image_path)} className={classNames({ [classes.activeGallery]: selectedImage === item.image_path })} />
								))}
							</Box>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Box className={classes.productCard}>
								<Typography variant="h3" className={classes.productTitle}>
									{product.name}
								</Typography>
								{productVariant && productVariant.undiscounted_selling_price && (
									<Typography variant="body1" className={classes.discount}>
										{numberFormat(productVariant.undiscounted_selling_price)} {currency}
									</Typography>
								)}

								<Box position="relative" className={classes.priceSection}>
									<Typography variant="body1" className={classes.price}>
										{numberFormat(productVariant?.selling_price)}
									</Typography>
									<Typography variant="body1" className={classes.currency}>
										kr
									</Typography>
								</Box>
								<Typography variant="body1" className={classes.detail}>
									{product.description}
								</Typography>
								<Box>
									<Container className={classes.counterSection}>
										<Grid container>
											<Grid item xs={6}>
												<Box>
													<Typography variant="body1" className={classes.label}>
														Kvantitet
													</Typography>
													<Box display="flex">
														<Button variant="contained" color="green" className={classes.counterBtn} onClick={() => { (cartItem?.quantity ?? 0) > 0 && dispatch({type: 'REMOVE_ITEM_FROM_CART', payload : {...productVariant, quantity : 1}}) }}>
															-
														</Button>
														<Box className={classes.count} display="flex" alignItems="center" justifyContent="center">
															<Typography variant="body1" align="center">
																{cartItem?.quantity ?? 0}
															</Typography>
														</Box>
														<Button variant="contained" color="green" className={classes.counterBtn} onClick={() => dispatch({type: 'ADD_ITEM_TO_CART', payload : {...productVariant, quantity : 1}}) }>
															+
														</Button>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box>
													<Typography variant="body1" className={classes.label}>
														Delsumma
													</Typography>
													<Typography variant="body1" className={classes.subTotal}>
														{`${numberFormat((productVariant?.selling_price ?? 0) * (cartItem?.quantity ?? 0))} ${currency}`}
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Container>
								</Box>
								<Box>
									<Button variant="contained" color="green" fullWidth>
										Lägg till i Kundvagn
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box className={classes.relatedProducts}>
				<Container maxWidth="xl">
					<Grid container spacing={3}>
						<Grid item xs={12} className={classes.titleSection}>
							<Typography variant="h3" fontWeight={700} align="center">
								Mer relaterad produkt
							</Typography>
						</Grid>
						<Container maxWidth="xl">
							<Grid container spacing={3} className={classes.productList}>
								{
									products.map((productItem, index) => (
										<Grid item lg={3} md={3} sm={6} xs={6} key={index}>
											<ProductItem product={productItem} />
										</Grid>
									))
								}
							</Grid>
						</Container>
					</Grid>
				</Container>
			</Box>
		</Box>
	)
}

export default ProductDetail
