import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from 'components/CustomForms/Button'

import ProductItem from "components/ProductItem"

import styles from "assets/jss/views/landingViewStyle"

import request from 'utils/request'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const AllProductsSection = () => {

    const classes = useStyles()  

    const [products, setProducts] = useState([])

    const history = useHistory()

    

    useEffect(() => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        request({
            url : BASE_URL + '/products',
            method : 'GET',
        })
        .then(res => {
            setProducts(res.data?.data ?? [])
        })
        .catch(error => {
            throw error.message
        })
    }, [])

    return (
        <Box className={classes.productSection}>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classNames(classes.titleSection, classes.zIndex1)}>
                        <Typography variant="h3" fontWeight={700} align="center">
                            Alla Produkter
                        </Typography>
                        <Typography variant="body1" align="center">
                            {/* Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aliquet. */}
                        </Typography>
                    </Grid>
                    {
                        products.map((productItem, index) => (
                            <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                                <ProductItem product={productItem}/>
                            </Grid> 
                        ))
                    }
                    <Grid item xs={12}>
                        <Box mt={4} textAlign="center" className={classes.seeAllProduct}>
                            <Button onClick={() => history.push('/all-product')} variant="contained" color="green">
                                    Se alla produkter
                                <ChevronRightIcon />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default AllProductsSection
