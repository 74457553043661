import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getTransactionService = props => {

    return request({
        url: BASE_URL + '/transaction',
        queryParams : props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => err.message)

}

export const getTransactionById = props => {
    const {id} = props
    return request({
        url: BASE_URL + '/transaction/'+ id +'/detail',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => err.message)
}

export const setToDeliveryTransactionService = props => {
    const {transaction_id, user_id} = props

    return request({
        url: BASE_URL + '/transaction/set-to-deliver',
        method: 'POST',
        body: {
            transaction_id: transaction_id,
            user_id: user_id
        }
    })
    .then(res => res.data)
    .catch(err => err.message)
}