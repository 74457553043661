import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getBlogService = props => {
    
    const {page, limit, keyword, is_published} = props
    
    return request({
        url: BASE_URL + '/blog',
        queryParams : {
            page : page ?? 1,
            limit : limit ?? 10,
            keyword: keyword ?? '',
            is_published: is_published ?? true
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const storeBlogService  = props => {
    return request({
        url: BASE_URL + '/blog',
        body: props,
        method : 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getBlogDetailService = props => {

    const {slug} = props

    return request({
        url: BASE_URL  + '/blog/' + slug + '/by-slug',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export  const updateBlogService = props => {
    return request({
        url: BASE_URL + '/blog/' + props.id + '/update',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const updatePublishStatusBlogService = props => {
    return request({
        url: BASE_URL + '/blog/' + props.id + '/update-publish-status',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const destroyBlogService = props => {
    return request({
        url: BASE_URL + '/blog/' + props.id + '/destroy',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}