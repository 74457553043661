import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Breadcrumb from "components/Breadcrumb"
import TransactionHistoryItem from "components/TransactionHistoryItem"
import TransactionDetail from "./components/TransactionDetail"

import styles from "assets/jss/views/transactionHistoryViewStyle"
import request from 'utils/request'

const useStyles = makeStyles(styles)

const BASE_URL = process.env.REACT_APP_BASE_URL


const TransactionHistory = () => {

  const classes = useStyles()

  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [histories, setHistories] = useState([])
  const [historyModal, setHistoryModal] = useState({})
  useEffect(() => {
    request({
      url : BASE_URL + '/order'
    })
    .then((res) => res.data)
    .then(res => setHistories(res))
  }, [])

  return (
    <Box className={classes.wrapper}>
      <Hidden smDown>
        <Breadcrumb breadcrumb={["Hem", "Transaktionshistorik"]}/>
      </Hidden>
      <Box className={classes.contentSection}>
        <Typography variant="h3" align="center" fontWeight={700}>
          Transaktionshistorik
        </Typography>
        <Container maxWidth="xl" className={classes.transactionContainer}>
          <Grid container spacing={3}>
            {
              histories.map((history) => (
                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.pageTitle} key={history.id}>
                  <TransactionHistoryItem history={history} openModal={() => {
                    setOpenDetailModal(true)
                    setHistoryModal(history)
                  }} />
                </Grid>
              ))
            }            
          </Grid>
        </Container>
      </Box>
      <TransactionDetail 
        open={openDetailModal} 
        data={historyModal}
        closeModal={() => setOpenDetailModal(false)} 
      />
    </Box>
  )
}

export default TransactionHistory
