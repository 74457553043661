const { useEffect } = require("react")
const { useDispatch } = require("react-redux")
const { useSelector } = require("react-redux")
const { getUserMeService } = require("services/UserService")

const InitComponent = ({children}) => {
    
    const auth = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const {isLoggedIn} =  auth

    const getAndUpdateUserRedux = async() => {
        try{
            const data = await getUserMeService()
            dispatch({type: 'UPDATE_USER', payload: data})
        }catch(err) {

        }
        
    }

    useEffect(() => {
        if(isLoggedIn) {
            getAndUpdateUserRedux()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {children}
        </>
    )
}

export default InitComponent