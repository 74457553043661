import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import styles from "assets/jss/views/admin/dashboardViewStyle"

import TotalIncome from "assets/img/dashboard/total-income.png"
import ProductSold from "assets/img/dashboard/dropbox.png"
import SmartPhone from "assets/img/dashboard/smartphone 1.png"
import request from 'utils/request'
import { getBadgeDataDashboardService } from 'services/DashboardService'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'
import { ArrowUpwardOutlined } from '@material-ui/icons'

const useStyles = makeStyles(styles)

const InitProps = {
  current_income: 0,
  last_month_income: 0,
  average_sale: 0,
  total_product_solds: 0
}

const StatisticsSection = () => {

  const classes = useStyles()

  const [badgeData, setBadgeData] = useState(InitProps)

  const getBadgeData = async () => {
    try {
      const res = await getBadgeDataDashboardService()
      console.log(res)
      setBadgeData({ ...badgeData, ...res })
    } catch (err) {

    }
  }

  useEffect(() => {
    getBadgeData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Box className={classNames(classes.cardItem, classes.totalIncome)} display="flex" alignItems="center">
          <Box className={classNames(classes.cardImg, classes.totalIncomeImg)}>
            <img src={TotalIncome} alt="Total Income" />
          </Box>
          <Box>
            <Typography variant="body1" className={classes.cardTitle}>
              Total inkomst
            </Typography>
            <Typography variant="h6" className={classes.cardMark}>
              {numberFormat(badgeData.current_income)} {currency}
            </Typography>
            <Typography variant="body1">

              {
                badgeData.current_income >= badgeData.last_month_income ?
                  (
                    <span style={{ color: 'green' }}>
                      <ArrowUpwardOutlined /> {numberFormat(badgeData.last_month_income)} {currency}
                    </span>
                  ) : (
                    <span style={{ color: 'red' }}>
                      <ArrowDownwardOutlinedIcon /> {numberFormat(badgeData.last_month_income)} {currency}
                    </span>
                  )
              }



              &nbsp;Förra månadens inkomst
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Box className={classNames(classes.cardItem, classes.averageSale)} display="flex" alignItems="center">
          <Box className={classNames(classes.cardImg, classes.averageSaleImg)}>
            <img src={SmartPhone} alt="Genomsnittlig försäljning" />
          </Box>
          <Box>
            <Typography variant="body1" className={classes.cardTitle}>
              Genomsnittlig försäljning
            </Typography>
            <Typography variant="h6" className={classes.cardMark}>
              {numberFormat(badgeData.average_sale)} {currency}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Box className={classNames(classes.cardItem, classes.productSold)} display="flex" alignItems="center">
          <Box className={classNames(classes.cardImg, classes.productSoldImg)}>
            <img src={ProductSold} alt="Total Products Sold" />
          </Box>
          <Box>
            <Typography variant="body1" className={classes.cardTitle}>
              Totala produkter som säljs
            </Typography>
            <Typography variant="h6" className={classes.cardMark}>
              {numberFormat(badgeData.total_product_solds)} pcs
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  )
}

export default StatisticsSection
