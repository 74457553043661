const UserProps = {
    id: null,
    name: '',
    username: '',
    password: '',
    c_password: '',
    email: '',
    date_of_birth: '',
    gender: '',
    phone_number: '',
    user_addresses : [],
}

export default UserProps