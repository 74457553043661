import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from "react-router-dom"

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import { Box, FormHelperText } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from '@material-ui/core/Link'
import Hidden from '@material-ui/core/Hidden'

import styles from "assets/jss/views/marketAuthStyle"

import Logo from "assets/img/footer-logo.png"
import MobileLogo from "assets/img/baloon-company-mobile.png"
import LoadingButton from 'components/CustomForms/LoadingButton'
import { Form, withFormik } from 'formik'
import * as Yup from 'yup';
import { login } from './_redux/authCrud'
import { Alert } from '@mui/material'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(styles)

const SignInSchema = Yup.object().shape({
	username: Yup.string()
		.max(191, 'Maximalt användarnamn är 191 längd tecken !')
		.required('Nödvändig!'),
	password: Yup.string()
		.max(191, 'Felaktigt lösenord')
		.required('Nödvändig!')
})

const SignInProps = {
	username: '',
	password: ''
}

const FormEnchancher = withFormik({
	mapPropsToValues: () => (SignInProps),
	validationSchema: SignInSchema,
	handleSubmit: (values) => {
	},
	isInitialValid: props => SignInSchema.isValidSync(SignInProps),

})

const useQuery = () => {
	const {search} = useLocation()

	return useMemo(() => new URLSearchParams(search), [search])
}

const Login = props => {

	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		isValid,
	} = props;

	const query = useQuery()

	const classes = useStyles()

	const history = useHistory()

	const [loading, setLoading] = useState(false)
	const [remember, setRemember] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const dispatch = useDispatch()

	const handleSubmit = async () => {
		if (isValid) {
			try {
				setLoading(true)

				const res = await login(values.username, values.password, remember)
				setTimeout(() => {
					
					dispatch({type : 'LOGIN_SUCCESS', payload : res.data})
					setLoading(false)

					history.replace(`/`)
				}, 3000)
				

			} catch (error) {
				setLoading(false)
				setErrorMessage(error)
			}
		}
	}
	
	return (
		<Box className={classes.wrapper}>
			<Container maxWidth="xl" className={classes.container}>
				<Grid container>
					<Grid item lg={6} md={6} sm={12} xs={12} className={classes.leftSide}>
						<Box className={classes.contents}>
							<Hidden xsDown>
								<img src={Logo} alt="Logo" />
							</Hidden>
							<Hidden smUp>
								<img src={MobileLogo} alt="Logo" />
							</Hidden>
							<Typography variant="h3">
								Logga in på lustgasser
							</Typography>
							<Form>
								{errorMessage && (
									<Box className={classes.errorField}>
										<Alert severity='error' variant="filled">
											{errorMessage}
										</Alert>
									</Box>
								)}
								<Box className={classes.textField}>
									<InputLabel htmlFor="email"	>
										Användarnamn eller e-mailadress
									</InputLabel>
									<OutlinedInput
										id="username"
										variant="outlined"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.username}
										error={touched.username && Boolean(errors.username)}
										name="username"
										fullWidth
										placeholder="Ange ditt användarnamn eller e-post"
									/>
									{!!touched.username && errors.username && (
										<FormHelperText error id="username">
											{errors.username}
										</FormHelperText>
									)}
								</Box>
								<Box className={classes.textField}>
									<InputLabel htmlFor="password"	>
										Lösenord
									</InputLabel>
									<OutlinedInput
										id="password"
										variant="outlined"
										fullWidth
										placeholder="Ange ditt lösenord"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										error={touched.password && Boolean(errors.password)}
										name="password"
										inputProps={{
											type: "password"
										}}
										endAdornment={<InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>}
									/>
									{!!touched.password && errors.password && (
										<FormHelperText error id="password">
											{errors.password}
										</FormHelperText>
									)}
								</Box>
								<Box className={classes.rememberSelect} display="flex" justifyContent="space-between" alignItems="center">
									<FormControlLabel
										control={<Checkbox checked={remember} className={classes.checkbox} onChange={e => setRemember(e.target.checked)} name="remember" />}
										label="Kom ihåg mig"
									/>
									<Link href="/" onClick={(e) => { e.preventDefault(); history.push('/auth/forgot-password') }}>Glömt ditt lösenord?</Link>
								</Box>
								<Box className={classes.submitBtn}>
									<LoadingButton type="submit" loading={loading} variant="contained" color="green" fullWidth onClick={handleSubmit}>
										Logga in
									</LoadingButton>
								</Box>
							</Form>
							<Typography variant="body1" className={classes.otherPageLink} align="center">
								Har inte konto ? <Link href="/" onClick={(e) => { e.preventDefault(); history.push('/auth/register') }}>Registrera här</Link>
							</Typography>
						</Box>
					</Grid>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Box className={classes.rightSide}></Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default FormEnchancher(Login)