import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/views/profileViewStyle.js"
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { updateMeService } from '../../../../services/UserService';
import LoadingButton from 'components/CustomForms/LoadingButton'

const useStyles = makeStyles(styles)

const EditContactData = (props) => {

  const classes = useStyles()

  const { open, closeModal } = props

  const dispatch = useDispatch()
  const auth = useSelector((state) => state.user)
  const { user } = auth

  const [loading, setLoading] = useState(false)


  const [data, setData] = useState({
    email: user.email,
    phone_number: user.phone_number
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({name, value})
    setData({
      ...data,
      [name]: value
    });
  }

  const updateUserSubmit = async (e) => {
    e.preventDefault();
    const newData = {
      email: data.email,
      phone_number: data.phone_number
    }
    setLoading(true)

    const res = await updateMeService({
      id: user.id,
      data : newData
    });
    dispatch({ type: 'UPDATE_USER', payload: res })
    setLoading(false);
    closeModal();
  }

  return (
    <CustomModal open={open} closeModal={closeModal} size="small">
      <ModalHeader title="Edit Redigera kontaktdata" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                labelText="E-postadress"
                inputProps={{
                  type: "email",
                  name: 'email',
                  fullWidth: true,
                  placeholder: "Input email",
                  value: data.email,
                  onChange: handleChange
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                labelText="Telefonnummer"
                inputProps={{
                  type: "text",
                  name: 'phone_number',
                  fullWidth: true,
                  placeholder: "Input phone number",
                  value: data.phone_number,
                  onChange: handleChange
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Box className={classes.modalFooter} textAlign="right">
        <Button className={classes.cancelBtn} onClick={closeModal}>
          Cancel
        </Button>
        <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={updateUserSubmit}>
          Uppdatering Data
        </LoadingButton>
      </Box>
    </CustomModal>
  )
}

export default EditContactData
