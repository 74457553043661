import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import { Box, Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'

import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import TextField from "components/CustomForms/TextField"
import CustomButton from "components/CustomForms/Button"
import ProductItem from "./components/ProductItem"
import ProductCustomPagination from "components/CustomPagination/ProductCustomPagination"

import styles from "assets/jss/views/admin/productViewStyle"
import { getPaginateData } from './_redux/productCrud'
import { useState } from 'react'
import ConfirmModal from 'components/CustomModal/ConfirmModal/ConfirmModal'
import { Typography } from '@mui/material'
import { destroyProduct } from './_redux/productCrud'

const useStyles = makeStyles(styles)

const Product = () => {

  const classes = useStyles()

  const history = useHistory()

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [totalPage, setTotalPage] = useState(1)
  const [, setLoading] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    roles: 'customer'
  })

  const [products, setProducts] = useState([])

  const onDeleteProduct = (product) => {
    setSelectedProduct(product)
    setIsModalDeleteOpen(true)
  }

  const getProduct = async () => {
    try {
      const res = await getPaginateData(query)
      setProducts(res.data?.data)
      setTotalData(res.data?.total_data ?? 0)
      setTotalPage(res?.data?.total_page ?? 1)
    } catch (err) {

    }
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props, page : 1})
  }

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{ page: page } })
  }

  useEffect(() => {

    const delauyDebounceFn = setTimeout(() => {
      getProduct()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AdminViewTitles mainTitle="Hantera produkt" subTitle="" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.productSection}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              disableLabel
              inputProps={{
                type: "text",
                fullWidth: true,
                placeholder: "Sök",
                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                onChange: (e) => { changeQuery({ keyword: e.target.value }) }
              }}
            />
          </Grid>
          <Hidden smDown>
            <Box flexGrow={1} />
          </Hidden>
          <Hidden smDown>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <CustomButton variant="contained" color="green" fullWidth startIcon={<AddIcon />} onClick={() => history.push('/admin/product/add')}>
                Lägg till
              </CustomButton>
            </Grid>
          </Hidden>
        </Grid>
        <Grid container spacing={3}>
          {
            products.map(product => (
              <Grid item lg={6} md={6} sm={12} xs={12} key={product.id}>
                <ProductItem
                  product={product}
                  onClickEdit={() => history.push('product')}
                  onClickDelete={() => onDeleteProduct(product)}
                />
              </Grid>
            ))
          }
          <Hidden smDown>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ProductCustomPagination 
                currentLimit={query.limit} 
                changeCurrentLimit={val => changeQuery({ limit: val })} 
                currentPage={query.page} 
                changePage={val => changePage({ page: val })} 
                pageCount={totalPage}
                totalData={totalData}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box mt={2}>
          <ProductCustomPagination 
                currentLimit={query.limit} 
                changeCurrentLimit={val => changeQuery({ limit: val })} 
                currentPage={query.page} 
                changePage={val => changeQuery({ page: val })} 
                pageCount={totalPage}
                totalData={totalData}
              />
          </Box>
          <Box mt={3}>
            <CustomButton
              variant="contained"
              color="green"
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => history.push('/admin/product/add')}
            >
              Lägg till
            </CustomButton>
          </Box>
        </Grid>
      </Hidden>
      {
        selectedProduct && (
          <ConfirmModal
            title="Radera produkt"
            open={isModalDeleteOpen}
            text={
              <Typography variant="body1" align="center">
                Är du säker på att du vill radera produkten “<span>{selectedProduct.name}</span>”?
              </Typography>
            }
            confirmAction={async () => {
              await destroyProduct(selectedProduct)
              setIsModalDeleteOpen(false)
              setSelectedProduct(null)
              await getProduct()
            }}

            closeModal={() => {
              setSelectedProduct(null)
              setIsModalDeleteOpen(false)
            }}

          />
        )
      }

    </Grid>
  )
}

export default Product
