import React, { useEffect } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import styles from "assets/jss/views/checkoutViewStyle"
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import LoadingButton from 'components/CustomForms/LoadingButton';
import { useParams, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { getTransactionById } from 'services/TransactionService';
import { orderPayService } from 'services/OrderService';
import OrderInformation from './OrderInformation';
import classNames from 'classnames';

import ReceivedOrderModal from "../Checkout/components/ReceivedOrderModal"


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51KJwBvHs0N5VTvxaiz9TBQovGl0LQqIy8zZa4EFV2XTW4giSaB7UYIXrDkbQR0YFyiqvBa5kTg7vh2IBVgrx5vZP00kQUvShyG');

const CheckoutForm = (props) => {
    const stripe = useStripe()
    const elements = useElements()
    const { transaction, updatePayment, setOpenReceivedModal } = props
    const [loading, setLoading] = useState(false)



    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        // console.log(event)
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const card = elements.getElement(CardElement);

        const result = await stripe.createToken(card);

        // console.log(result)


        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            // console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            // console.log(result)
            try {
                setLoading(true)
                const payRes = await orderPayService({
                    body: {
                        invoice_number: transaction.invoice_number,
                        stripe_token: result?.token?.id

                    }
                })
                console.log({ payRes })
                console.log('iidididi', payRes.status)
                if(payRes.status){
                    setOpenReceivedModal(true);
                    updatePayment();
                }else{
                    alert(payRes.message);
                }
                setLoading(false)
                // if(payRes.status)
                // updatePayment();
                // setOpenReceivedModal(true);
            } catch (err) {

            }
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                color: "#666",
                fontSize: "18px",
            },
            invalid: {
                color: "#fa755a",
                fontSize: "fa755a",
            }
        }
    }


    return (
        <form>
            <CardElement options={cardElementOptions} />
            <LoadingButton onClick={handleSubmit} loading={loading} variant="contained" color="green" disabled={!stripe}>Skicka in</LoadingButton>
        </form>
    )


}

const useStyles = makeStyles(styles)

const StripeCard = () => {
    const history = useHistory()

    const { id } = useParams()
    const [openReceivedModal, setOpenReceivedModal] = useState(false)
    const [transaction, setTransaction] = useState({
        invoice_number: '',
        status: '',
        transaction_items: [],
        payment: {
            name: ''
        }
    });

    const getTransactionDetail = async () => {
        try {
            const data = await getTransactionById({ id: id })

            if (data) {
                setTransaction(data)
            }

        } catch (err) {

        }

    }

    useEffect(() => {
        getTransactionDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const classes = useStyles()


    return (
        <Box className={classes.wrapper}>
            <Hidden smDown>
                <Breadcrumb breadcrumb={["Hem", "Order", "Payment"]} />
            </Hidden>
            <Box className={classes.contentSection}>
                <Container maxWidth="xl">

                    <Grid container spacing={3}>

                        <Grid item lg={6} md={6} sm={12} xs={12} >
                            <Grid container>
                                <Grid item xs={12} className={classNames(classes.contentBlock, classes.billingInfoSection)}>
                                    {
                                        transaction.status === 'pending' &&
                                        <Elements stripe={stripePromise}>
                                            <CheckoutForm transaction={transaction} updatePayment={() => getTransactionDetail()} setOpenReceivedModal={setOpenReceivedModal} />
                                        </Elements>
                                    }

                                    {
                                        transaction.status === '' &&
                                        <Typography variant='h3'>
                                            Loading ...
                                        </Typography>
                                    }

                                    {
                                        transaction.status !== 'pending' && transaction.status !== '' && (
                                            <Typography variant='h3'>
                                                {transaction.transaction_status.name}
                                            </Typography>
                                        )
                                    }

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <OrderInformation transaction={transaction} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {
                transaction && (
                    <ReceivedOrderModal
                        open={openReceivedModal}
                        data={transaction}
                        closeModal={() => {
                            setOpenReceivedModal(false)
                            // history.replace('/')
                        }}
                    />
                )
            }
        </Box>
    )

}

export default StripeCard