import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from "react-router-dom"

import InfoIcon from '@material-ui/icons/Info';

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import Breadcrumb from "components/Breadcrumb"
import CartItem from "components/CartItem"
import Button from "components/CustomForms/Button"

import styles from "assets/jss/views/cartViewStyle"

import discount from "assets/img/cart/discount.png"
import { useSelector } from 'react-redux';
import { getTotalPrice, getTotalQuantity } from './_redux/cartAction';
import numberFormat from 'utils/number-format';
import currency from 'utils/currency';

const useStyles = makeStyles(styles)

const Cart = () => {

  const classes = useStyles()

  const history = useHistory()

  const cart = useSelector((state) => state.cart)

  const goCheckout = () => {
    if (cart.items.length > 0) {
      history.push('/checkout')
    } else {
      alert("Vänligen beställ produkt");
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Hidden smDown>
        <Breadcrumb breadcrumb={["Hem", "Vagn"]} />
      </Hidden>
      <Box className={classes.contentSection}>
        <Box className={classes.titleSection}>
          <Typography variant="h3" align="center">
            VAGN
          </Typography>
          <Typography variant="body1" align="center">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet. */}
          </Typography>
        </Box>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Box className={classes.productsSection}>
                <Hidden smDown>
                  <Box display="flex" justifyContent="space-between" className={classes.fields}>
                    <Box className={classes.productField}>
                      <Typography variant="body1">
                        PRODUKT
                      </Typography>
                    </Box>
                    <Box className={classes.priceField}>
                      <Typography variant="body1">
                        PRIS
                      </Typography>
                    </Box>
                    <Box className={classes.quantityField}>
                      <Typography variant="body1">
                        KVANTITET
                      </Typography>
                    </Box>
                    <Box className={classes.totalField}>
                      <Typography variant="body1">
                        TOTAL
                      </Typography>
                    </Box>
                  </Box>
                </Hidden>
                {
                  cart.items.map((item, index) => (
                    <CartItem key={index} cartItem={item} />
                  ))
                }
              </Box>
            </Grid>

            <Hidden mdUp>
              <Grid item xs={12}>
                <Box className={classes.discountSection}>
                  <OutlinedInput
                    fullWidth
                    placeholder="Discount code"
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={discount} alt="Discount" />
                      </InputAdornment>
                    }
                  />
                  <Button variant="contained" color="green" fullWidth>
                    Use Discount Code
                  </Button>
                </Box>
              </Grid>

            </Hidden>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box className={classes.summarySection}>
                <Typography variant="body1" className={classes.summaryTitle}>
                  Ordersammanfattning
                </Typography>
                <Box display="flex" justifyContent="space-between" className={classes.totalSection}>
                  <Typography variant="body1">
                    Produkter totalt
                  </Typography>
                  <Typography variant="body1">
                    {getTotalQuantity(cart.items)} Föremål
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.subTotal}>
                  <Typography variant="body1">
                    Delsumma
                  </Typography>
                  <Typography variant="body1">
                    {numberFormat(getTotalPrice(cart.items))} {currency}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" className={classes.note}>
                  <InfoIcon />
                  <Typography variant="body1">
                    Med detta köp får du <span> {numberFormat(Math.floor(getTotalPrice(cart.items) / 100))} </span> lojalitetspoäng
                  </Typography>
                </Box>
                <Divider />
                {cart.items.length > 0 ? (
                  <Button variant="contained" color="green" fullWidth onClick={goCheckout}>
                    Gå till kassa
                  </Button>
                ) : (
                  <Button variant="contained" color="green" fullWidth disabled>
                    Gå till kassa
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Cart
