import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

import { Box, Hidden, Button, FormHelperText } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CustomTextField from "components/CustomForms/TextField"
import * as Yup from 'yup'

import styles from "assets/jss/views/admin/addCategoryViewStyle"
import { Form, withFormik } from 'formik'
import { useSelector } from 'react-redux'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { Switch } from 'formik-material-ui'
import { updateCategoryService } from 'services/CategoryService'
import { storeCategoryService } from 'services/CategoryService'
import { getCategoryDetailBySlugService } from 'services/CategoryService'


const useStyles = makeStyles(styles)

const BASE_URL = process.env.REACT_APP_BASE_URL

const CategorySchema = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string()
        .max(191)
        .required(),
    slug: Yup.string()
        .nullable(),
    image_path: Yup.string()
        .required(),
    is_published: Yup.bool()
        .notRequired()

})

const CategoryProps = {
    id: null,
    name: '',
    image_path: '',
    slug: '',
    is_popular: false
}

const FormEnchancer = withFormik({
    mapPropsToValues: (props) => ({
        id: props.data?.id || null,
        name: props.data?.name || '',
        slug: props.data?.slug || '',
        image_path: props.data?.image_path || '',
        is_popular: props.data?.is_popular || false
    }),
    validationSchema: CategorySchema,
    enableReinitialize: true,
    handleSubmit: (values) => {

    },
    isInitialValid: props => CategorySchema.isValidSync(CategoryProps),
})

const AddBlog = props => {

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        setFieldValue,
    } = props

    const classes = useStyles()

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()

    const { slug } = params
    const uploadFile = () => {
        document.getElementById("upload-banner-img").click()
    }

    const user = useSelector(item => item.user)

    const uploadImage = (e) => {

        const formData = new FormData()
        formData.append('file', e.target.files[0])
        formData.append('path', 'categories')
        fetch(BASE_URL + '/file/upload-image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token,
            },
            body: formData
        })
            .then(res => res.json())
            .then(res => {
                setFieldValue('image_path', res.data.image_name)
            })
            .catch((err) => setErrorMessage(err.message))
    }

    const handleSubmit = async () => {
        if (isValid) {
            try {
                setLoading(true)
                if (slug) {
                    await updateCategoryService(values)
                } else {
                    await storeCategoryService(values)
                }

                setTimeout(() => {
                    setLoading(false)

                    history.replace('/admin/category')
                }, 3000)


            } catch (error) {
                setLoading(false)
                setErrorMessage(error)
            }
        }
    }

    const getData = async () => {
        try {
            const data = await getCategoryDetailBySlugService({ slug: slug })
            setFieldValue('id', data.id)
            setFieldValue('image_path', data.image_path)
            setFieldValue('slug', data.slug)
            setFieldValue('name', data.name)
            setFieldValue('is_popular', data.is_popular)

        } catch (error) {

        }
    }

    useEffect(() => {
        if (slug) {
            getData(slug)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container >
            <Form>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button startIcon={<ArrowBackIcon />} className={classes.backBtn} onClick={() => history.push('/admin/category')}>
                        Tillbaka
                    </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.contentSection}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h3">
                                Lägg till kategori
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="body1" className={classes.bannerLabel}>
                                Kategoribild<span>*</span>
                            </Typography>
                            <Box display="flex">
                                {
                                    values.image_path && (
                                        <Box className={classes.imageBanner}>
                                            <img src={values.image_path} alt="Product" width="120" height="120" />
                                        </Box>
                                    )
                                }


                                <Box className={classes.uploadField} position="relative">
                                    <input type="file" id="upload-banner-img" onChange={uploadImage} />
                                    <Box position="absolute" textAlign="center" onClick={uploadFile}>
                                        <CloudUploadOutlinedIcon />
                                        <Typography variant="body1" align="center">
                                            Ladda upp bild
                                        </Typography>
                                    </Box>
                                    {touched.images && errors.images && (
                                        <FormHelperText error id="password">
                                            {errors.images}
                                        </FormHelperText>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <CustomTextField
                                labelText="Namn"
                                error={touched.title && errors.title}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Namn",
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.name,
                                    name: "name",
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <CustomTextField
                                labelText="Slug"
                                error={touched.Slug && errors.Slug}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Url Design eg : lorem-ipsum",
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.slug,
                                    name: "slug",
                                }}
                            />
                        </Grid>
                        <Hidden smDown>
                            <Grid item lg={12} md={12} sm={12}>
                                <Box textAlign="right" mt={3}>
                                    <Button className={classes.cancelBtn}>
                                        Annullera
                                    </Button>
                                    <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={handleSubmit}>
                                        Spara
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid item sm={6} xs={6}>
                        <Button className={classes.cancelBtn} fullWidth>
                            Annullera
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <LoadingButton type="submit" loading={loading} variant="contained" color="green" fullWidth onClick={handleSubmit}>
                            Spara
                        </LoadingButton>
                    </Grid>
                </Hidden>
            </Form>
        </Grid>
    )
}

export default FormEnchancer(AddBlog)
