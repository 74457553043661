import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import styles from "assets/jss/views/landingViewStyle"

import { getBlogService } from 'services/BlogService'
import { useHistory } from 'react-router-dom'
import BlogItem from 'components/BlogItem'

const useStyles = makeStyles(styles)

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 1,
		partialVisibilityGutter: 40,
		partialVisible: true,
		centerMode: true
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		partialVisibilityGutter: 30,
		items: 1,
		slidesToSlide: 1
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		partialVisibilityGutter: 50,
		items: 1,
		slidesToSlide: 1
	}
};

const BlogSection = () => {

	const classes = useStyles()
	const history = useHistory()

	const [blogs, setBlogs] = useState([])
	const [query,] = useState({
		keyword: '',
		page: 1,
		limit: 10,
		is_published: false
	})

	const getData = async () => {
		try {
			const data = await getBlogService(query)
			setBlogs(data?.data ?? [])
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box className={classes.blogSection}>
			<Container maxWidth="xl">
				<Grid container spacing={3}>
					<Grid item xs={12} className={classes.titleSection}>
						<Typography variant="h3" fontWeight={700} align="center">
							Blogg
						</Typography>
						<Typography variant="body1" align="center">
							{/* Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aliquet. */}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box className={classes.blogList}>
							<Grid container spacing={3}>
								{
									blogs.map(blog => (
										<Grid item lg={4} md={4} sm={6} xs={12} key={blog.id}>
											<BlogItem blog={blog} />
										</Grid>
									))
								}
							</Grid>
							{/* <Carousel
								responsive={responsive}
								autoPlay
								infinite
								autoPlaySpeed={300000}
								centerMode={true}
								draggable
							>
								{blogs.map((item) => (
									<Box onClick={() => history.push(`/blog/${item.slug}`)} className={classes.blogItem} textAlign="center" key={item.id}>
										<img src={item.banner_url} alt="Blog 1" style={{ height: 'calc(100% - 50px)' }} />
										<Box className={`${classes.blogContent} blogContent`}>
											<Typography variant="h6" className='text-white'>
												{item.title}
											</Typography>
											<Typography variant="body1">

											</Typography>
										</Box>
									</Box>
								))}
							</Carousel> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default BlogSection
