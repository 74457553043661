import React, { useEffect } from 'react'
import ChartistGraph from "react-chartist"

import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import StatisticsSection from "./components/StatisticsSection"
import OrdersSection from "./components/OrdersSection"

import styles from "assets/jss/views/admin/dashboardViewStyle"
import { useState } from 'react'
import { getChartDataDashboardService } from 'services/DashboardService'

const useStyles = makeStyles(styles)

const Dashboard = () => {

  const classes = useStyles()

  const InitPropsChart = {
    labels : [],
    series : []
  }

  const [chartData, setChartData] = useState(InitPropsChart)

  const getChartData = async() => {

    try{
      const res = await getChartDataDashboardService()
      setChartData(res)
    }catch(err){

    }

  }
  
  var lineChartOptions = {
    low: Math.min(chartData.series[0]),
    showArea: true,
    axisY: {    
      labelInterpolationFnc: function(value) { 
        return value.toFixed(3)
      }
    },
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 30
    },   
    high: Math.max(chartData.series[0]) * 1.5,
    fullWidth: true,
  };

  useEffect(() => {
    getChartData()
  }, [])

  return (  
    <Grid container spacing={3}>
      <StatisticsSection />
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Box className={classes.chartSection}>
          <Typography variant="h3">
            Nettoinkomstst
          </Typography>
          <Typography variant="body1">
            Nulla leo nec dictum tincidunt mus odio non.
          </Typography>
          <Box className={classes.chartist}>
            <ChartistGraph
              className={"ct-octave"}
              data={chartData}
              type={"Line"}
              options={lineChartOptions}            
            />
          </Box>
        </Box>
      </Grid>
      <OrdersSection />      
    </Grid>
  )
}

export default Dashboard
