import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { Box, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { getFaqService } from 'services/FaqService'

import styles from "assets/jss/views/termsOfPurchaseViewStyle.js"

const useStyles = makeStyles(styles)

const Faq = () => {

  const classes = useStyles() 

  const [menu, setMenu] = useState({})
  const [open, setOpen] = useState(false)

  const [data, setData] = useState([])

  const getData = async() => {
    try{
      const res = await getFaqService({
        page: 1,
        limit: 100,
        keyword : '',
        is_published : 1
      })
      setData(res?.data ?? [])

      if(res?.data?.length > 0) {
        setMenu(res?.data[0])
      }
    }catch(err) {
      console.log(err)
    }
  }

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  

  useEffect(() => {
    getData()
  }, [])

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.headerSection}>
        <Box className={classes.titleSection}>
          <Typography variant="h3" fontWeight={700} align="center">
            FAQ
          </Typography>
          <Typography variant="body1" align="center">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet. */}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contentSection}>
        <Container>
          <Grid container spacing={3}>
            <Hidden smDown>
              <Grid item lg={3} md={3}>
                <Box className={classes.menuSection}>
                  <Typography variant="h6">
                    FAQ
                  </Typography>
                  {
                    data.map((item) => (
                      <Typography 
                        variant="body1" 
                        key={item.id} 
                        className={classNames({[classes.menuActive]: item.id === menu.id})}
                        onClick={() => setMenu(item)}
                      >
                        {
                          item.question
                        }
                      </Typography>
                    ))
                  }                  
                </Box>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item sm={12}>
                <Box className={classes.mobileMenu}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                      <Button className={classes.menuBtn} onClick={handleClick} endIcon={<FiberManualRecordIcon />}>
                        {
                          menu.question
                        }
                      </Button>
                      {
                        open ? (
                          <Box className={classes.mobileMenuWrapper}>
                            <Box className={classes.mobileMenuList}>
                              <Box>
                                {
                                  data.map((item, index) => (
                                    <Button 
                                      className={classNames(classes.menuItem, {[classes.activeMobileMenu]: item.id === menu.id})} 
                                      fullWidth 
                                      key={index}
                                      onClick={() => setMenu(item)}
                                    >
                                      {
                                        item.question
                                      }
                                    </Button>
                                  ))
                                }
                              </Box>
                            </Box>
                          </Box>
                        ) : null
                      }                      
                    </Box>
                    
                  </ClickAwayListener>
                </Box>
              </Grid>
            </Hidden>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <Box className={classes.content}>
                  <Typography variant="h3">
                    {
                      !!menu && menu.question
                    }
                  </Typography>
                  <Typography variant="body1">
                    {
                      !!menu && menu.answer
                    }
                  </Typography>
              </Box>
            </Grid>            
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Faq
