import React, { useMemo, useState } from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import SearchIcon from '@material-ui/icons/Search'

import { Box, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

import ProductItem from "components/ProductItem"

import styles from "assets/jss/views/allProductViewStyle"
import { getCategoryService } from 'services/CategoryService'


import FilterImg from "assets/img/filter.png"
import request from 'utils/request'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(styles)
const BASE_URL = process.env.REACT_APP_BASE_URL

const useQuery = () => {
	const {search} = useLocation()

	return useMemo(() => new URLSearchParams(search), [search])
}

const ProductSection = () => {

	const classes = useStyles()

	const query = useQuery()

	const [products, setProducts] = useState([])
	const [categories, setCategories] = useState([])
	const [totalPage, setTotalPage] = useState(1)
	const [queryBody, setQueryBody] = useState({
		limit : 10,
		keyword : '',
		page : 1,
		category_id: query.get('category_id')
	})

	const getProduct = () => {

		request({
			url: BASE_URL + '/products',
			method: 'GET',
			queryParams: queryBody
		})
			.then(res => {
				setProducts(res.data?.data ?? [])
				setTotalPage(res.data?.total_page ?? 1)
			})
			.catch(error => {
				throw error.message
			})
	}

	const getCategoryData = async() => {
		const res = await getCategoryService({
            limit : 10,
            page: 1
        })

        setCategories(res?.data ?? [])
	}

	useEffect(() => 
	{
		getCategoryData()

		getProduct()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		request({
			url: BASE_URL + '/products',
			method: 'GET',
			queryParams: queryBody
		})
			.then(res => {
				setProducts(res.data?.data ?? [])
				setTotalPage(res.data?.total_page ?? 1)
			})
			.catch(error => {
				throw error.message
			})
	}, [queryBody])

	return (
		<Box className={classes.productSection}>
			<Container maxWidth="xl">
				<Grid container spacing={3}>
					<Grid item xs={12} className={classes.titleSection}>
						<Typography variant="h3" fontWeight={700} align="center">
							ALLA PRODUKTER
						</Typography>
						<Typography variant="body1" align="center">
							{/* Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aliquet. */}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box className={classes.searchFormWrapper}>
							<FormControl fullWidth className={classes.searchForm} variant="outlined">
								<OutlinedInput
									placeholder="Search product"
									onChange={(e) => setQueryBody({...queryBody, keyword : e.target.value})}
									endAdornment={<InputAdornment position="start">
										<Button className={classes.searchBtn} onClick={(e) => {getProduct()}}><SearchIcon /></Button></InputAdornment>}
								/>
							</FormControl>
						</Box>
						<Box className={classes.filterSection}>
							<Box className={classes.filterList}>
								<Button className={classes.filterBtn} startIcon={<img src={FilterImg} alt="Filter" />}>
									Filtrera
								</Button>
								<Button
									className={classNames(classes.filterBtn, { [classes.active]: queryBody.category_id === null })}
									onClick={() => setQueryBody({...queryBody, category_id: null})}
								>
									Alla kategorier
								</Button>
								{
									categories.map((option, index) => (
										<Button
											// eslint-disable-next-line eqeqeq
											className={classNames(classes.filterBtn, { [classes.active]: queryBody.category_id == option.id })}
											key={index}
											onClick={() => setQueryBody({...queryBody, category_id : option.id})}
										>
											{option.name}
										</Button>
									))
								}
							</Box>
						</Box>
					</Grid>
					{
						products.map((productItem, index) => (
							<Grid item lg={3} md={3} sm={6} xs={6} key={index}>
								<ProductItem product={productItem} />
							</Grid>
						))
					}
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" justifyContent="center" className={classes.pagination}>
						<Stack spacing={2}>
							<Pagination count={totalPage} page={queryBody.page} onChange={ async(e, value) => {
								await setQueryBody({...queryBody, page : value})
							}} variant="outlined" shape="rounded" />
						</Stack>
					</Box>
				</Grid>
			</Container>
		</Box>
	)
}

export default ProductSection
