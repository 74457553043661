/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box, Checkbox, Divider, FormControlLabel, FormHelperText, InputAdornment, OutlinedInput } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import InfoIcon from '@material-ui/icons/Info'

import Breadcrumb from "components/Breadcrumb"
import RadioCard from "components/RadioCard"
import discount from "assets/img/cart/discount.png"

import SelectAddressModal from "./components/SelectAddressModal"
import ReceivedOrderModal from "./components/ReceivedOrderModal"

import styles from "assets/jss/views/checkoutViewStyle"
import request from 'utils/request'
import currency from 'utils/currency'
import numberFormat from 'utils/number-format'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { getTotalPrice } from 'views/Market/Cart/_redux/cartAction'
import { Link, useHistory } from 'react-router-dom'
import TextField from "components/CustomForms/TextField"
import { Form, withFormik } from 'formik'
import * as Yup from 'yup'
import { validateVoucherService } from 'services/VoucherService'
import { Alert } from '@mui/material'
import SelectField from 'components/CustomForms/SelectField'
import { getMdLocationStateService } from 'services/MdLocationStateService'
import { getMdLocationCityService } from 'services/MdLocationCityService'
import { getMdLocationPostalCodeService } from 'services/MdLocationPostalCodeService'



const useStyles = makeStyles(styles)

const initProps = {
  recepient_full_name: '',
  recepient_email: '',
  recepient_street_address: '',
  recepient_street_number: '',
  recepient_phone: '',
  recepient_zip_code: '',
  recepient_notes: '',
  shipping_method_id: null,
  items: [],
  payment_id: null,
  voucher_code: null,
  recepient_city_id: '',
  recepient_state_id: '',
  recepient_postal_code_id: ''
}

const CheckoutSchema = Yup.object().shape({
  recepient_full_name: Yup.string()
    .max(191, 'Maximal längd är 191 tecken!')
    .required('Nödvändig!'),
  recepient_email: Yup.string()
    .email()
    .max(191, 'Felaktigt lösenord')
    .required('Nödvändig!'),
  recepient_state_id: Yup.string()
    .required('Ort krävs'),
  recepient_city_id: Yup.string()
    .required('Stad krävs'),
  recepient_postal_code_id: Yup.string()
    .required('Postnummer krävs'),
  recepient_street_number: Yup.string()
    .max(191, 'Maximal längd är 191 tecken!'),
  recepient_phone: Yup.string()
    .max(191, 'Maximal längd är 191 tecken!')
    .required('Nödvändig!'),
  recepient_notes: Yup.string()
    .max(191, 'Maximal längd är 191 tecken!'),
  payment_id: Yup.number()
    .typeError('Vänligen välj din betalning')
    .required('Vänligen välj din betalning'),
  items: Yup.array()
    .min(1, 'Artikeln i kundvagnen krävs')
    .required('Artikeln i kundvagnen krävs'),
  voucher_code: Yup.string()
    .nullable()
})



const FormEnchancher = withFormik({
  mapPropsToValues: () => (initProps),
  validationSchema: CheckoutSchema,
  handleSubmit: (values) => {
  },
  isInitialValid: props => CheckoutSchema.isValidSync(initProps),

})

const Checkout = props => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()


  const [openAddressModal, setOpenAddressModal] = useState(false)
  const [openReceivedModal, setOpenReceivedModal] = useState(false)
  const [shippingMethod, setShippingMethod] = useState([])
  const [isUserAgree, setIsUserAgree] = useState(false)
  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [discountAmount, setDiscountAmount] = useState(null)
  const [voucherCode, setVoucherCode] = useState('')
  const [validateVoucherLoading, setValidateVoucherLoading] = useState(false)
  const [stateData, setStateData] = useState([])
  const [cityData, setCityData] = useState([])
  const [postalCodeData, setPostalCodeData] = useState([])
  const [agreeError, setAgreeError] = useState(false)



  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue,
  } = props;

  const cart = useSelector(item => item.cart)
  const { isLoggedIn } = useSelector(item => item.user)

  const { items } = cart

  const BASE_URL = process.env.REACT_APP_BASE_URL

  const getPayments = () => {
    return request({
      url: BASE_URL + '/payments'
    })
      .then(res => res.data)
      .then(res => setPayments(res))
      .catch(err => err.message)
  }

  const getState = async () => {
    try {
      const data = await getMdLocationStateService({
        keyword: '',
        page: 1,
        limit: 1000
      })
      if (data?.data) {
        const res = data?.data ?? []
        const result = res.map(item => ({ value: item.id, label: item.name }))
        setStateData(result)
      }

    } catch (err) {

    }
  }

  const getCity = async (state_id) => {
    try {
      const data = await getMdLocationCityService({
        keyword: '',
        page: 1,
        limit: 1000,
        md_location_state_id: state_id
      })
      if (data?.data) {
        const res = data?.data ?? []
        const result = res.map(item => ({ value: item.id, label: item.name }))
        setCityData(result)
      }

    } catch (err) {

    }
  }

  const getPostalCode = async (city_id) => {
    try {
      const data = await getMdLocationPostalCodeService({
        keyword: '',
        page: 1,
        limit: 1000,
        md_location_city_id: city_id
      })
      if (data?.data) {
        const res = data?.data ?? []
        setPostalCodeData(res)
      }

    } catch (err) {

    }
  }

  useEffect(() => {
    // getShippingMethod()
    getPayments()
    getState()
    setFieldValue('items', items)
  }, [])

  const getShippingCost = () => {
    const getIndex = postalCodeData.findIndex((item) => item.id === values.recepient_postal_code_id)

    if (getIndex > -1) {

      return postalCodeData[getIndex]
    } else {
      return null
    }
  }

  const storeOrder = () => {
    return request({
      url: BASE_URL + '/order/store',
      method: 'POST',
      body: values
    })
      .then((res) => {
        setOrderData(res.data);
        return res.data;
      })
      .catch(err => {
        throw err.message
      })
  }

  const validateVoucher = async () => {
    try {
      if (voucherCode) {
        setErrorMessage('')
        setValidateVoucherLoading(true)
        const voucher = await validateVoucherService({ voucher_code: voucherCode, amount: getTotalPrice(items) })
        setFieldValue('voucher_code', voucherCode)
        setDiscountAmount(voucher.discount)
        setValidateVoucherLoading(false)
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Voucher-koden behövs';
      }

    } catch (err) {
      setErrorMessage(err)
      setValidateVoucherLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (isValid) {
      try {
        if (!isUserAgree) {
          setAgreeError(true);
          return;
        }
        setLoading(true)

        const orderRes = await storeOrder()
        setTimeout(() => {

          setLoading(false)

          dispatch({ type: 'CLEAR_CART', payload: {} })

          if (orderRes.payment.slug === 'stripe-cc') {
            history.replace(`/card-payment/${orderRes.id}`)
          } else {
            history.replace(`/swish-payment/${orderRes.id}`)
          }
          // setOpenReceivedModal(true)
        }, 3000)


      } catch (error) {
        setLoading(false)
        setErrorMessage(error)
      }
    }
  }

  return (
    <Form>

      <Box className={classes.wrapper}>
        <Hidden smDown>
          <Breadcrumb breadcrumb={["Hem", "Vagn", "Kolla upp"]} />
        </Hidden>
        <Box className={classes.contentSection}>
          <Container maxWidth="xl">
            {errorMessage && (
              <Box className={classes.errorField}>
                <Alert severity='error' variant="filled">
                  {errorMessage}
                </Alert>
              </Box>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Frakt Metod
                </Typography>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                {/* Start of Billing Information Section */}
                <Box className={classes.billingSection}>
                  <Typography variant="h3">
                    Faktureringsinformation
                  </Typography>
                  <Box className={classes.billingForm}>
                    <Container>
                      <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextField
                            labelText="Fullständiga namn"
                            error={touched.recepient_full_name && errors.recepient_full_name}
                            inputProps={{
                              type: "text",
                              fullWidth: true,
                              placeholder: "Ange Din Fullständiga namn",
                              onChange: handleChange,
                              onBlur: handleBlur,
                              name: "recepient_full_name"
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            labelText="E-postadress"
                            error={touched.recepient_email && errors.recepient_email}
                            inputProps={{
                              type: "email",
                              fullWidth: true,
                              placeholder: "Inmatningsadress",
                              onChange: handleChange,
                              onBlur: handleBlur,
                              name: "recepient_email"
                            }}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>

                          <SelectField
                            labelText="Välj Ort"
                            error={touched.recepient_state_id && errors.recepient_state_id}

                            inputProps={{
                              value: values.recepient_state_id,
                              fullWidth: true,
                              onChange: async (e) => {
                                await setFieldValue('recepient_state_id', e.target.value)
                                await setFieldValue('recepient_city_id', '')
                                await setFieldValue('recepient_postal_code_id', '')
                                await setCityData([])
                                await setPostalCodeData([])
                                await getCity(e.target.value)
                              },
                              name: 'recepient_state_id',
                            }}
                            data={stateData}
                          />

                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>

                          <SelectField
                            labelText="Välj Stad"
                            error={touched.recepient_city_id && errors.recepient_city_id}

                            inputProps={{
                              value: values.recepient_city_id,
                              fullWidth: true,
                              onChange: (e) => {
                                setFieldValue('recepient_city_id', e.target.value)
                                setFieldValue('recepient_postal_code_id', '')
                                setPostalCodeData([])
                                getPostalCode(e.target.value)
                              },
                              name: 'recepient_city_id',
                            }}
                            data={cityData}
                          />

                        </Grid>



                        <Grid item lg={6} md={6} sm={12} xs={12}>

                          <SelectField
                            labelText="Välj Postnummer"
                            error={touched.recepient_postal_code_id && errors.recepient_postal_code_id}

                            inputProps={{
                              value: values.recepient_postal_code_id,
                              fullWidth: true,
                              placeholder: 'Select Postal Code',
                              onChange: (e) => setFieldValue('recepient_postal_code_id', e.target.value),
                              name: 'recepient_postal_code_id',
                            }}
                            data={postalCodeData.map(item => ({ value: item.id, label: `${item.postal_code}` }))}
                          />

                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            error={touched.recepient_street_number && errors.recepient_street_number}
                            labelText="Gatuadress detalj"
                            inputProps={{
                              type: "text",
                              fullWidth: true,
                              placeholder: "Ingångslägenhet nummer, golv, etc (valfritt)",
                              onChange: handleChange,
                              onBlur: handleBlur,
                              name: "recepient_street_number"
                            }}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            labelText="Telefonnummer"
                            error={touched.recepient_phone && errors.recepient_phone}
                            inputProps={{
                              fullWidth: true,
                              placeholder: "Ingångstelefon",
                              onChange: handleChange,
                              onBlur: handleBlur,
                              name: "recepient_phone"
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            labelText="Anteckningar (valfritt)"
                            error={touched.recepient_notes && errors.recepient_notes}
                            inputProps={{
                              fullWidth: true,
                              placeholder: "Ingångsnoteringar",
                              onChange: handleChange,
                              onBlur: handleBlur,
                              name: "recepient_notes"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Box>
                {/* End of Billing Information Section */}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                {/* {Start Of Order Lists Section} */}
                <Box className={classes.orderList}>
                  <Typography variant="h6">
                    Orderlista
                  </Typography>
                  {
                    items.map((item, index) => (
                      <Box className={classes.orderItem} key={index}>
                        <Typography variant="body1">
                          {item.product_name}
                        </Typography>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="body1">
                            {`${item.quantity} items`}
                          </Typography>
                          <Typography variant="body1">
                            {`${numberFormat(item.selling_price)} ${currency}`}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  }
                  <Divider />
                  <Typography variant="h6">
                    Rabattkod
                  </Typography>
                  <Box className={classes.discount} display="flex" justifyContent="space-between">
                    <OutlinedInput
                      placeholder="Rabattkod"
                      value={voucherCode}
                      onChange={e => setVoucherCode(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={discount} alt="Discount" />
                        </InputAdornment>
                      }
                    />
                    <LoadingButton loading={validateVoucherLoading} onClick={() => {
                      validateVoucher()
                    }} variant="contained" color="green">
                      Använd kod
                    </LoadingButton>
                  </Box>
                  <Typography variant="h6">
                    Betalningsmetod
                  </Typography>

                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <RadioGroup name="payment-method" value={values.payment_id} onChange={(e) => setFieldValue('payment_id', parseInt(e.target.value))}>
                      <Container className={classes.paymentMethodContainer}>
                        <Grid container spacing={2}>
                          {payments.map(item => (

                            <Grid key={item.id} item lg={12} md={12} sm={6} xs={6} className={classNames(classes.paymentMethod, { [classes.activePayment]: values.payment_id === parseInt(item.id) })}>
                              <FormControlLabel value={item.id} control={<Radio />} label={
                                <Box display="flex" alignItems="center">
                                  <div style={{ width: '75px' }}>
                                    <img src={item.thumbnail} alt={item.name} style={{ height: 50, marginLeft: 10 }} />
                                  </div>
                                  <Hidden smDown>
                                    <Typography variant="body1">
                                      {item.name}
                                    </Typography>
                                    <InfoIcon />
                                  </Hidden>
                                </Box>
                              } />
                            </Grid>
                          ))}
                        </Grid>
                      </Container>
                    </RadioGroup>
                    {!!touched.payment_id && errors.payment_id && (
                      <FormHelperText error id="payment_id">
                        {errors.payment_id}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Divider />

                  <Typography variant="h6">
                    Ordersammanfattning
                  </Typography>
                  {
                    values.voucher_code && discountAmount && (
                      <Box display="flex" justifyContent="space-between" className={classes.sumaryLine}>
                        <Typography variant="body1">
                          Discount
                        </Typography>
                        <Typography variant="body1">
                          {numberFormat(discountAmount)} {currency}
                        </Typography>
                      </Box>
                    )
                  }
                  <Box display="flex" justifyContent="space-between" className={classes.sumaryLine}>
                    <Typography variant="body1">
                      Delsumma
                    </Typography>
                    <Typography variant="body1">
                      {numberFormat(getTotalPrice(items))} {currency}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" className={classes.sumaryLine}>
                    <Typography variant="body1">
                      Fraktkostnad
                    </Typography>
                    <Typography variant="body1">
                      {numberFormat(getShippingCost()?.price ?? 0)}kr
                    </Typography>
                  </Box>

                  <Divider className={classes.setMargin} />
                  <Box display="flex" justifyContent="space-between" className={classes.totalPayment}>
                    <Typography variant="body1">
                      Total betalning
                    </Typography>
                    <Typography variant="body1">
                      {numberFormat(getTotalPrice(items) + parseFloat(getShippingCost()?.price ?? 0) - parseFloat(discountAmount ?? '0.00'))} {currency}
                    </Typography>
                  </Box>

                  <Divider className={classes.setMargin} />

                  <FormControlLabel
                    className={classes.checkBox}
                    control={<Checkbox checked={isUserAgree} onChange={(e) => setIsUserAgree(e.target.checked)} name="checkedA" />}
                    label={<Typography>Jag har läst och accepterar härmed villkoren i <Link to="/terms-of-purchase">köpvillkor</Link> </Typography>}
                  />
                  {agreeError && (<FormHelperText error id="checkedA">
                    Du måste hålla med om det.
                  </FormHelperText>)}
                  {/* <Typography variant="body1" className={classes.footerText} align="center">
                    Order & Create Account
                  </Typography> */}

                  <LoadingButton loading={loading} type="submit" variant="contained" color="green" fullWidth onClick={handleSubmit}>
                    Beställa
                  </LoadingButton>
                </Box>
                {/* End Of Order Lists Section */}
              </Grid>
            </Grid>
          </Container>
        </Box>

        <SelectAddressModal
          open={openAddressModal}
          closeModal={() => setOpenAddressModal(false)}
        />
        {/* {
          orderData && (
            <ReceivedOrderModal
              open={openReceivedModal}
              data={orderData}
              closeModal={() => {
                setOpenReceivedModal(false)
                history.replace('/')
              }}
            />
          )
        } */}
      </Box>
    </Form>
  )
}

export default FormEnchancher(Checkout)
