import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getUserService = props => {
    
    const {page, limit, keyword, roles} = props
    
    return request({
        url: BASE_URL + '/user',
        queryParams : {
            page : page ?? 1,
            limit : limit ?? 10,
            keyword: keyword ?? '',
            roles : roles ?? ''
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const storeUserService  = props => {
    return request({
        url: BASE_URL + '/user',
        body: props,
        method : 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const updateUserService = props => {
    const {id, data} = props
    
    return request({
        url: BASE_URL + `/user/${id}/update`,
        body: data,
        method: 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const updateMeService = props => {
    const {data} = props
    
    return request({
        url: BASE_URL + `/user/update/me`,
        body: data,
        method: 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const destroyUserService = props => {
    const {id} = props

    return request({
        url: BASE_URL + `/user/${id}/destroy`,
        method: 'POST',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getUserMeService = () => {
    return request({
        url: BASE_URL + `/user/me`
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}