import request from  '../../../../utils/request'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getPaginateData = props => {

    const {
        limit,
        page,
        keyword
    } = props

    return request({
        url : BASE_URL + '/products',
        method : 'GET',
        queryParams : {
            limit : limit ?? 10,
            page : page ?? 1,
            keyword : keyword ?? '',
            category_id : null
        }
    })
    .then(res => {
        return res
    })
    .catch(error => {
        throw error.message
    })

}

export const getDetailProduct = props => {
    const {
        id
    } = props

    return request({
        url: BASE_URL + '/products/' + id + '/detail',
        method : 'GET'
    })
    .then(res => {
        return res.data
    })
    .catch(error => {
        throw error.message
    })
}

export const storeProduct = props => {
    return request({
        url : BASE_URL + '/products/store',
        method: 'POST',
        body: props
    })
    .then(res => {
        return res
    })
    .catch(error => {
        throw error.message
    })
}

export const updateProduct = props => {
    return request({
        url : `${BASE_URL}/products/${props.id}/update`,
        method: 'POST',
        body: props
    })
    .then(res => {
        return res
    })
    .catch(error => {
        throw error.message
    })
}

export const destroyProduct = props => {
    return request({
        url : `${BASE_URL}/products/${props.id}/destroy`,
        method: 'POST',
    })
    .then(res => {
        return res
    })
    .catch(error => {
        throw error.message
    })
}