import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Button from "components/CustomForms/Button"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/components/transactionDetailsStyle"
import moment from 'moment'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const TransactionDetail = (props) => {

  const classes = useStyles()

  const { open, closeModal, data } = props

  return (
    <CustomModal open={open} closeModal={closeModal} >
      <ModalHeader title="Transaktions Detaljer" closeModal={closeModal} />
      <ModalContents>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.contentBlock, classes.invoiceSection)}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Box className={classes.invoiceLine}>
                    <Typography variant="body1">
                      Fakturanummer
                    </Typography>
                    <Typography variant="body1">
                      {data.invoice_number}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Box className={classes.invoiceLine}>
                    <Typography variant="body1">
                      Datum
                    </Typography>
                    <Typography variant="body1">
                      {moment(data.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Box className={classes.invoiceLine}>
                    <Typography variant="body1">
                      Total betalning
                    </Typography>
                    <Typography variant="body1">
                      {numberFormat(data.grand_total)} {currency}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <Box className={classes.invoiceLine}>
                    <Typography variant="body1">
                      Status
                    </Typography>
                    <Typography variant="body1">
                      {data.transaction_status?.name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.orderListSection)}>

              <Typography variant="body1" className={classes.blockTitle}>
                Orderlista
              </Typography>
              {data.transaction_items?.map((item) => (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderLine}>
                    <Box>
                      <Typography variant="body1">
                        {item.product_variant.name}
                      </Typography>
                      <Typography variant="body1">
                        {numberFormat(item.quantity)} x {numberFormat(item.selling_price_per_item)} {currency}
                      </Typography>
                    </Box>
                    <Typography variant="body1">
                      {numberFormat(item.total)} {currency}
                    </Typography>
                  </Box>
                  <Divider className={classes.divider} />
                </>
              ))}
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.billingInfoSection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Faktureringsinformation
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingLeft}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Frakt Metod
                    </Typography>
                    <Typography variant="body1">
                      {data.shipping_method?.name ?? '-'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Spårningsnummer
                    </Typography>
                    <Typography variant="body1">
                      {data.order_tracking_number ?? '-'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Anteckningar
                    </Typography>
                    <Typography variant="body1">
                      {data.recepient_notes ?? '-'}
                    </Typography>
                  </Box>
                </Grid>

                <Hidden mdUp>
                  <Grid item xs={12} className={classes.setPadding}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Hidden>

                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingRight}>
                  <Typography variant="body1">
                    {data.recepient_name ?? '-'}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_email ?? '-'}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_phone ?? '-'}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_street_address ?? '-'}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepuent_street_number ?? '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.summarySection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Ordersammanfattning
              </Typography>
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Betalningsmetod
                </Typography>
                <Typography variant="body1">
                  {data.payment?.name ?? '-'}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Delsumma
                </Typography>
                <Typography variant="body1">
                  {data.transaction_items?.length > 0 && numberFormat(data.transaction_items.reduce((prev, next) => prev + parseFloat(next.total), 0))} {currency}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Fraktkostnad
                </Typography>
                <Typography variant="body1">
                  {numberFormat(data.shipping_price)} {currency}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" justifyContent="space-between" className={classes.totalPayment}>
                <Typography variant="body1">
                  Total betalning
                </Typography>
                <Typography variant="body1">
                  {numberFormat(data.grand_total)} {currency}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Hidden mdUp>
        <Box className={classes.footer} mt={2}>
          <Button variant="contained" color="green" fullWidth onClick={closeModal}>Close</Button>
        </Box>
      </Hidden>
    </CustomModal>
  )
}

export default TransactionDetail
