import React from 'react'
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

import Button from "@material-ui/core/Button"
// import LoadingButton from "@material-ui/lab/"

import styles from "assets/jss/components/buttonStyles.js"
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(styles)

const LoadingButton = (props) => {

    const classes = useStyles()

    const {
        color,
        round,
        children,
        fullWidth,
        className,
        muiClasses,
        variant,
        loading,
        onClick,
        ...rest
    } = props

    const btnClasses = classNames({
        [classes.loadingButton]: true,
        [classes.greenContained]: (variant === "contained") && (color === "green"),
        [classes.greenOutlined]: (variant === "outlined") && (color === "green"),
        [classes.greenText]: (variant === "text") && (color === "green"),
        [classes[color]]: color,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.simple]: variant === "simple",
        [classes.disabledButton] : loading,
        [className]: className,
    });

    return (
        <Button {...rest} startIcon={loading && <CircularProgress size={18}/>} variant='contained' className={btnClasses} classes={muiClasses} disabled={loading} onClick={onClick}>
            {children}
        </Button>
    )
}

LoadingButton.propTypes = {
    color: PropTypes.oneOf([
        "green",
        "white",
        "rose",
        "black"
    ]),
    simple: PropTypes.bool,
    loading: PropTypes.bool,
    round: PropTypes.bool,
    outlined: PropTypes.bool,
    contained: PropTypes.bool,
    fullWidth: PropTypes.bool,
    variant: PropTypes.oneOf([
        "simple",
        "outlined",
        "contained"
    ]),
    className: PropTypes.string,
    muiClasses: PropTypes.object,
    children: PropTypes.node
};

export default LoadingButton
