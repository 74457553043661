import React, { useEffect } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import styles from "assets/jss/views/checkoutViewStyle"
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import LoadingButton from 'components/CustomForms/LoadingButton';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { getTransactionById } from 'services/TransactionService';
import { orderPayService } from 'services/OrderService';
import OrderInformation from './OrderInformation';
import classNames from 'classnames';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.



const useStyles = makeStyles(styles)

const SwishQr = () => {

    const { id } = useParams()
    const [transaction, setTransaction] = useState({
        invoice_number: '',
        status: '',
        transaction_items: [],
        payment: {
            name: ''
        }
    });

    const [qrCode, setQrCode] = useState()

    const getTransactionDetail = async () => {
        try {
            const data = await getTransactionById({ id: id })

            if (data) {
                setTransaction(data)
                loadQr(data)
            }

        } catch (err) {

        }

    }

    const loadQr = async (data) => {
        fetch(process.env.REACT_APP_BASE_URL + '/payments/swish/create-qr', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                "token": data.swish_token
            })
        })
        .then(res => res.json())
        .then(data => { // note this is already an ArrayBuffer
            setQrCode(data.data)
        })
        .catch((err) => {
            console.log(err)
        });

    }

    useEffect(() => {
        getTransactionDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const classes = useStyles()


    return (
        <Box className={classes.wrapper}>
            <Hidden smDown>
                <Breadcrumb breadcrumb={["Hem", "Order", "Payment"]} />
            </Hidden>
            <Box className={classes.contentSection}>
                <Container maxWidth="xl">

                    <Grid container spacing={3}>

                        <Grid item lg={6} md={6} sm={12} xs={12} >
                            <Grid container>
                                <Grid item xs={12} className={classNames(classes.contentBlock, classes.billingInfoSection)} style={{textAlign: 'center'}}>
                                    {
                                        transaction.status === 'pending' && qrCode &&
                                        <img src={`data:image/png;base64,${qrCode}`} alt='qr' style={{textAlign: 'center'}} />
                                    }

                                    {
                                        transaction.status === '' &&
                                        <Typography variant='h3'>
                                            Loading ...
                                        </Typography>
                                    }

                                    {
                                        transaction.status === 'paid' &&
                                        <Typography variant='h3'>
                                            Paid
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <OrderInformation transaction={transaction} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )

}

export default SwishQr