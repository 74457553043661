import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, Hidden } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'

import Breadcrumb from "components/Breadcrumb"
import BlogItem from "components/BlogItem"

import styles from "assets/jss/views/blogDetailViewStyle"

import Facebook from "assets/img/social/facebook.png"
import Instagram from "assets/img/social/instagram.png"
import LinkIcon from "assets/img/social/link.png"
import Twitter from "assets/img/social/twitter.png"
import { getBlogService, getBlogDetailService } from 'services/BlogService'
import moment from 'moment'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(styles)

const BlogDetail = () => {

  const classes = useStyles()
  const params = useParams()
  const {slug} = params
  const [blogs, setBlogs] = useState([])
  const [blogItem, setBlogItem] = useState({
    title : '',
    slug: '',
    content: '',
    is_published_at : null
  })
  const [query,] = useState({
    keyword: '',
    page: 1,
    limit: 10,
    is_published: false
  })

  const getData = async () => {
    try {
      const data = await getBlogService(query)
      setBlogs(data?.data ?? [])
    } catch (err) {
      console.log(err)
    }
  }

  const getDetail = async() => {
    try{
      const data = await getBlogDetailService({slug: slug})
      setBlogItem(data)
    }catch(err) {

    }
  }

  useEffect(() => {
    getData()
    getDetail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return (
    <Box className={classes.wrapper}>
      <Hidden smDown>
        <Breadcrumb breadcrumb={["Hem", "Blogg", blogItem.title]}/>
      </Hidden>
      <Box className={classes.contentSection}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.titleSection}>
              <Typography variant="h3" fontWeight={700}>
                {blogItem.title}
              </Typography>
              <Typography variant="body1">
                By Admin | {moment(blogItem.is_published_at).format('YYYY/MM/DD')}
              </Typography>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12} className={classes.detailImgSection}>
              <img src={blogItem.banner_url} alt="Blogg" width="100%" />
            </Grid>
            <Grid item lg={1} md={1} sm={12} xs={12} className={classes.socialWrapper}>
              <Fab className={classes.socialBtn}>
                <img src={Instagram} alt="Instagram" />
              </Fab>
              <Fab className={classes.socialBtn}>
                <img src={Facebook} alt="Facebook" />
              </Fab>
              <Fab className={classes.socialBtn}>
                <img src={Twitter} alt="Twitter" />
              </Fab>
              <Fab className={classes.socialBtn}>
                <img src={LinkIcon} alt="Link" />
              </Fab>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12} className={classes.detailText}>
              <Typography variant="body1">
                {blogItem.content}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.anotherBlogSection}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.anotherTitle}>
                  <Typography variant="h3" fontWeight={700} align="center">
                    Another Blog From Us
                  </Typography>
                </Grid>
                {
                  blogs.map(blog => (
                    <Grid item lg={4} md={4} sm={6} xs={12} key={blog.id}>
                      <BlogItem blog={blog} />
                    </Grid>
                  ))
                }
              </Grid>
              </Grid>
            </Grid>            
        </Container>
      </Box>
    </Box>
  )
}

export default BlogDetail
