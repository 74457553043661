import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"

import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/views/profileViewStyle.js"

const useStyles = makeStyles(styles)

const AddNewAddress = (props) => {

  const classes = useStyles()

  const { open, closeModal } = props

  return (
    <CustomModal open={open} closeModal={closeModal} >
      <ModalHeader title="Lägg till ny adress" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Fullständiga namn"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input first name",
                  defaultValue: "Noah J"
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Telefonnummer"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input phone number"                  
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Street Address"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input street name and number"                  
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField               
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input apartment number, floor, etc(Optional)"                  
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Postnummer"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Inmatningsnummer"                  
                }} 
              />
            </Grid>
          </Grid>
        </Container>        
      </ModalContents>
      <Box className={classes.modalFooter} textAlign="right">
        <Button className={classes.cancelBtn} onClick={ closeModal }>
          Annullera
        </Button>
        <Button variant="contained" color="green" onClick={ closeModal }>
          Spara
        </Button>
      </Box>
    </CustomModal>
  )
}

export default AddNewAddress
