import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import SearchIcon from '@material-ui/icons/Search'

import { Box, Hidden, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import TextField from "components/CustomForms/TextField"

import styles from "assets/jss/views/admin/orderViewStyle"
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'
import ProductCustomPagination from 'components/CustomPagination/ProductCustomPagination'
import ConfirmModal from 'components/CustomModal/ConfirmModal/ConfirmModal'
import CustomButton from 'components/CustomForms/Button'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { Delete, Edit } from '@material-ui/icons'
import PublicIcon from '@material-ui/icons/Public';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FaqItem from './components/FaqItem'
import { getFaqService, destroyFaqService, updatePublishStatusFaqService } from 'services/FaqService'
import FaqDetail from './components/FaqDetail'

const useStyles = makeStyles(styles)


const Faq = () => {

    const classes = useStyles()
    const history = useHistory()
    const [openConfirmToDeleteModal, setOpenConfirmToDeleteModal] = useState(false)
    const [openConfirmToPublisheModal, setOpenConfirmToPublisheModal] = useState(false)
    const [openViewModal, setOpenViewModal] = useState(false)
    const [selectedData, setSelectedData] = useState(null);
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const [loadingButton, setLoadingButton] = useState(false)


    const [data, setData] = useState([])

    const [query, setQuery] = useState({
        keyword: '',
        page: 1,
        limit: 10
    })
    const getData = async () => {
        try {
            const res = await getFaqService(query)
            setData(res?.data ?? [])
            setTotalPage(res?.total_page ?? 0)
            setTotalData(res?.total_data ?? 0)
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const changePage = (page) => {
        setLoading(true)
        setQuery({ ...setQuery, ...{ page: page } })
    }

    const changeQuery = (props) => {
        setQuery({ ...query, ...props })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const delauyDebounceFn = setTimeout(() => {
            getData()

        }, 2000)

        return () => clearTimeout(delauyDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        if (!openConfirmToDeleteModal) {
            setSelectedData(null)
        }
    }, [openConfirmToDeleteModal])


    // Start Of Datatable

    const columns = [
        {
            selector: row => row.question,
            name: "Fråga",
            wrap: 'true',
            sortable: true,
            grow: 2
        },
        {
            selector: row => row.is_published,
            name: "Publicerad",
            sortable: true,
            grow: 2,
            cell: (row) => (
                <Typography>
                    {row.is_published ? 'Ja' : 'Nej'}
                </Typography>
            )
        },
        {
            selector: row => row.id,
            name: "Handling",
            grow: 4,
            cell: (row) => (
                <Box>
                    <Button variant="contained" onClick={() => {
                        history.push('/admin/faq/edit/' + row.id)
                    }}
                        className={classNames(classes.actionButton, classes.deliveryBtn)}
                        startIcon={<Edit />}
                    >
                        Redigera
                    </Button>
                    <Button variant="contained" onClick={ () => {
                        setSelectedData(row)
                        setOpenViewModal(true)
                    } }
                        className={classNames(classes.actionButton, classes.deliveryBtn)}
                        startIcon={<VisibilityIcon />}
                    >
                        Se
                    </Button>
                    <Button variant="contained" onClick={() => {
                        setOpenConfirmToPublisheModal(true)
                        setSelectedData(row)
                    }}
                        className={classNames(classes.actionButton, classes.deliveryBtn)}
                        startIcon={<PublicIcon />}
                    >
                        {row.is_published ? 'Opublicera' : 'Publicera'}
                    </Button>
                    <Button variant="contained" onClick={() => {
                        setOpenConfirmToDeleteModal(true)
                        setSelectedData(row)
                    }}
                        className={classNames(classes.actionButton, classes.deliveryBtn)}
                        startIcon={<Delete />}
                    >
                        Radera
                    </Button>


                </Box>
            )
        }
    ]

    return (
        <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <AdminViewTitles mainTitle="Hantera FAQ" subTitle="" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className={classes.tableWrapper}>
                    <Box className={classes.filterWrapper}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                                <TextField
                                    disableLabel
                                    className={classes.searchForm}
                                    inputProps={{
                                        type: "text",
                                        value: query.keyword,
                                        fullWidth: true,
                                        placeholder: "Sök",
                                        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                                        onChange: (e) => { changeQuery({ keyword: e.target.value }) }
                                    }}
                                />
                            </Grid>
                            <Hidden smDown>
                                <Box flexGrow={1} />
                            </Hidden>
                            <Hidden smDown>
                                <Grid item lg={2} md={3} sm={12} xs={12}>
                                    <CustomButton variant="contained" color="green" onClick={() => history.push('/admin/faq/create')} fullWidth startIcon={<AddIcon />} >
                                        Lägg till
                                    </CustomButton>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.dataTable}>
                            <CustomDataTable
                                columns={columns}
                                data={data}
                                striped={false}
                                center={true}
                                highlightOnHover={true}
                                responsive={true}
                                persistTableHead={false}
                                progressPending={loading}
                                pagination
                                paginationServer
                                currentPage={query.page}
                                paginationPerPage={query.limit}
                                paginationTotalRows={totalData}
                                onChangePage={(page) => changePage(page)}
                            />
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Box className={classes.ordersList}>
                            {
                                data.map((item) => (

                                    <FaqItem
                                        key={item.id}
                                        data={item}
                                        handleClickDelete={() => {
                                            setOpenConfirmToDeleteModal(true)
                                            setSelectedData(item)
                                        }}
                                        handleEdit={() => {
                                            history.push('/admin/faq/update/' + item.id)
                                        }}
                                    />
                                ))
                            }
                        </Box>
                    </Hidden>
                </Box>
            </Grid>
            <Hidden mdUp>
                <Grid item sm={12} xs={12}>
                    <Box mt={3}>
                        <ProductCustomPagination
                            currentLimit={query.limit}
                            changeCurrentLimit={val => changeQuery({ limit: val })}
                            currentPage={query.page}
                            changePage={val => changeQuery({ page: val })}
                            pageCount={totalPage}
                            totalData={totalData}
                        />
                    </Box>
                </Grid>
                <Box mt={2}>
                    <CustomButton variant="contained" color="green" onClick={() => history.push('/admin/faq/create')} fullWidth startIcon={<AddIcon />} >
                        Lägg till
                    </CustomButton>
                </Box>
            </Hidden>
            {
                selectedData && (
                    <>

                        <ConfirmModal
                            title="Ta bort FAQ"
                            open={openConfirmToDeleteModal}
                            closeModal={() => setOpenConfirmToDeleteModal(false)}
                            text={
                                <Typography variant="body1" align="center">
                                    Är du säker på att du vill ta bort “<span>{selectedData.title}</span>”?
                                </Typography>
                            }
                            loading={loading}
                            confirmAction={async () => {
                                setLoadingButton(true)
                                await destroyFaqService(selectedData)
                                await getData()
                                setLoadingButton(false)
                                setOpenConfirmToDeleteModal(false)

                            }}
                        />

                        <FaqDetail
                            open={openViewModal}
                            data={selectedData}
                            closeModal={() => setOpenViewModal(false)}
                        />

                        <ConfirmModal
                            title="Publish FAQ"
                            open={openConfirmToPublisheModal}
                            closeModal={() => setOpenConfirmToPublisheModal(false)}
                            text={
                                <Typography variant="body1" align="center">
                                    Är du säker på att du vill publicera detta “<span>{selectedData.title}</span>”?
                                </Typography>
                            }
                            loading={loadingButton}
                            colorVariant="success"
                            confirmAction={async () => {
                                setLoadingButton(true)
                                await updatePublishStatusFaqService(selectedData)
                                await getData()
                                setLoadingButton(false)
                                setOpenConfirmToPublisheModal(false)
                            }}
                        />
                    </>
                )
            }

        </Grid>
    )
}

export default Faq
