import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import styles from "assets/jss/components/transactionHistoryItemStyle"
import moment from 'moment'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const TransactionHistoryItem = (props) => {

  const classes = useStyles()

  const { history, openModal } = props

  const statusClassname = classNames(
    classes.status,
    {
      [classes.finished]: history.status === "Finished",
      [classes.delivery]: history.status === "In Delivery",
      [classes.failed]: history.status === "Failed"
    }
  )

  return (
    <Box className={classes.transactionHistory}>
      <Box display="flex" alignItems="center" className={classes.topSection}>
        <Typography variant="body1" className={classes.transactionText}>
          Transaktion
        </Typography>
        <Box display="flex" className={classes.dateNumber}>
          <Typography variant="body1">
            {moment(history.created_at).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
          <Typography variant="body1">
            {history.invoice_number}
          </Typography>
        </Box>
        <Box className={statusClassname}>
          <Typography variant="body1">
            {history.transaction_status?.name}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contents} display="flex">
        <Box className={classes.leftSide}>
          <Typography variant="body1" className={classes.title}>
            {history.transaction_items[0].product_variant.product_name}
          </Typography>
          <Typography variant="body1" className={classes.price}>
            {numberFormat(history.transaction_items[0].total)} {currency}
          </Typography>
          {(history.transaction_items.length) > 1 && (
            <Typography variant="body1" className={classes.quantity}>
              {`+${history.transaction_items.length - 1} More Product`}
            </Typography>
          )}

        </Box>
      </Box>
      <Box textAlign="right" className={classes.detailBtn + ' ' + "detailBtn"}>
        <Button onClick={openModal}>
          Transaktions Detaljer
        </Button>
      </Box>
    </Box>
  )
}

export default TransactionHistoryItem
