import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputLabel from "@material-ui/core/InputLabel"

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"

import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"
import LoadingButton from 'components/CustomForms/LoadingButton'

import styles from "assets/jss/views/profileViewStyle.js"
import { useSelector } from 'react-redux';
import { updateMeService } from '../../../../services/UserService';
import { useDispatch } from 'react-redux'

import moment from 'moment';

const useStyles = makeStyles(styles)

const EditPersonalData = (props) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.user)
  const { user } = auth

  const { open, closeModal } = props

  const [selectedDate, setSelectedDate] = useState(user.date_of_birth)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)



  const handleDateChange = (date) => {
    setSelectedDate(date)
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const updateUserSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: name ? name : user.name,
      date_of_birth: selectedDate? moment(selectedDate).format('YYYY-MM-DD') : ''
    }
    setLoading(true)

    const res = await updateMeService({
      id: user.id,
      data
    });
    console.log({res})
    dispatch({ type: 'UPDATE_USER', payload: res })
      setLoading(false);
      closeModal();
  }

  return (
    <CustomModal open={open} closeModal={closeModal} size="small">
      <ModalHeader title="Redigera personuppgifter" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                labelText="Fullständiga namn"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input first name",
                  defaultValue: user.name,
                  onChange: handleNameChange
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                labelText="Användarnamn"
                inputProps={{
                  disable: true,
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input username",
                  defaultValue: user.username
                }} 
              />
            </Grid> */}
            <Grid item xs={12} className={classes.dateField}>
              <InputLabel htmlFor="birthday">
                Födelsedatum
                <span>*</span>
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date-picker-inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  fullWidth
                  placeholder="YYYY/MM/DD"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Box className={classes.modalFooter} textAlign="right">
        <Button className={classes.cancelBtn} onClick={closeModal}>
          Cancel
        </Button>
        <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={updateUserSubmit}>
          Uppdatering Data
        </LoadingButton>
      </Box>
    </CustomModal>
  )
}

export default EditPersonalData
