import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from "react-router-dom"

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'components/CustomForms/Button'

import styles from "assets/jss/components/productItemStyle"
import { useDispatch } from 'react-redux'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const ProductItem = (props) => {

	const dispatch = useDispatch()

	const classes = useStyles()

	const history = useHistory()

	const addToCart = (item) => {
		dispatch({type: 'ADD_ITEM_TO_CART', payload: {...item, quantity : 1}})
	}

	const { product } = props

	return (
		<Box className={classes.productCard} onClick={() => history.push('/product-detail/' + product.slug)}>
			<img src={product.thumbnail} alt="Product" style={{height: "calc(100% - 201px)"}} />
			<Box className={classes.content}>
				<Typography variant="h6" className={classes.title}>
					{product.name}
				</Typography>
				<Typography variant="body1" className={classes.price} align="center">
					{
						product.product_variants[0].undiscounted_selling_price !== null && product.product_variants[0].undiscounted_selling_price > product.product_variants[0].selling_price && (
							<span>
								{numberFormat(product.product_variants[0].undiscounted_selling_price)} {currency}
							</span>
						)
					}

					{numberFormat(product.product_variants[0].selling_price)} {currency}
				</Typography>
				<Button
					variant="outlined"
					color="green"
					className={classes.addBtn}
					fullWidth
					onClick={(e) => {
						e.preventDefault();
						addToCart(product.product_variants[0])
					}}
				>
					<ShoppingCartIcon /> Lägg till i Kundvagn
				</Button>
			</Box>
			{
				product.product_variants[0].undiscounted_selling_price !== null && product.product_variants[0].undiscounted_selling_price > product.product_variants[0].selling_price && (
					<Box className={classes.sale} display="flex" alignItems="center" justifyContent="center">
						<Box>
							<Typography variant="body1">{parseInt(100 - (product.product_variants[0].selling_price / (product.product_variants[0].undiscounted_selling_price / 100)))}%</Typography>
							<Typography variant="body1">RABATT</Typography>
						</Box>
					</Box>
				)
			}
		</Box>
	)
}

export default ProductItem
