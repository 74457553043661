import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import VisibilityIcon from '@material-ui/icons/Visibility'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import SearchIcon from '@material-ui/icons/Search'

import { Box, Hidden, IconButton, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import TextField from "components/CustomForms/TextField"
import CustomDateField from "components/CustomForms/CustomDateField"
import OrderItem from "./components/OrderItem"
import OrderDetail from "./components/OrderDetail"
import SetDeliveryModal from "./components/SetDeliveryModal"

import styles from "assets/jss/views/admin/orderViewStyle"
import FilterIcon from 'assets/img/filter.png'
import { getPaginateData } from './_redux/orderCrud'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'
import moment from 'moment'
// import { getShippingMethodServices } from 'services/ShippingMethodService'
import { getPaymentService } from 'services/PaymentService'
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'
import ProductCustomPagination from 'components/CustomPagination/ProductCustomPagination'
import { setToDeliveryTransactionService } from 'services/TransactionService'

const useStyles = makeStyles(styles)

const statuses = [
  'pending',
  'paid',
  'void'
]

const Order = () => {

  const classes = useStyles()
  // const [shippingMethods, setShippingMethods] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [openDetail, setOpenDetail] = useState(false)
  const [, setOpenConfirmModal] = useState(false)
  const [loadingDeliveryAction, setLoadingDeliveryAction] = useState(false)
  const [openDeliveryModal, setDeliveryModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null);
  const [totalData, setTotalData] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const columns = [
    {
      selector: row => row.invoice_number,
      name: "Faktura",
      wrap: 'true',
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.created_at,
      name: "Datum",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <Typography>
          {moment(row.created_at).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      )
    },
    {
      selector: row => row.recepient_name,
      name: "Faktureringsnamn",
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.payment,
      name: "Betalning",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <Typography>
          {row.payment?.name ?? ''}
        </Typography>
      )
    },
    {
      selector: row => row.status,
      name: "status",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <Box className={classNames(
          classes.statusBadge,
          {
            [classes.approvedStatus]: row.status === "paid",
            [classes.pendingStatus]: row.status === "pending",
            [classes.failedStatus]: row.status === "void"
          }
        )}>
          {row.transaction_status?.name}
        </Box>
      )
    },
    {
      selector: row => row.grand_total,
      name: "total",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <Typography variant="body1" className={classes.totalPrice}>
          {numberFormat(row.grand_total)} {currency}
        </Typography>
      )
    },
    {
      selector: row => row.id,
      name: "Handling",
      grow: 4,
      cell: (row) => (
        <Box>
          {
            (row.status === "paid") && (
              <Button variant="contained" onClick={() => {setSelectedData(row); setDeliveryModal(true)}} className={classNames(classes.actionButton, classes.deliveryBtn)} startIcon={<LocalShippingIcon />}>
                Setleverans
              </Button>
            )
          }
          <Button variant="contained" onClick={() => { setSelectedData(row); setOpenDetail(true) }} className={classes.actionButton} startIcon={<VisibilityIcon />}>
            Detalj
          </Button>

        </Box>
      )
    }
  ]

  const [transactions, setTransactions] = useState([])

  const [query, setQuery] = useState({
    keyword: '',
    date: '',
    status: '',
    payment_method: ''
  })
  const getData = async () => {
    try {
      const data = await getPaginateData(query)
      setTransactions(data?.data?.data ?? [])
      setTotalPage(data?.data?.total_page ?? 0)
      setTotalData(data?.data?.total_data ?? 0)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{ page: page } })
  }

  const getPaymentMethods = async () => {
    try {
      const data = await getPaymentService()
      setPaymentMethods(data)
    } catch (err) {
      console.log(err)
    }
  }

  const setToDelivery = async(transaction_id, user_id) => {
    try{
      await setToDeliveryTransactionService({transaction_id: transaction_id, user_id: user_id})
    }catch(err){
      console.log(err)
    }
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props })
  }

  useEffect(() => {
    getData()
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getData()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (!openDetail) {
      setSelectedData(null)
    }
  }, [openDetail])

  useEffect(() => {
    if (!openDeliveryModal) {
      setSelectedData(null)
    }
  }, [openDeliveryModal])




  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AdminViewTitles mainTitle="Hantera ordning" subTitle="Hantera order från kund" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.tableWrapper}>
          <Box className={classes.filterWrapper}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <TextField
                  disableLabel
                  className={classes.searchForm}
                  inputProps={{
                    type: "text",
                    value: query.keyword,
                    fullWidth: true,
                    placeholder: "Sök",
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    onChange: (e) => { changeQuery({ keyword: e.target.value }) }
                  }}
                />
                <Hidden mdUp>
                  <IconButton>
                    <img src={FilterIcon} alt="Filter" />
                  </IconButton>
                </Hidden>
              </Grid>
              <Hidden smDown>
                <Grid item lg={3} md={3} sm={12}>
                  <CustomDateField
                    value={moment(query.date, 'YYYYY/MM/DD').format()}
                    handleChange={(e) => {

                      if (e) {
                        if (moment(e).isValid()) {
                          changeQuery({ date: moment(e).format('YYYY/MM/DD') })
                        }

                      } else {
                        changeQuery({ date: '' })
                      }


                    }}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} className={classes.selectWrapper}>
                  <Select
                    value={query.status}
                    onChange={(e) => { changeQuery({ status: e.target.value }) }}
                    className={classes.select}
                    fullWidth
                  >
                    <MenuItem value="">
                      allStatus
                    </MenuItem>
                    {statuses.map((item, index) => (
                      <MenuItem value={item} key={index}>{item}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item lg={3} md={3} sm={12} className={classes.selectWrapper}>
                  <Select
                    value={query.payment_method}
                    onChange={(e) => changeQuery({ payment_method: e.target.value })}
                    className={classes.select}
                    fullWidth
                  >
                    <MenuItem value="">
                      Alla Betalningsmetod
                    </MenuItem>
                    {paymentMethods.map((item) => (
                      <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
          <Hidden smDown>
            <Box className={classes.dataTable}>
              <CustomDataTable
                columns={columns}
                data={transactions}
                striped={false}
                center={true}
                highlightOnHover={true}
                responsive={true}
                persistTableHead={false}
                progressPending={loading}
                pagination
                paginationServer
                currentPage={query.page}
                paginationPerPage={query.limit}
                paginationTotalRows={totalData}
                onChangePage={(page) => changePage(page)}
              />
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.ordersList}>
              {
                transactions.map((order) => (

                  <OrderItem
                    key={order.id}
                    order={order}
                    openDetailModal={() => {
                      setSelectedData(order); 
                      setOpenDetail(true) 
                      }
                    }
                    openDeleteConfirmModal={() => {}}
                    openDeliveryModal={() => setDeliveryModal(true)}
                  />
                ))
              }
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Hidden mdUp>
        <Grid item sm={12} xs={12}>
          <Box mt={3}>
            <ProductCustomPagination 
                currentLimit={query.limit} 
                changeCurrentLimit={val => changeQuery({ limit: val })} 
                currentPage={query.page} 
                changePage={val => changeQuery({ page: val })} 
                pageCount={totalPage}
                totalData={totalData}
              />
          </Box>
        </Grid>
      </Hidden>
      {
        selectedData && (
          <>
            <OrderDetail
              open={openDetail}
              transaction={selectedData}
              closeModal={() => setOpenDetail(false)}
            />
            <SetDeliveryModal
              loading={loadingDeliveryAction}
              open={openDeliveryModal}
              transaction={selectedData}
              handleOnSetDelivery={async(user_id) => {
                setLoadingDeliveryAction(true)
                await setToDelivery(selectedData.id, user_id)
                await getData()
                setLoadingDeliveryAction(false)
                setDeliveryModal(false)
              }}
              closeModal={() => setDeliveryModal(false)}
            />
          </>
        )
      }

    </Grid>
  )
}

export default Order
