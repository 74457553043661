import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { Box, Hidden, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import styles from "assets/jss/views/staff/productViewStyle"
import CustomButton from 'components/CustomForms/Button'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'
import { useDispatch, useSelector } from 'react-redux'


const useStyles = makeStyles(styles)

const ProductItem = (props) => {

    const classes = useStyles()

    const { product } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [count, setCount] = useState()
    // const count = 12
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };



    useEffect(() => {
        setCount(product.quantity_in_cart)
    }, [product])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch()
    const cart = useSelector(item => item.cart)
    const { items } = cart

    const productVariant = product.product_variants[0]

    const cartItem = items.find((item) => {
        if (productVariant) {
            if (productVariant.id === item.id) {
                return item;
            }

            return null
        }

        return null
    })

    return (
        <Box className={classes.productItem}>
            <img src={product.thumbnail} alt="Product" width="82px" height="82px" />
            <Box className={classes.productInfo}>
                <Typography variant="body1" className={classes.productTitle}>
                    {product.name}
                </Typography>
                <Box display="flex">
                    {/* <Box className={classes.infoItem}>
                        <Typography variant="body1">
                            Stock :
                        </Typography>
                        <Typography variant="body1">
                            {`${product.stock} Pcs`}
                        </Typography>
                    </Box> */}
                    <Box className={classes.infoItem}>
                        <Typography variant="body1">
                            Pris :
                        </Typography>
                        <Typography variant="body1">
                            {`${numberFormat(product?.product_variants[0].selling_price)} ${currency}`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Hidden smDown>
                <Box className={classes.actionList}>
                    {cartItem?.quantity > 0 ? (
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <CustomButton
                                variant="contained"
                                className={classNames({ [classes.disableBtn]: count < 0 })}
                                color="green"
                                onClick={() => { (cartItem?.quantity ?? 0) > 0 && dispatch({type: 'REMOVE_ITEM_FROM_CART', payload : {...productVariant, quantity : 1}}) }}
                            >
                                -
                            </CustomButton>
                            <Typography variant="body1" style={{ color: 'white' }}>
                                {cartItem?.quantity ?? 0}
                            </Typography>
                            <CustomButton variant="contained" color="green" onClick={() => dispatch({ type: 'ADD_ITEM_TO_CART', payload: { ...productVariant, quantity: 1 } })}>
                                +
                            </CustomButton>
                        </Box>
                    ) : (
                        <CustomButton onClick={() => dispatch({ type: 'ADD_ITEM_TO_CART', payload: { ...productVariant, quantity: 1 } })} variant="text" color="green">Köpa</CustomButton>
                    )}


                </Box>
            </Hidden>
        </Box>
    )
}

export default ProductItem
