import React, { useState } from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import cx from "classnames";

import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

import { makeStyles } from "@material-ui/core/styles"

import { Box } from '@material-ui/core'

import Sidebar from "components/Sidebar"
import AdminNavbar from "components/Navbars/AdminNavbar/AdminNavbar"
import routes from "routes.js"

import styles from "assets/jss/layouts/adminStyle"
import { useSelector } from 'react-redux';

import AdminLogin from 'views/Auth/AdminLogin';

import MarketNavbar from "components/Navbars/MarketNavbar"
import MarketFooter from "components/Footer/MarketFooter"

var ps;

const useStyles = makeStyles(styles)


const Admin = (props) => {

  const { ...rest } = props;

  const pathName = rest.location.pathname;


  const classes = useStyles()

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });

  const mainPanel = React.createRef();

  const { user } = useSelector(item => item.user)

  const isAdmin = user.roles.findIndex(
    (item) => item.name === "super_admin" || item.name === "admin"
  )

  React.useEffect(() => {
    if (pathName !== '/admin' && isAdmin > -1) {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);

      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    }
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (prop.protected) {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  const PrivateRoute = (prop) => {
    const { isLoggedIn, user } = useSelector(item => item.user)
    if (isLoggedIn) {
      const isAdmin = user.roles.findIndex(
        (item) => item.name === "super_admin" || item.name === "admin"
      )

      if (isAdmin > -1) {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={prop.key}
          />
        )
      } else {
        return (
          <Route
            path={prop.path}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: "/admin",
                  state: { from: location }
                }}
              />
            )}
            key={prop.key}
          />
        )
      }
    } else {
      return (
        <Route
          path={prop.path}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: "/admin",
                state: { from: location }
              }}
            />
          )}
          key={prop.key}
        />
      )
    }
  }

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  if (isAdmin <= -1 && pathName == "/admin") {
    return (
      <Box>
        <MarketNavbar />
        <AdminLogin />
        <MarketFooter />
      </Box>
    )
  }

  return (
    <Box className={classes.wrapper}>
      {isAdmin > -1
        ? (
          <>
            <Sidebar
              routes={routes.filter(route => route.layout === "/admin")}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              miniActive={miniActive}
              {...rest}
            />
            <Box className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              <Box className={classes.content}>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/admin" to="/admin/dashboard" />
                </Switch>
              </Box>
            </Box>
          </>
        )
        : (
          <Redirect from="/admin" to="/admin" />
        )}

    </Box>
  )
}

export default Admin
