import { Box, Divider, Grid, Hidden, makeStyles, Typography } from "@material-ui/core"
import React from "react"

import styles from "assets/jss/components/orderDetailModalStyle"
import numberFormat from "utils/number-format"
import currency from "utils/currency"
import classNames from 'classnames'

const useStyles = makeStyles(styles)

const OrderInformation = props => {
    const {transaction} = props

    const classes = useStyles()

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} className={classNames(classes.contentBlock, classes.billingInfoSection)}>
                    <Typography variant="body1" className={classes.blockTitle}>
                        Billing Information
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingLeft}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body1">
                                    Frakt Metod
                                </Typography>
                                <Typography variant="body1">
                                    Type {transaction.shipping_method?.id}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body1">
                                    Tracking Numer
                                </Typography>
                                <Typography variant="body1">
                                    {transaction.tracking_number}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body1">
                                    Notes
                                </Typography>
                                <Typography variant="body1">
                                    {transaction.recepient_notes ?? '-'}
                                </Typography>
                            </Box>
                        </Grid>

                        <Hidden mdUp>
                            <Grid item xs={12} className={classes.setPadding}>
                                <Divider className={classes.divider} />
                            </Grid>
                        </Hidden>

                        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingRight}>
                            <Typography variant="body1">
                                {transaction.recepient_name}
                            </Typography>
                            <Typography variant="body1">
                                {transaction.recepient_email}
                            </Typography>
                            <Typography variant="body1">
                                {transaction.recepient_phone_number}
                            </Typography>
                            <Typography variant="body1">
                                {transaction.recepient_stree_address}
                            </Typography>
                            <Typography variant="body1">
                                {transaction.recepient_street_number}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classNames(classes.contentBlock, classes.orderListSection)}>
                    <Typography variant="body1" className={classes.blockTitle}>
                        Orderlista
                    </Typography>
                    {transaction.transaction_items.map((item) => (
                        <>
                            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderLine}>
                                <Box>
                                    <Typography variant="body1">
                                        {item.product_variant.product_name}
                                    </Typography>
                                    <Typography variant="body1">
                                        {numberFormat(item.quantity)} X {numberFormat(item.selling_price_per_item)} {currency}
                                    </Typography>
                                </Box>
                                <Typography variant="body1">
                                    {numberFormat(item.total)} {currency}
                                </Typography>
                            </Box>
                            <Divider className={classes.divider} />
                        </>
                    ))}
                </Grid>

                <Grid item xs={12} className={classNames(classes.contentBlock, classes.summarySection)}>
                    <Typography variant="body1" className={classes.blockTitle}>
                        Ordersammanfattning
                    </Typography>
                    <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                        <Typography variant="body1">
                            Betalningsmetod
                        </Typography>
                        <Typography variant="body1">
                            {transaction.payment.name}
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                    <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                        <Typography variant="body1">
                            Delsumma
                        </Typography>
                        <Typography variant="body1">
                            {transaction.transaction_items.length > 0 ? numberFormat(transaction.transaction_items.reduce((prev, next) => prev + (next.selling_price_per_item * next.quantity), 0)) : 0} {currency}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                        <Typography variant="body1">
                            Fraktkostnad
                        </Typography>
                        <Typography variant="body1">
                            {numberFormat(transaction.shipping_price)} {currency}
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                    <Box display="flex" justifyContent="space-between" className={classes.totalPayment}>
                        <Typography variant="body1">
                            Total betalning
                        </Typography>
                        <Typography variant="body1">
                            {numberFormat(transaction.grand_total)} {currency}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}

export default OrderInformation