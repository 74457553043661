import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, Button, Divider, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import EditPersonalData from "./EditPersonalData"
import EditContactData from "./EditContactData"
import EditPassword from "./EditPassword"

import styles from "assets/jss/views/profileViewStyle"

import userAvatar from "assets/img/user.png"
import editIcon from "assets/img/edit.png"

import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles)

const ProfileDataPanel = () => {

  const classes = useStyles()

  const auth = useSelector((state) => state.user)
  const { user } = auth

  const [openPersonalModal, setOpenPersonalModal] = useState(false)
  const [openContactModal, setOpenContactModal] = useState(false)
  const [openPassowrdModal, setOpenPassowrdModal] = useState(false)

  return (
    <Box className={classes.profileDataSection} display="flex" justifyContent="space-between">
      <Box className={classes.avatar} position="relative">
        <img src={userAvatar} alt="User" width="100%" />
        <IconButton className={classes.editProfile}>
          <img src={editIcon} alt="Edit" />
        </IconButton>
      </Box>
      <Box className={classes.profileDataCol}>
        <Box className={classes.profileDataFieldRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Personlig information
          </Typography>
          <Button onClick={() => setOpenPersonalModal(true)}>Ändra</Button>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Användarnamn
          </Typography>
          <Typography variant="body1">
            {user.username}
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Name
          </Typography>
          <Typography variant="body1">
            {user.name}
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Födelsedatum
          </Typography>
          <Typography variant="body1">
            {user.date_of_birth}
          </Typography>
        </Box>
      </Box>

      <Hidden smDown>
        <Divider className={classes.profileDivider} orientation="vertical" flexItem />
      </Hidden>
      <Hidden mdUp>
        <Divider className={classes.profileDivider} />
      </Hidden>

      <Box className={classes.profileDataCol}>
        <Box className={classes.profileDataFieldRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Kontaktinformation
          </Typography>

          <Button onClick={() => setOpenContactModal(true)}>Ändra</Button>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            E-postadress
          </Typography>
          <Typography variant="body1">
            {user.email}
          </Typography>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Telefonnummer
          </Typography>
          <Typography variant="body1">
            {user.phone_number}
          </Typography>
        </Box>
      </Box>
      <Hidden smDown>
        <Divider className={classes.profileDivider} orientation="vertical" flexItem />
      </Hidden>
      <Hidden mdUp>
        <Divider className={classes.profileDivider} />
      </Hidden>
      <Box className={classes.profileDataCol}>
        <Box className={classes.profileDataFieldRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Säkerhet
          </Typography>
          <Button onClick={() => setOpenPassowrdModal(true)}>Ändra</Button>
        </Box>
        <Box className={classes.profileDataRow} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            Lösenord
          </Typography>
          <Typography variant="body1">
            *********
          </Typography>
        </Box>
      </Box>
      <EditPersonalData
        open={openPersonalModal}
        closeModal={() => setOpenPersonalModal(false)}
      />
      <EditContactData
        open={openContactModal}
        closeModal={() => setOpenContactModal(false)}
      />
      <EditPassword
        open={openPassowrdModal}
        closeModal={() => setOpenPassowrdModal(false)}
      />
    </Box>
  )
}

export default ProfileDataPanel
