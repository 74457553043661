import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

import { Box, Hidden, Button, FormHelperText, MenuItem, FormControl } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CustomButton from "components/CustomForms/Button"
import CustomTextField from "components/CustomForms/TextField"
import ConfirmModal from "components/CustomModal/ConfirmModal"
import * as Yup from 'yup'

import styles from "assets/jss/views/admin/addProductViewStyle"
import addItem1 from "assets/img/product detail/add-product-1.png"
import addItem2 from "assets/img/product detail/add-product-2.png"
import { Form, withFormik } from 'formik'
import request from 'utils/request'
import { useSelector } from 'react-redux'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { storeProduct, updateProduct } from './_redux/productCrud'
import { getDetailProduct } from './_redux/productCrud'
import { getCategoryService } from 'services/CategoryService'
import Select from '@material-ui/core/Select'
import { InputLabel } from '@mui/material'
import currency from 'utils/currency'


const useStyles = makeStyles(styles)

const BASE_URL = process.env.REACT_APP_BASE_URL

const ProductSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  name: Yup.string()
    .max(191)
    .required(),
  selling_price: Yup.number()
    .moreThan(1)
    .positive()
    .required(),
  undiscounted_selling_price: Yup.number()
    .positive()
    .nullable(),
  description: Yup.string()
    .required(),
  category_id: Yup.number()
    .nullable(),
  images: Yup.array()
    .ensure()
    .min(1)
    .required()
})

const ProductProps = {
  id: null,
  name: '',
  selling_price: 0,
  undiscounted_selling_price: '',
  description: '',
  category_id: null,
  images: []
}

const FormEnchancer = withFormik({
  mapPropsToValues: (props) => ({
    id: props.product?.id || null,
    name: props.product?.name || '',
    selling_price: props.product?.selling_price || 0,
    undiscounted_selling_price: props.product?.undiscounted_selling_price || 0,
    description: props.product?.description || '',
    category_id: props.product?.category_id || null,
    images: props.product?.images || []
  }),
  validationSchema: ProductSchema,
  enableReinitialize: true,
  handleSubmit: (values) => {

  },
  isInitialValid: props => ProductSchema.isValidSync(ProductProps),
})

const AddProduct = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue,
  } = props

  const classes = useStyles()

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const params = useParams()
  const [categoryData, setCategoryData] = useState([])

  const { id } = params
  const uploadFile = () => {
    document.getElementById("upload-product-img").click()
  }

  const user = useSelector(item => item.user)

  const uploadImage = (e) => {

    const formData = new FormData()
    formData.append('file', e.target.files[0])
    formData.append('path', 'products')
    fetch(BASE_URL + '/file/upload-image', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + user.token,
      },
      body: formData
    })
      .then(res => res.json())
      .then(res => {
        let image_uploads = values.images
        image_uploads.push(res.data.image_name)
        setFieldValue('images', image_uploads)
      })
      .catch((err) => setErrorMessage(err.message))
  }

  const handleSubmit = async () => {
    if (isValid) {
      try {
        setLoading(true)
        if (id) {
          await updateProduct(values)
        } else {
          await storeProduct(values)
        }

        setTimeout(() => {
          setLoading(false)

          history.replace('/admin/product')
        }, 3000)


      } catch (error) {
        setLoading(false)
        setErrorMessage(error)
      }
    }
  }

  const getData = async () => {
    try {
      const data = await getDetailProduct(params)
      const variant = data.product_variants[0]
      setFieldValue('id', data.id)
      setFieldValue('name', data.name)
      setFieldValue('selling_price', parseInt(variant.selling_price))
      setFieldValue('undiscounted_selling_price', parseInt(variant.undiscounted_selling_price ?? 0))
      setFieldValue('description', data.description)
      setFieldValue('category_id', data.category_id)
      setFieldValue('images', data.product_images.map(item => item.image_path))

    } catch (error) {

    }
  }

  const getCategoryDataList = async () => {
    try {
      const res = await getCategoryService({
        limit: 10,
        page: 1,
        keyword: ''
      })
      setCategoryData(res?.data ?? [])
    } catch (err) {

    }
  }

  useEffect(() => {
    if (id) {
      getData(id)
    }
    getCategoryDataList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container >
      <Form>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button startIcon={<ArrowBackIcon />} className={classes.backBtn} onClick={() => history.push('/admin/product')}>
            Tillbaka
          </Button>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.contentSection}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h3">
                Add Product
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="body1" className={classes.productLabel}>
                Bildprodukt<span>*</span>
              </Typography>
              <Box display="flex">
                {
                  values.images.map((item, index) => {
                    return (
                      <Box className={classes.imageItem} key={index}>
                        <img src={item} alt="Product" width="100%" height="100%" />
                      </Box>
                    )
                  })
                }

                <Box className={classes.uploadField} position="relative">
                  <input type="file" id="upload-product-img" onChange={uploadImage} />
                  <Box position="absolute" textAlign="center" onClick={uploadFile}>
                    <CloudUploadOutlinedIcon />
                    <Typography variant="body1" align="center">
                      Ladda upp bilder
                    </Typography>
                  </Box>
                  {touched.images && errors.images && (
                    <FormHelperText error id="password">
                      {errors.images}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomTextField
                labelText="produktnamn"
                error={touched.name && errors.name}
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Inmatningsproduktnamn",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.name,
                  name: "name",
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomTextField
                labelText="Pris"
                error={touched.selling_price && errors.selling_price}
                inputProps={{
                  type: "number",
                  fullWidth: true,
                  placeholder: "Ingångspris",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.selling_price,

                  name: "selling_price",
                  startAdornment: <Button className={classNames(classes.adornmentBtn, classes.startAdorBtn)}>{currency}</Button>
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <CustomTextField
                labelText="Före rabatt"
                error={touched.selling_price && errors.undiscounted_selling_price}
                inputProps={{
                  type: "text",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.undiscounted_selling_price,
                  name: "undiscounted_selling_price",
                  fullWidth: true,
                  placeholder: "Input före rabatt",
                  endAdornment: <Button className={classNames(classes.adornmentBtn, classes.endAdorBtn)}>{currency}</Button>
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl>
              <InputLabel id="category_id" style={{color: 'white'}}>Produkter Kategori</InputLabel>
              <Select
                labelText="Category"
                id="category_id"
                type="text"
                name="category_id"
                value={values.category_id}
                onChange={handleChange}
                handleBlur={handleBlur}
                className={classes.select}
                fullWidth
              >
                {categoryData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTextField
                labelText="Beskrivning"
                error={touched.description && errors.description}
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Inmatningsproduktbeskrivning",
                  multiline: true,
                  rows: 6,
                  defaultValue: '',
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.description,
                  name: "description",
                }}
              />
            </Grid>
            
            <Hidden smDown>
              <Grid item lg={12} md={12} sm={12}>
                <Box textAlign="right" mt={3}>
                  <Button className={classes.cancelBtn}>
                    Annullera
                  </Button>
                  <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={handleSubmit}>
                    Spara
                  </LoadingButton>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden mdUp>
          <Grid item sm={6} xs={6}>
            <Button className={classes.cancelBtn} fullWidth>
              Annullera
            </Button>
          </Grid>
          <Grid item sm={6} xs={6}>
            <LoadingButton type="submit" loading={loading} variant="contained" color="green" fullWidth onClick={handleSubmit}>
              Spara
            </LoadingButton>
          </Grid>
        </Hidden>
        {/* <ConfirmModal 
        open={openConfirmModal} 
        closeModal={() => setOpenConfirmModal(false)} 
        text={
          <Typography variant="body1" align="center">
            Are you sure want to add product “<span>{values.name}</span>”?
          </Typography>
        }
        colorVariant="success"
        loading={loading}
        title="Save"
        confirmAction={() => setOpenConfirmModal(false)}
      /> */}
      </Form>
    </Grid>
  )
}

export default FormEnchancer(AddProduct)
