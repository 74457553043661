import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import VisibilityIcon from '@material-ui/icons/Visibility'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import { Box, Hidden } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import TextField from "components/CustomForms/TextField"
import CustomButton from 'components/CustomForms/Button'
import AdminViewTitles from "components/Breadcrumb/AdminViewTitles"
import CustomIconButton from "components/CustomForms/CustomIconButton"
import UserItem from "./components/UserItem"
import UserDetail from "./components/UserDetail"
import ConfirmModal from "components/CustomModal/ConfirmModal"
import EditUser from "./components/EditUser"

import styles from "assets/jss/views/admin/userViewStyle"
import { getUserService } from 'services/UserService'
import UserProps from './crud/userProps'
import { destroyUserService } from 'services/UserService'
import CustomDataTable from 'components/CustomDataTable/CustomDataTable'
import ProductCustomPagination from 'components/CustomPagination/ProductCustomPagination'

const useStyles = makeStyles(styles)

const User = () => {

  const classes = useStyles()

  const [openDetail, setOpenDetail] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(UserProps)
  const [totalPage, setTotalPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [query, setQuery] = useState({
    limit: 10,
    page: 1,
    keyword: '',
    roles: 'customer'
  })

  useEffect(() => {
    if (!openEditUserModal) {
      setSelectedUser(UserProps)
      getUser()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditUserModal])

  const getUser = async () => {
    try {
      setLoading(true)
      const data = await getUserService(query)
      setUsers(data.data ?? [])
      setTotalPage(data.total_page)
      setTotalData(data.total_data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const changeQuery = (props) => {
    setQuery({ ...query, ...props })
  }

  const changePage = (page) => {
    setLoading(true)
    setQuery({ ...setQuery, ...{page : page}})
  }

  useEffect(() => {
    const delauyDebounceFn = setTimeout(() => {
      getUser()

    }, 2000)

    return () => clearTimeout(delauyDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const columns = [
    {
      selector: row => row.name,
      name: "Namn",
      wrap: 'true',
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.email,
      name: "E-postadress",
      sortable: true,
      grow: 3
    },
    {
      selector: row => row.phone_number,
      name: "Telefon",
      sortable: true,
      grow: 2
    },
    {
      selector: row => row.id,
      name: "Handling",
      grow: 1,
      cell: (row) => (
        <Box className={classes.actionList}>
          <CustomIconButton
            onClick={() => {
              setOpenDetail(true);
              setSelectedUser(row)
            }
            }
          >
            <VisibilityIcon />
          </CustomIconButton>
          <CustomIconButton onClick={() => { setOpenEditUserModal(true); setSelectedUser(row) }}><EditIcon /></CustomIconButton>
          <CustomIconButton onClick={() => { setOpenConfirmModal(true); setSelectedUser(row) }}><DeleteIcon /></CustomIconButton>
        </Box>
      )
    }
  ]

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AdminViewTitles mainTitle="Hantera användaren" subTitle="" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.tableWrapper}>
          <Box className={classes.filterWrapper}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                <TextField
                  disableLabel
                  className={classes.searchForm}
                  inputProps={{
                    type: "text",
                    value: query.keyword,
                    fullWidth: true,
                    placeholder: "Sök användare med namn eller e-post",
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    onChange: (e) => { changeQuery({ keyword: e.target.value }) }
                  }}
                />
              </Grid>
              <Hidden smDown>
                <Box flexGrow={1} />
              </Hidden>
              <Hidden smDown>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                  <CustomButton variant="contained" color="green" onClick={() => setOpenEditUserModal(true)} fullWidth startIcon={<AddIcon />} >
                    Lägg till
                  </CustomButton>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
          <Hidden smDown>
            <Box className={classes.dataTable}>
              <CustomDataTable
                columns={columns}
                data={users}
                striped={false}
                center={true}
                highlightOnHover={true}
                responsive={true}
                progressPending={loading}
                persistTableHead={false}
                pagination
                paginationServer
                currentPage={query.page}
                paginationPerPage={query.limit}
                paginationTotalRows={totalData}
                onChangePage={(page) => changePage(page)}
              />
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.ordersList}>
              {
                users.map((user) => (
                  <UserItem
                    key={user.id}
                    user={user}
                    handleClickDetail={() => {
                      setOpenDetail(true);
                      setSelectedUser(user)
                    }}
                    handleClickDelete={() => { setOpenConfirmModal(true); setSelectedUser(user) }}
                    handleClickEdit={() => {setOpenEditUserModal(true); setSelectedUser(user)}}
                  />
                ))
              }
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Hidden mdUp>
        <Grid item sm={12} xs={12}>
          <Box mt={3}>
            <ProductCustomPagination 
                currentLimit={query.limit} 
                changeCurrentLimit={val => changeQuery({ limit: val })} 
                currentPage={query.page} 
                changePage={val => changeQuery({ page: val })} 
                pageCount={totalPage}
                totalData={totalData}
              />
          </Box>
          <Box mt={2}>
            <CustomButton variant="contained" color="green" onClick={() => setOpenEditUserModal(true)} fullWidth startIcon={<AddIcon />} >
              Lägg till
            </CustomButton>
          </Box>
        </Grid>
      </Hidden>
      <UserDetail
        open={openDetail}
        user={selectedUser}
        closeModal={() => setOpenDetail(false)}
      />
      <ConfirmModal
        open={openConfirmModal}
        closeModal={() => setOpenConfirmModal(false)}
        text={
          <Typography variant="body1" align="center">
            Är du säker på att du vill radera användare “<span>{selectedUser.name}</span>”?
          </Typography>
        }
        confirmAction={async () => {
          await destroyUserService(selectedUser)
          setOpenConfirmModal(false)
        }}
      />
      <EditUser
        open={openEditUserModal}
        user={selectedUser}
        closeModal={() => setOpenEditUserModal(false)}
      />
    </Grid>
  )
}

export default User
