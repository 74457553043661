import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import persistStore from 'redux-persist/es/persistStore';
import { rootReducer } from './root-reducer'

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    })
    
})

export const persistor = persistStore(store);

export default store