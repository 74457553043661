import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import styles from "assets/jss/components/blogItemStyle"
import moment from 'moment'

const useStyles = makeStyles(styles)

const BlogItem = (props) => {

  const classes = useStyles()

  const history = useHistory()

  const { blog } = props

  return (
    <Box className={classes.blogItem} onClick={() => history.push(`/blog/${blog.slug}`)}>
      <Box className={classes.image}>
        <img src={blog.banner_url} alt={blog.title} width="100%" height='100%' />        
      </Box>
      <Box className={classes.contents}>
        <Typography variant="h6">
          { blog.title }
        </Typography>
        <Typography variant="body1">
          { `${ moment(blog.is_published_at).format('YYYY/MM/DD') }` }
        </Typography>
      </Box>
    </Box>
  )
}

export default BlogItem
