import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from "react-router-dom"

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import Hidden from '@material-ui/core/Hidden'
import * as Yup from 'yup'
import moment from 'moment'


import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import styles from "assets/jss/views/marketAuthStyle"

import Logo from "assets/img/footer-logo.png"
import MobileLogo from "assets/img/baloon-company-mobile.png"
import { register } from './_redux/authCrud'
import { useDispatch } from 'react-redux'
import { Form, withFormik } from 'formik'
import { Alert, FormHelperText } from '@mui/material'
import LoadingButton from 'components/CustomForms/LoadingButton'

const useStyles = makeStyles(styles)

const RegisterSchema = Yup.object().shape({
	name: Yup.string()
		.max(191, 'Maximal längd 191 tecken')
		.required('Namn krävs'),
	username: Yup.string()
		.max(191, 'Maximal längd är 191 tecken')
		.required('Nödvändig!'),
	password: Yup.string()
		.required('Nödvändig!'),
	email: Yup.string().email('Invalid E-postadress')
		.required(),
	date_of_birth: Yup.date()
		.required()
})

const RegisterProps = {
	name: '',
	username : '',
	password: '',
	email : '',
	date_of_birth : ''
}

const FormEnchancher = withFormik({
	mapPropsToValues: () => (RegisterProps),
	validationSchema: RegisterSchema,
	handleSubmit: (values) => {
	},
	isInitialValid: props => RegisterSchema.isValidSync(RegisterProps),

})

const Register = props => {

	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		isValid,
		setFieldValue
	} = props;
  
	const classes = useStyles()

	const history = useHistory()

	const dispatch = useDispatch()

	const [selectedDate, setSelectedDate] = useState(null)
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const handleDateChange = (date) => {
		setSelectedDate(date)		
	};

	const handleSubmit = async () => {
		if (isValid) {
			try {
				setLoading(true)

				const res = await register({
					username : values.username,
					email : values.email,
					date_of_birth : values.date_of_birth,
					password : values.date_of_birth,
					name : values.name
				})
				setTimeout(() => {
					
					dispatch({type : 'LOGIN_SUCCESS', payload : res.data})
					setLoading(false)

					history.replace('/')
				}, 3000)
				

			} catch (error) {
				setLoading(false)
				setErrorMessage(error)
			}
		}
	}

	return (
		<Box className={classes.wrapper}>
			<Container maxWidth="xl" className={classes.container}>
				<Grid container>
					<Grid item lg={6} md={6} sm={12} xs={12} className={classes.leftSide}>
						<Box className={classes.contents}>						

							<Hidden xsDown>
								<img src={Logo} alt="Logo" />
							</Hidden>
							<Hidden smUp>
								<img src={MobileLogo} alt="Logo" />
							</Hidden>
							<Typography variant="h3">
								Registrera nytt konto
							</Typography>
							<Form>
								{errorMessage && (
									<Box className={classes.errorField}>
										<Alert severity='error' variant="filled">
											{errorMessage}
										</Alert>
									</Box>
								)}
								<Box className={classes.textField}>
									<InputLabel htmlFor="name"	>
										Ditt namn
									</InputLabel>
									<OutlinedInput 
										id="name" 
										variant="outlined" 
										fullWidth
										name="name"
										placeholder="Ange ditt namn"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										error={touched.name && Boolean(errors.name)}
									/>
									{!!touched.name && errors.name && (
										<FormHelperText error id="username">
											{errors.name}
										</FormHelperText>
									)}
								</Box>
								<Box className={classes.textField}>
									<InputLabel htmlFor="username"	>
										Användarnamn
									</InputLabel>
									<OutlinedInput 
										id="username" 
										variant="outlined" 
										fullWidth
										name="username"
										placeholder="Inmatat nytt användarnamn"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.username}
										error={touched.username && Boolean(errors.username)}
									/>
									{!!touched.username && errors.username && (
										<FormHelperText error id="username">
											{errors.username}
										</FormHelperText>
									)}
								</Box>
								<Box className={classes.textField}>
									<InputLabel htmlFor="email"	>
										E-postadress
									</InputLabel>
									<OutlinedInput 
										id="email" 
										variant="outlined" 
										fullWidth
										name="email"
										placeholder="Ange din e-postadress"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										error={touched.email && Boolean(errors.email)}
									/>
									{!!touched.email && errors.email && (
										<FormHelperText error id="email">
											{errors.email}
										</FormHelperText>
									)}
								</Box>
								<Box className={classNames(classes.textField, classes.datePicker)}>
									<InputLabel htmlFor="birthday">
										Födelsedatum
									</InputLabel>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>									
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											name="date_of_birth"
											format="yyyy/MM/dd"
											margin="normal"
											id="date-picker-inline"	
											onChange={e=> {
												handleDateChange(e)
												setFieldValue('date_of_birth', moment(e).format('YYYY-MM-DD'))
											}}
											value={selectedDate}
											fullWidth
											placeholder="YYYY/MM/DD"
											inputVariant="outlined"
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>									
									</MuiPickersUtilsProvider>
									{!!touched.date_of_birth && errors.date_of_birth && (
										<FormHelperText error id="date_of_birth">
											{errors.date_of_birth}
										</FormHelperText>
									)}
								</Box>							
								<Box className={classes.textField}>
									<InputLabel htmlFor="password"	>
										Lösenord
									</InputLabel>
									<OutlinedInput 
										id="password" 
										name="password"
										variant="outlined" 
										fullWidth
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										error={touched.password && Boolean(errors.password)}
										placeholder="Ange ditt lösenord"
										inputProps={{
											type: "password"
										}}
										endAdornment={<InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>}
									/>	
									{!!touched.password && errors.password && (
										<FormHelperText error id="password">
											{errors.password}
										</FormHelperText>
									)}
								</Box>							
								<Box className={classes.submitBtn}>
									<LoadingButton type="submit" loading={loading} variant="contained" color="green" fullWidth onClick={handleSubmit}>
										Registrera
									</LoadingButton>
								</Box>
							</Form>
							
							<Typography variant="body1" className={classes.otherPageLink} align="center">
								Har redan konto ? <Link href="/" onClick={(e) => {e.preventDefault(); history.push('/login')}}>Logga in här</Link>
							</Typography>
						</Box>
					</Grid>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Box className={classes.rightSide}></Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default FormEnchancher(Register)
