import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL + '/md-locations/states'

export const getMdLocationStateService = props => {
    
    const {page, limit, keyword} = props
    
    return request({
        url: BASE_URL,
        queryParams : {
            page : page ?? 1,
            limit : limit ?? 10,
            keyword: keyword ?? '',
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const storeMdLocationStateService  = props => {
    return request({
        url: BASE_URL + '/store',
        body: props,
        method : 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getDetailMdLocationStateService = props => {

    const {id} = props

    return request({
        url: BASE_URL  + '/' + id + '/detail',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export  const updateMdLocationStateService = props => {
    return request({
        url: BASE_URL + '/' + props.id + '/update',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const destroyMdLocationStateService = props => {
    return request({
        url: BASE_URL + '/' + props.id + '/destroy',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}