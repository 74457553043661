import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import PopularCategoryItem from "components/PopularCategoryItem"

import styles from "assets/jss/views/landingViewStyle"

import { getCategoryService } from 'services/CategoryService'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const PopularCategorySection = () => {

    const classes = useStyles()  

    const [data, setData] = useState([])

    const history = useHistory()

    const getData = async() => {

        const res = await getCategoryService({
            limit :4,
            page: 1,
            is_popular : 1
        })

        setData(res?.data ?? [])
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Box className={classes.popularCategorySection}>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.titleSection}>
                        <Typography variant="h3" fontWeight={700} align="center">
                            Populära sökta kategorier
                        </Typography>
                        <Typography variant="body1" align="center">
                            {/* Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aliquet. */}
                        </Typography>
                    </Grid>
                        {
                            data.map((item) => (
                                <Grid onClick={() => history.push(`/all-product?category_id=${item.id}`)} item lg={3} md={3} sm={6} xs={6} key={item.id}>
                                    <PopularCategoryItem 
                                        
                                        categoryImg={item.image_path} 
                                        title={item.name} />                        
                                </Grid>
                            ))
                        }
                </Grid>
            </Container>
        </Box>
    )
}

export default PopularCategorySection
