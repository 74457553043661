import { Grid, Hidden } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from "assets/jss/views/staff/productViewStyle"
import TextField from 'components/CustomForms/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import products from './data/product'
import ProductItem from './components/ProductItem'
import CustomButton from 'components/CustomForms/Button'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import BackButton from 'components/BackButton'
import { getPaginateData } from 'views/Admin/Product/_redux/productCrud'
import { getTotalQuantity } from 'views/Market/Cart/_redux/cartAction'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles)

const Product = () => {


    const classes = useStyles()

    const history = useHistory()

    const cart = useSelector((state) => state.cart)

    const [totalPage, setTotalPage] = useState(1)
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState({
        limit: 10,
        page: 1,
        keyword: '',
        roles: 'customer'
    })

    const [products, setProducts] = useState([])

    const getProduct = async () => {
        try {
            const res = await getPaginateData(query)
            setProducts(res.data?.data)
            setTotalData(res.data?.total_data ?? 0)
            setTotalPage(res?.data?.total_page ?? 1)
        } catch (err) {

        }
    }

    const changeQuery = (props) => {
        setQuery({ ...query, ...props, page: 1 })
    }

    const changePage = (page) => {
        setLoading(true)
        setQuery({ ...setQuery, ...{ page: page } })
    }

    useEffect(() => {

        const delauyDebounceFn = setTimeout(() => {
            getProduct()

        }, 2000)

        return () => clearTimeout(delauyDebounceFn)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <BackButton onClick={() => history.push('staff/dashboard')} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <TextField
                            disableLable
                            inputProps={{
                                type: "text",
                                fullWidth: true,
                                placeholder: "Search Product",
                                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                                onChange: (e) => {changeQuery({keyword: e.target.value})}
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {products.map(product => (
                        <Grid item lg={6} md={6} sm={12} xs={12} key={product.id}>
                            <ProductItem product={product} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.footerButton}>
                <Grid container spacing={3}>
                    <Hidden smDown>
                        <Box flexGrow={1} />
                        <Grid item lg={2} md={2} sm={12} xs={12}>
                            <CustomButton variant="contained" fullWidth onClick={() => history.push('/staff/payment')} disabled={getTotalQuantity(cart.items) < 1} color="green">Kolla upp {getTotalQuantity(cart.items)}</CustomButton>
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomButton variant="contained" fullWidth onClick={() => history.push('/staff/payment')} disabled={getTotalQuantity(cart.items) < 1} color="green">Kolla upp {getTotalQuantity(cart.items)}</CustomButton>
                        </Grid>
                    </Hidden>

                </Grid>
            </Grid>
        </Grid>
    )

}

export default Product