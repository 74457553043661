import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import SearchIcon from '@material-ui/icons/Search';

import { Box, Button as UIButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from "components/CustomForms/Button"
import AddNewAddress from "./AddNewAddress"

import styles from "assets/jss/views/profileViewStyle.js"

const useStyles = makeStyles(styles)

const BillingInfoPanel = () => {

  const classes = useStyles()

  const [openAddAddress, setOpenAddAddress] = useState(false)

  return (
    <>
      <Box className={classes.billingInfoSection}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" className={classes.searchForm}>
                <OutlinedInput                                               
                  endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"             
                  labelWidth={0}
                  placeholder="Search Address"
                />
                <Hidden smDown>
                  <Button variant="contained" color="green" onClick={() => setOpenAddAddress(true)}>Add New Address</Button>
                </Hidden>              
              </Box>
            </Grid>
            
            <Grid item lg={4} md={4} sm={12} xs={12} className={classes.billingInfoCol}>
              <Box className={classes.billingInfoField} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">
                  Billing Information 2
                </Typography>
                <Box className={classes.badge}>
                  <Typography variant="body1">
                    primary
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body1" className={classes.textWhite}>
                Noah J Öberg
              </Typography>
              <Typography variant="body1">
                +1234567890
              </Typography>
              <Typography variant="body1">
                Västergatan 31
              </Typography>
              <Typography variant="body1">
                Malmö, 211 21 
              </Typography>
              <Box display="flex" justifyContent="space-around" className={classes.billingActions}>
                <UIButton className={classes.billingDeleteBtn}>
                  Delete
                </UIButton>
                <UIButton className={classes.billingUpdateBtn}>
                  Uppdatering
                </UIButton>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className={classes.billingInfoCol}>
              <Box className={classes.billingInfoField} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">
                  Billing Information 3
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.textWhite}>
                Noah J Öberg
              </Typography>
              <Typography variant="body1">
                +1234567890
              </Typography>
              <Typography variant="body1">
                Västergatan 31
              </Typography>
              <Typography variant="body1">
                Malmö, 211 21 
              </Typography>
              <Box display="flex" justifyContent="space-around" className={classes.billingActions}>
                <UIButton className={classes.billingDeleteBtn}>
                  Delete
                </UIButton>
                <UIButton className={classes.billingUpdateBtn}>
                  Uppdatering
                </UIButton>
              </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className={classes.billingInfoCol}>
              <Box className={classes.billingInfoField} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">
                  Billing Information 3
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.textWhite}>
                Noah J Öberg
              </Typography>
              <Typography variant="body1">
                +1234567890
              </Typography>
              <Typography variant="body1">
                Västergatan 31
              </Typography>
              <Typography variant="body1">
                Malmö, 211 21 
              </Typography>
              <Box display="flex" justifyContent="space-around" className={classes.billingActions}>
                <UIButton className={classes.billingDeleteBtn}>
                  Delete
                </UIButton>
                <UIButton className={classes.billingUpdateBtn}>
                  Uppdatering
                </UIButton>
              </Box>
            </Grid>          
          </Grid>
        </Container>
      </Box>
      <Hidden mdUp>
        <Box mt={3}>
          <Button variant="contained" color="green" fullWidth onClick={() => setOpenAddAddress(true)}>
            Add New Address
          </Button>
        </Box>
      </Hidden>
      <AddNewAddress 
        open={openAddAddress}
        closeModal={() => setOpenAddAddress(false)}
      />
    </>
  )
}

export default BillingInfoPanel
