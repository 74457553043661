import produce from "immer"

export const addItemToCart = (state, payload) => {
    const existingCartItemIndex = state.items.findIndex(
        (item) => item.id === payload.id
    )

    if(existingCartItemIndex > -1) {
        const newState = produce(state.items, drafState => {
            drafState[existingCartItemIndex].quantity += payload.quantity
        })
        // newState[existingCartItemIndex].quantity += payload.quantity
        return newState
    }else{
        return [...state.items, payload]
    }
    
}

export const removeItemFromCart = (state, payload) => {
    return state.items.reduce((acc, item) => {
        if(item.id === payload.id) {
            const newQuantity = item.quantity - payload.quantity;

            return newQuantity > 0
                ? [...acc, {...item, quantity : newQuantity}]
                : [...acc]
        }

        return [...acc, item]
    }, [])
}

export const clearItemFromCart = (state, payload) => {
    return state.items.filter((item) => item.id !== payload.id)
}

export const getTotalQuantity = (state) => {
    return state.reduce((prev, next) => prev + next.quantity, 0)
}

export const getTotalPrice = (state) => {
    return state.reduce((prev, next) => prev + (next.quantity * next.selling_price), 0)
}