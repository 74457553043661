import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getNotificationForUserService = props => {
    
    const {page, limit, keyword} = props
    
    return request({
        url: BASE_URL + '/notification/get-for-user',
        queryParams : {
            page : page ?? 1,
            limit : limit ?? 10,
            keyword: keyword ?? '',
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getNotificationService = props => {
    
    const {page, limit, keyword} = props
    
    return request({
        url: BASE_URL + '/notification',
        queryParams : {
            page : page ?? 1,
            limit : limit ?? 10,
            keyword: keyword ?? '',
        }
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const storeNotificaitonService  = props => {
    return request({
        url: BASE_URL + '/notification',
        body: props,
        method : 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const markAllNotificationService = () => {
    return request({
        url: BASE_URL + '/notification/mark-all-as-readed',
        method: 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message});
}