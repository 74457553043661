import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'

import styles from "assets/jss/components/faqItemStyle"

const useStyles = makeStyles(styles)

const FaqItem = (props) => {

	const classes = useStyles()

	const { faq } = props

	const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
		<Box>
			<Card className={clsx(classes.faq, {
				[classes.nonBorder]: !expanded
			})}>            		
				<CardActions disableSpacing className={clsx(classes.header, {
					[classes.textWhite]: !expanded
				})}>
					<Typography variant="body2" color="textSecondary" component="p">
						{faq.question}
					</Typography>
					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</IconButton>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent className={classes.content}>	
						<Typography paragraph>
							{faq.answer}
						</Typography>									
					</CardContent>
				</Collapse>
			</Card>
		</Box>
	)
}

export default FaqItem
