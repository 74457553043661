import { Grid, Hidden } from '@material-ui/core'
import AdminViewTitles from 'components/Breadcrumb/AdminViewTitles'
import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import styles from 'assets/jss/views/admin/notificationViewStyle'
import TextField from 'components/CustomForms/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { getNotificationForUserService } from 'services/NotificationService'
import { useState } from 'react'
import { markAllNotificationService } from 'services/NotificationService'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { useDispatch } from 'react-redux'
import { getUserMeService } from 'services/UserService'
import { DateRange } from '@material-ui/icons'

const useStyles = makeStyles(styles)

const DeliverySVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <circle id="base" cx="20" cy="20" r="20" fill="#ff4b4b" />
        <g id="icon_filled_16px_delivery" data-name="icon / filled / 16px / delivery" transform="translate(8 8)">
            <path id="Vector" d="M22.466,6.532c-.075-.016-.146-.031-.21-.047-.717-.186-1.044-.289-1.286-.756l-.917-1.856a2.1,2.1,0,0,0-1.7-1.032H16.725V.7a.7.7,0,0,0-.7-.7L4.66.011A.661.661,0,0,0,4,.7v.633H1.091a1.091,1.091,0,0,0,0,2.181H4V4.564H2.1a1.091,1.091,0,0,0,0,2.181H4V7.79H3.1a1.091,1.091,0,1,0,0,2.181H4v1.093a.7.7,0,0,0,.7.7H5.883a2.614,2.614,0,0,0,5.172,0h4.785c.025,0,.05,0,.076,0a2.614,2.614,0,0,0,5.173,0h1.7A1.214,1.214,0,0,0,24,10.55V8.493C24,6.854,23.077,6.66,22.466,6.532Zm-3.963,3.9a.95.95,0,1,1-.95.95A.951.951,0,0,1,18.5,10.434Zm-1.778-4.05V3.853h1.247a1.621,1.621,0,0,1,1.29.783l.816,1.652q.026.05.054.1H16.725Zm-7.306,5a.95.95,0,1,1-.95-.95A.951.951,0,0,1,9.419,11.384Z" transform="translate(0 5.001)" fill="#fff" />
        </g>
    </svg>
)

const MessageSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="icon" transform="translate(-16 -33.5)">
            <circle id="base" cx="20" cy="20" r="20" transform="translate(16 33.5)" fill="#fe9f00" />
            <g id="icon_filled_16px_chat" data-name="icon / filled / 16px / chat" transform="translate(24 41.5)">
                <g id="Group">
                    <g id="Group-2" data-name="Group">
                        <path id="Vector" d="M12.375,0H2.25A2.252,2.252,0,0,0,0,2.25V9a2.252,2.252,0,0,0,2.25,2.25v2.813a.563.563,0,0,0,.928.428l3.78-3.24h5.417A2.252,2.252,0,0,0,14.625,9V2.25A2.252,2.252,0,0,0,12.375,0Z" transform="translate(3 3)" fill="#fff" />
                    </g>
                </g>
                <g id="Group-3" data-name="Group">
                    <g id="Group-4" data-name="Group">
                        <path id="Vector-2" data-name="Vector" d="M10.85,0V5.625A3.379,3.379,0,0,1,7.475,9h-5L0,11.12a2.221,2.221,0,0,0,.725.131H6.141L9.92,14.49a.566.566,0,0,0,.367.135.554.554,0,0,0,.235-.052.56.56,0,0,0,.327-.511V11.25A2.252,2.252,0,0,0,13.1,9V2.25A2.252,2.252,0,0,0,10.85,0Z" transform="translate(7.901 6.375)" fill="#fff" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

const OnlineSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <circle id="read" cx="6" cy="6" r="6" fill="#1db954" />
    </svg>
)

const DesktopListItemNotif = (props) => {
    const { item, onClick } = props

    return (
        <Grid container alignItems='center' style={{ marginBottom: 10 }} onClick={onClick}>
            <Grid item lg={1} md={1} sm={1} xs={1} style={{ textAlign: 'center', minWidth: 40 }}>
                {item.type === 'delivery' && DeliverySVG}
                {item.type === 'notification' && MessageSVG}
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
                <div style={{ color: '#FFF', fontSize: 16 }}>{item.title}</div>
                <div style={{ color: '#7E8184', fontSize: 16, marginBottom: 5 }}>{item.content}</div>
                {/* <div style={{ color: '#7E8184', fontSize: 16 }}>{moment(item.created_at).format()}</div> */}
            </Grid>
            <Box flexGrow={1} />
            {item.is_readed === 0 && (
                <Grid item lg={1} md={1} sm={1} xs={1} style={{ textAlign: 'center' }}>
                    {OnlineSVG}
                </Grid>
            )}
            
        </Grid>
    )
}


const Notification = (props) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const [notificationData, setNotificationData] = useState([])
    const [loadingAction, setLoadingAction] = useState(false)
    const [query, ] = useState({
        page: 1,
        limit : 1000,
    })
    const getData = async() => {
        
        const data = await getNotificationForUserService(query)

        setNotificationData(data.data)

    }

    const updateUserDetailRedux = async() => {
        const data = await getUserMeService()
        console.log(data)
        dispatch({type : 'UPDATE_USER', payload : data})
    }

    const marksAllIsReadAction = async() => {
        try{
            setLoadingAction(true)
            await markAllNotificationService()
            await getData()
            await updateUserDetailRedux()
            setLoadingAction(false)
        }catch(err) {
            setLoadingAction(false)
        }
        
        
    }

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <AdminViewTitles mainTitle="All anmälan" subTitle="" />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <Box className={classes.listWrapper}>
                    <Box className={classes.filterWrapper}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} md={3} sm={12} xs={12} className={classes.searchFormWrapper}>
                                <TextField
                                    disableLabel
                                    className={classes.searchForm}
                                    inputProps={{
                                        type: "text",
                                        fullWidth: true,
                                        placeholder: "All Datum",
                                        endAdornment: <InputAdornment position="end"><DateRange /></InputAdornment>,
                                        onChange: () => { }
                                    }}
                                />
                            </Grid>
                            <Hidden smDown>
                                <Box flexGrow={1} />
                            </Hidden>
                            <Hidden smDown>
                                <Grid item lg={2} md={3} sm={12} xs={12}>
                                    <LoadingButton variant="contained" color="green" onClick={marksAllIsReadAction} loading={loadingAction} fullWidth>
                                        Markera allt som läst
                                    </LoadingButton>
                                </Grid>
                            </Hidden>
                            {notificationData.map((item, index) => (
                                <DesktopListItemNotif
                                    key={index}
                                    item={item}
                                    onClick={() => null}
                                />
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Hidden mdUp>
                <Grid item sm={12} xs={12}>
                    <Box mt={3}>
                        {/* <CustomMobilePagination /> */}
                    </Box>
                    <Box mt={2}>
                        <LoadingButton variant="contained" color="green" onClick={marksAllIsReadAction} loading={loadingAction} fullWidth >
                            Mark all as read
                        </LoadingButton>
                    </Box>
                </Grid>
            </Hidden>
        </Grid>
    )

}

export default Notification