import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Button from "components/CustomForms/Button"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/components/customModalStyle"
import { getUserService } from 'services/UserService'
import { useState } from 'react'
import LoadingButton from 'components/CustomForms/LoadingButton'

const useStyles = makeStyles(styles)

const SetDeliveryModal = (props) => {

  const classes = useStyles()

  const { open, closeModal, handleOnSetDelivery, loading } = props
  const [data, setData] = useState([])
  const getData = async() => {
    const res = await getUserService({
      keyword : '',
      limit: 100,
      page : 1,
      roles : 'staff'
    })
    setData(res?.data)
  }

  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    getData()
  }, [])

  return (
    <CustomModal open={open} closeModal={closeModal} size="small">
      <ModalHeader title="Setleverans" closeModal={closeModal} />
      <Typography variant="body1" align="center" className={classes.subTitle}>
      </Typography>
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={10} md={10} sm={10} xs={10} className={classes.selectWrapper}>
              <Select                
                className={classes.customSelect}
                fullWidth
                defaultValue="Choose Staff"
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <MenuItem value="">
                  Välj personal
                </MenuItem>
                {
                  data.map((item) => (
                    <MenuItem value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>            
          </Grid>
        </Container>              
      </ModalContents>
      <Box mt={2}>
        <Box className={classes.modalFooter} textAlign="center">
          <Button className={classes.cancelBtn} onClick={ closeModal }>
            Annullera
          </Button>
          <LoadingButton loading={loading} variant="contained" color="green" onClick={() => handleOnSetDelivery(selectedValue) }>
            Sparaa
          </LoadingButton>
        </Box>
      </Box>
    </CustomModal>
  )
}

export default SetDeliveryModal
