import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL + '/md-locations/cities'

export const getMdLocationCityService = props => {
    
    return request({
        url: BASE_URL,
        queryParams : props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const storeMdLocationCityService  = props => {
    return request({
        url: BASE_URL + '/store',
        body: props,
        method : 'POST'
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const getDetailMdLocationCityService = props => {

    const {id} = props

    return request({
        url: BASE_URL  + '/' + id + '/detail',
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export  const updateMdLocationCityService = props => {
    return request({
        url: BASE_URL + '/' + props.id + '/update',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}

export const destroyMdLocationCityService = props => {
    return request({
        url: BASE_URL + '/' + props.id + '/destroy',
        method: 'POST',
        body: props
    })
    .then(res => res.data)
    .then(res => res)
    .catch(err => {throw err.message})
}