import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, FormLabel } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"
import RadioCard from "components/RadioCard"
import CustomDateField from "components/CustomForms/CustomDateField"

import styles from "assets/jss/views/admin/discountViewStyle"
import currency from 'utils/currency'

import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import moment from 'moment'
import { storeVoucherService, updateVoucherService } from 'services/VoucherService'

const useStyles = makeStyles(styles)


const FormStoreSchema = Yup.object().shape({
  name: Yup.string()
    .max(191, 'maximalt antal tecken 191')
    .required('Namn krävs'),
  voucher_code: Yup.string()
    .min(8, 'Too Short!')
    .max(191, 'Too Long!')
    .required('Requeired!'),
  amount_type: Yup.string()
    .required('Required!'),
  amount: Yup.number()
    .min(1)
    .required('Required !!'),
  start_date: Yup.date()
    .required(),
  end_date: Yup.date()
    .nullable(),
  quota: Yup.number()
    .min(1)
    .required(),
  description: Yup.string()
    .nullable(),
  min_order: Yup.number()
    .min(0)
    .notRequired()
    .nullable()
})

const FormUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .max(191, 'maximalt antal tecken 191')
    .required('Namn krävs'),
  voucher_code: Yup.string()
    .min(8, 'Too Short!')
    .max(191, 'Too Long!')
    .required('Requeired!'),
  amount_type: Yup.string()
    .required('Required!'),
  amount: Yup.number()
    .min(1)
    .required('Required !!'),
  start_date: Yup.date()
    .required(),
  end_date: Yup.date()
    .nullable(),
  quota: Yup.number()
    .min(1)
    .required(),
  description: Yup.string()
    .nullable(),
  min_order: Yup.number()
    .notRequired()
    .nullable()
})

const FormEnchanher = withFormik({
  mapPropsToValues: (props) => ({
    id: props.data.id || null,
    name: props.data.name || '',
    voucher_code: props.data.voucher_code || '',
    amount_type: props.data.amount_type || 'fixed',
    amount: props.data.amount || 1,
    start_date: props.data.start_date || null,
    end_date: props.data.end_date || null,
    quota: props.data.quota || 0,
    description: props.data.description || '',
    min_order: props.data.min_order || 0
  }),
  validationSchema: props => props.data.id ? FormUpdateSchema : FormStoreSchema,
  enableReinitialize: true,
  handleSubmit: (values) => {

  },
  isInitialValid: props => props.data.id ? FormUpdateSchema.isValidSync(props.data) : FormStoreSchema.isValidSync(props.data)
})

const EditDiscount = (props) => {

  const classes = useStyles()

  const {
    open,
    closeModal,
    data,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue,
    handleReset
  } = props

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)


  const storeOrUpdate = async () => {
    try {
      if (!values.id) {
        await storeVoucherService(values)
      } else {
        await updateVoucherService(values)
      }
    } catch (err) {
      throw err
    }
  }

  const handleSubmit = async () => {

    if (isValid) {
      try {
        setLoading(true)
        await storeOrUpdate()
        setTimeout(() => {

          setLoading(false)
          // setIsNew(true)
          setErrorMessage('')
          closeModal()
          handleReset()
        }, 3000)
      } catch (err) {
        setErrorMessage(err)
      }
    }

  }

  return (
    <CustomModal open={open} closeModal={closeModal}>
      <Form>
        <ModalHeader title="Edit Discount" closeModal={closeModal} />
        <ModalContents>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  labelText="Namnkupong"
                  error={touched.name && errors.name}
                  inputProps={{
                    id: 'name',
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ingångskupongnamn",
                    defaultValue: "",
                    onChange: handleChange,
                    handleBlur: handleBlur,
                    name: 'name',
                    value: values.name
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  labelText="Rabattkod"
                  error={touched.voucher_code && errors.voucher_code}
                  inputProps={{
                    id: 'voucher_code',
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ingångskupongkod",
                    defaultValue: "",
                    onChange: handleChange,
                    handleBlur: handleBlur,
                    name: 'voucher_code',
                    value: values.voucher_code
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.dateField}>
                <Typography variant="body1" className={classes.radioLabel}>
                  Början av rabattperioden<span>*</span>
                </Typography>
                <CustomDateField
                  id="start_date"
                  name="start_date"
                  handleBlur={handleBlur}
                  value={moment(values.start_date, 'YYYYY/MM/DD').format()}
                  handleChange={(e) => {
                    if (e) {
                      if (moment(e).isValid()) {
                        setFieldValue('start_date', e)
                      }

                    } else {
                      setFieldValue({ start_date: null })
                    }
                  }}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.dateField}>
                <Typography variant="body1" className={classes.radioLabel}>
                  Slut på rabattperioden<span>*</span>
                </Typography>
                <CustomDateField
                  id="end_date"
                  name="end_date"
                  handleBlur={handleBlur}
                  value={moment(values.end_date, 'YYYYY/MM/DD').format()}
                  handleChange={(e) => {
                    if (e) {
                      if (moment(e).isValid()) {
                        setFieldValue('end_date', e)
                      }

                    } else {
                      setFieldValue({ start_date: null })
                    }
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" className={classes.radioLabel}>
                  Återbetalningstyp {values.amount_type}<span>*</span>
                </Typography>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Välj mängd typ</FormLabel>
                  <RadioGroup
                    name="amount_type"
                    value={values.amount_type}
                    id="amount_type"
                    handleBlur={handleBlur}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setFieldValue('amount_type', e.target.value)
                    }}>
                    <Grid container className={classes.methodContainer}>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <RadioCard value='fixed' label='Fast' small selected={values.amount_type === "fixed"} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <RadioCard value='percentage' label='Procentsats' small selected={values.amount_type === "percentage"} />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Belopp"
                  className={classes.textFieldWithStart}
                  error={touched.amount && errors.amount}
                  inputProps={{
                    id: 'amount',
                    name: 'amount',
                    type: "text",
                    fullWidth: true,
                    placeholder: "Ingående belopp",
                    defaultValue: values.amount,
                    handleBlur: handleBlur,
                    startAdornment: <InputAdornment position="start"><Button>{currency}</Button></InputAdornment>,
                    onChange: handleChange
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Minsta beställning"
                  className={classes.textFieldWithStart}
                  error={touched.min_order && errors.min_order}
                  inputProps={{
                    type: "number",
                    name: "min_order",
                    id : "min_order",
                    fullWidth: true,
                    placeholder: "Ingående minimum",
                    defaultValue: "",
                    value: values.min_order,
                    startAdornment: <InputAdornment position="start"><Button>{currency}</Button></InputAdornment>,
                    onChange: handleChange,
                    handleBlur: handleBlur
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  labelText="Kupongkvot"
                  error={touched.quota && errors.quota}
                  inputProps={{
                    type: "number",
                    id: "quota",
                    name: "quota",
                    fullWidth: true,
                    placeholder: "Kupongkvot",
                    defaultValue: "",
                    handleBlur: handleBlur,
                    value: values.quota,
                    onChange: handleChange
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  labelText="Beskrivning"
                  error={touched.description && errors.description}
                  inputProps={{
                    type: "text",
                    id: "description",
                    name: "description",
                    fullWidth: true,
                    placeholder: "Ingångskupongbeskrivning",
                    defaultValue: "",
                    onChange: handleChange,
                    handleBlur: handleBlur,
                    value: values.description,
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </ModalContents>
        <Box className={classes.modalFooter} textAlign="right">
          <Button variant="simple" onClick={closeModal}>
            Annullera
          </Button>
          <Button variant="contained" type="submit" color="green" onClick={handleSubmit}>
            Spara
          </Button>
        </Box>
      </Form>
    </CustomModal>
  )
}

export default FormEnchanher(EditDiscount)
