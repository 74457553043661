import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

import { Box, Hidden, Button, FormHelperText } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import CustomTextField from "components/CustomForms/TextField"
import * as Yup from 'yup'

import styles from "assets/jss/views/admin/addBlogViewStyle"
import { Form, withFormik } from 'formik'
import { useSelector } from 'react-redux'
import LoadingButton from 'components/CustomForms/LoadingButton'
import { updateBlogService } from 'services/BlogService'
import { storeBlogService } from 'services/BlogService'
import { getBlogDetailService } from 'services/BlogService'
import { Switch } from 'formik-material-ui'


const useStyles = makeStyles(styles)

const BASE_URL = process.env.REACT_APP_BASE_URL

const BlogSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    title: Yup.string()
        .max(191)
        .required(),
    slug: Yup.string()
        .nullable(),
    meta_title: Yup.string()
        .nullable(),
    summary: Yup.string()
        .nullable(),
    banner_url: Yup.string()
        .required('Banner krävs!'),
    content: Yup.string()
        .required(),
    is_published: Yup.bool()
        .notRequired()

})

const BlogProps = {
    id: null,
    title: '',
    slug: '',
    meta_title: '',
    summary: '',
    banner_url: '',
    content: '',
    is_published: false
}

const FormEnchancer = withFormik({
    mapPropsToValues: (props) => ({
        id: props.blog?.id || null,
        title: props.blog?.title || '',
        slug: props.blog?.slug || '',
        meta_title: props.blog?.meta_title || '',
        summary: props.blog?.summary || '',
        banner_url: props.blog?.banner_url || '',
        content: props.blog?.content || '',
        is_published: props.blog?.is_published || false
    }),
    validationSchema: BlogSchema,
    enableReinitialize: true,
    handleSubmit: (values) => {

    },
    isInitialValid: props => BlogSchema.isValidSync(BlogProps),
})

const AddBlog = props => {

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        setFieldValue,
    } = props

    const classes = useStyles()

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()

    const { slug } = params
    const uploadFile = () => {
        document.getElementById("upload-banner-img").click()
    }

    const user = useSelector(item => item.user)

    const uploadImage = (e) => {

        const formData = new FormData()
        formData.append('file', e.target.files[0])
        formData.append('path', 'blogs')
        fetch(BASE_URL + '/file/upload-image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token,
            },
            body: formData
        })
            .then(res => res.json())
            .then(res => {
                setFieldValue('banner_url', res.data.image_name)
            })
            .catch((err) => setErrorMessage(err.message))
    }

    const handleSubmit = async () => {
        if (isValid) {
            try {
                setLoading(true)
                if (slug) {
                    await updateBlogService(values)
                } else {
                    await storeBlogService(values)
                }

                setTimeout(() => {
                    setLoading(false)

                    history.replace('/admin/blog')
                }, 3000)


            } catch (error) {
                setLoading(false)
                setErrorMessage(error)
            }
        }
    }

    const getData = async () => {
        try {
            const data = await getBlogDetailService({ slug: slug })
            setFieldValue('id', data.id)
            setFieldValue('banner_url', data.banner_url)
            setFieldValue('slug', data.slug)
            setFieldValue('meta_title', data.meta_title)
            setFieldValue('summary', data.summary)
            setFieldValue('content', data.content)
            setFieldValue('title', data.title)
            setFieldValue('is_published', data.is_published)

        } catch (error) {

        }
    }

    useEffect(() => {
        if (slug) {
            getData(slug)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container >
            <Form>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button startIcon={<ArrowBackIcon />} className={classes.backBtn} onClick={() => history.push('/admin/blog')}>
                        Tillbaka
                    </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.contentSection}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h3">
                                Lägg till blogginlägg
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="body1" className={classes.bannerLabel}>
                                Bannerbild<span>*</span>
                            </Typography>
                            <Box display="flex">
                                {
                                    values.banner_url && (
                                        <Box className={classes.imageBanner}>
                                            <img src={values.banner_url} alt="Product" width="100%" height="100%" />
                                        </Box>
                                    )
                                }


                                <Box className={classes.uploadField} position="relative">
                                    <input type="file" id="upload-banner-img" onChange={uploadImage} />
                                    <Box position="absolute" textAlign="center" onClick={uploadFile}>
                                        <CloudUploadOutlinedIcon />
                                        <Typography variant="body1" align="center">
                                            Ladda upp bilder
                                        </Typography>
                                    </Box>
                                    {touched.images && errors.images && (
                                        <FormHelperText error id="password">
                                            {errors.images}
                                        </FormHelperText>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <CustomTextField
                                labelText="Bloggtitel"
                                error={touched.title && errors.title}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Bloggtitel",
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.title,
                                    name: "title",
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <CustomTextField
                                labelText="Slug"
                                error={touched.Slug && errors.Slug}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Url Design eg : lorem-ipsum",
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.slug,
                                    name: "slug",
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CustomTextField
                                labelText="Innehåll"
                                error={touched.content && errors.content}
                                inputProps={{
                                    type: "text",
                                    fullWidth: true,
                                    placeholder: "Innehåll",
                                    multiline: true,
                                    rows: 6,
                                    defaultValue: '',
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: values.content,
                                    name: "content",
                                }}
                            />
                        </Grid>
                        <Hidden smDown>
                            <Grid item lg={12} md={12} sm={12}>
                                <Box textAlign="right" mt={3}>
                                    <Button className={classes.cancelBtn}>
                                        Annullera
                                    </Button>
                                    <LoadingButton type="submit" loading={loading} variant="contained" color="green" onClick={handleSubmit}>
                                        Spara
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden mdUp>
                    <Grid item sm={6} xs={6}>
                        <Button className={classes.cancelBtn} fullWidth>
                            Annullera
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <LoadingButton type="submit" loading={loading} variant="contained" color="green" fullWidth onClick={handleSubmit}>
                            Spara
                        </LoadingButton>
                    </Grid>
                </Hidden>
                {/* <ConfirmModal 
        open={openConfirmModal} 
        closeModal={() => setOpenConfirmModal(false)} 
        text={
          <Typography variant="body1" align="center">
            Are you sure want to add product “<span>{values.name}</span>”?
          </Typography>
        }
        colorVariant="success"
        loading={loading}
        title="Save"
        confirmAction={() => setOpenConfirmModal(false)}
      /> */}
            </Form>
        </Grid>
    )
}

export default FormEnchancer(AddBlog)
