import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FaqItem from "components/FaqItem"

import styles from "assets/jss/views/landingViewStyle"
import { getFaqService } from 'services/FaqService'

const useStyles = makeStyles(styles)

const FAQSection = () => {

	const classes = useStyles() 
	
	const [data, setData] = useState([])
	const getData = async() => {
		try{
		  const res = await getFaqService({
			page: 1,
			limit: 100,
			keyword : '',
			is_published : 1
		  })
		  setData(res?.data ?? [])
		}catch(err) {
		  console.log(err)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
			<Box className={classes.faqSection}>
				<Container maxWidth="xl">
					<Grid container spacing={3}>
						<Grid item xs={12} className={classes.titleSection}>
							<Typography variant="h3" fontWeight={700} align="center">
								Vanliga frågor
							</Typography>
							<Typography variant="body1" align="center">
								{/* Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. */}
							</Typography>
						</Grid>
						<Grid item xs={12} className={classes.faqList}>
							<Grid container>
							{
								data.map((item) => (
									<Grid item lg={6} md={6} sm={12} xs={12}>
										<FaqItem faq={item} />
									</Grid>
								))
							}
							</Grid>
						</Grid>
						
					</Grid>
				</Container>
			</Box>
	)
}

export default FAQSection
