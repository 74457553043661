import { Box } from "@material-ui/core"

const ForbiddenAccess = () => {
    return(
        <Box>
            Forbidden Access
        </Box>
    )
}

export default ForbiddenAccess