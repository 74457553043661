import request from "utils/request"

const BASE_URL = process.env.REACT_APP_BASE_URL

export const orderPayService = props => {
    const { body } = props
    return request({
        url: BASE_URL + '/order/pay',
        method: 'POST',
        body: body
    })
        // .then(res => res.data)
        .then(res => res)
        .catch(err => err)
}