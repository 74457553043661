import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, Hidden } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Pagination from '@material-ui/lab/Pagination'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import styles from "assets/jss/components/customPaginationStyle"

const useStyles = makeStyles(styles)

const ProductCustomPagination = (props) => {

  const {pageCount, currentPage, totalData, currentLimit, changeCurrentLimit, changePage} = props

  const classes = useStyles()

  // const { state, apiRef } = useGridSlotComponentProps();

  // const [rows, setRows] = useState(10)

  return (
    <Box display="flex" justifyContent="space-between" className={classes.custompagination} width="100%">
      <Hidden smDown>
        <Box display="flex" alignItems="center">
          <Typography variant="body1">
            Data per page :
          </Typography>
          <Select          
            id="select"
            className={classes.rowsPerPage}
            value={currentLimit ?? 0}
            onChange={(e) => changeCurrentLimit(e.target.value)}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
          <Typography variant="body1">
            {(currentLimit * currentPage) - currentLimit + 1} - {currentLimit * currentPage} from {totalData} 
          </Typography>
        </Box>
      </Hidden>      
      <Pagination 
        // count={state.pagination.pageCount}
        // page={state.pagination.page + 1}
        // onChange={(event, value) => apiRef.current.setPage(value - 1)}
        onChange={(event, value) => changePage(value)}
        page={currentPage}
        count={pageCount}
        boundaryCount={3}
        variant="outlined" 
        shape="rounded" 
        size="medium"
        className={classes.pagination} 
      />
    </Box>
  )
}

export default ProductCustomPagination
