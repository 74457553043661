import { ArrowBackIos } from '@material-ui/icons'
import React from 'react'

const BackButton = (props) => {

    const { onClick } = props

    return (
        <div
            style={{
                color: '#1DB954',
                fontSize: '1.5rem',
                marginBottom: '0.5rem',
                display: 'flex',
                alignItems: 'center'
            }}
            onClick={onClick}
        >
            <ArrowBackIos /> <span style={{ marginLeft: '10px' }}>Back</span>
        </div>
    )
}

export default BackButton