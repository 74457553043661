import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Button from "components/CustomForms/Button"

import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"

import styles from "assets/jss/components/receivedOrderModalStyle"
import moment from 'moment'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles(styles)

const ReceivedOrderModal = (props) => {

  const classes = useStyles()
  const history = useHistory()

  const { open, closeModal, data } = props

  return (
    <CustomModal open={open} closeModal={closeModal}>
      <ModalHeader title="Tack! Din beställning har mottagits" closeModal={closeModal} />
      <ModalContents>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={12} className={classNames(classes.contentBlock, classes.invoiceSection)}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box className={classes.invoiceLine}>
                    <Typography variant="body1">
                      Fakturanummer
                    </Typography>
                    <Typography variant="body1">
                      {data.invoice_number}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box className={classes.invoiceLine}>
                    <Typography variant="body1">
                      Datum
                    </Typography>
                    <Typography variant="body1">
                      {moment(data.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                  </Box>
                </Grid>
                <Hidden smDown>
                  <Grid item lg={4} md={4}>
                    <Box>
                      <Typography variant="body1">
                        Total betalning
                      </Typography>
                      <Typography variant="body1">
                        {data.grand_total}
                      </Typography>
                    </Box>
                  </Grid>
                </Hidden>

              </Grid>
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.orderListSection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Orderlista
              </Typography>
              {data.transaction_items.map(item => (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.orderLine}>
                    <Box>
                      <Typography variant="body1">
                        {item.product_variant.product_name}
                      </Typography>
                      <Typography variant="body1">
                        {numberFormat(item.quantity)} X {numberFormat(item.selling_price_per_item)} {currency}
                      </Typography>
                    </Box>
                    <Typography variant="body1">
                      {numberFormat(item.total)} {currency}
                    </Typography>
                  </Box>
                  <Divider className={classes.divider} />
                </>
              ))}
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.billingInfoSection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Faktureringsinformation
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingLeft}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Frakt Metod
                    </Typography>
                    <Typography variant="body1">
                      Typ {data.shipping_method?.id}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Spårningsnummer
                    </Typography>
                    <Typography variant="body1">
                      {data.tracking_number}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                      Notes
                    </Typography>
                    <Typography variant="body1">
                      {data.recepient_notes ?? '-'}
                    </Typography>
                  </Box>
                </Grid>

                <Hidden mdUp>
                  <Grid item xs={12} className={classes.setPadding}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Hidden>

                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.billingRight}>
                  <Typography variant="body1">
                    {data.recepient_name}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_email}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_phone}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_street_address}
                  </Typography>
                  <Typography variant="body1">
                    {data.recepient_street_number ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classNames(classes.contentBlock, classes.summarySection)}>
              <Typography variant="body1" className={classes.blockTitle}>
                Ordersammanfattning
              </Typography>
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Betalningsmetod
                </Typography>
                <Typography variant="body1">
                  {data.payment?.name}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Delsumma
                </Typography>
                <Typography variant="body1">
                  {data.transaction_items.length > 0 ? numberFormat(data.transaction_items.reduce((prev, next) => prev + next.selling_price_per_item, 0)) : 0} {currency}
                </Typography>
              </Box>
              {
                data.voucher_discount_amount > 0 && (
                  <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                    <Typography variant="body1">
                      Discount
                    </Typography>
                    <Typography variant="body1">
                      {numberFormat(data.voucher_discount_amount)} {currency}
                    </Typography>
                  </Box>
                )
              }
              <Box display="flex" justifyContent="space-between" className={classes.summaryLine}>
                <Typography variant="body1">
                  Fraktkostnad
                </Typography>
                <Typography variant="body1">
                  {numberFormat(data.shipping_price)} {currency}
                </Typography>
              </Box>
              <Divider className={classes.divider} />
              <Box display="flex" justifyContent="space-between" className={classes.totalPayment}>
                <Typography variant="body1">
                  Total betalning
                </Typography>
                <Typography variant="body1">
                  {numberFormat(data.grand_total)} {currency}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ModalContents>
      <Box className={classes.footer} textAlign="right">
        <Button variant="contained" color="green" onClick={() => history.replace('/')}>
          Tillbaka till hemmet
        </Button>
        {/* {data.status === 'pending' && data.payment.slug === 'stripe-cc' && (
          <Button variant="contained" color="green" onClick={() => history.replace(`/card-payment/${data.id}`)}>
            Betala
          </Button>
        )}

        {data.status === 'pending' && data.payment.slug === 'swish_m_commerce' && (
          <Button variant="contained" color="green" onClick={() => history.replace(`/swish-payment/${data.id}`)}>
            Betala
          </Button>
        )} */}
      </Box>
    </CustomModal>
  )
}

export default ReceivedOrderModal
