import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import DeleteIcon from '@material-ui/icons/Delete'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomForms/Button"

import styles from "assets/jss/components/cartItemStyle"
import { useDispatch } from 'react-redux'
import numberFormat from 'utils/number-format'
import currency from 'utils/currency'

const useStyles = makeStyles(styles)

const CartItem = (props) => {

  const classes = useStyles()

  const { cartItem } = props

  const [count, setCount] = useState()

  const dispacth = useDispatch()


  return (
    <Box className={classes.cartItem + " " + "cartItem"} display="flex">
      <Box className={classes.product} display="flex">
        <Hidden smDown>
          <IconButton
            onClick={e => {
              e.preventDefault()
              dispacth({type : 'CLEAR_ITEM_FROM_CART', payload : cartItem})
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Hidden>        
        <img src={cartItem.product_thumbnail} alt="Product" />
        <Typography variant="body1">
          { cartItem.product_name }
        </Typography>
        <Hidden mdUp>
          <IconButton 
            onClick={e => {
              e.preventDefault()
              dispacth({type : 'CLEAR_ITEM_FROM_CART', payload : cartItem})
            }}
            className={classes.deleteBtn}>
            <DeleteIcon />
          </IconButton>
        </Hidden>
      </Box>
      <Box className={classes.price} display="flex" alignItems="center" justifyContent="space-between">
        <Hidden mdUp>
          <Typography variant="body1">
            Pris
          </Typography>
        </Hidden>
        <Typography variant="body1">
          { `${ numberFormat(cartItem.selling_price) } ${currency}` }
        </Typography>
      </Box>
      <Box className={classes.quantity} display="flex" alignItems="center">
        <Hidden mdUp>
          <Typography variant="body1" className={classes.fieldTitle}>
            Kvantitet
          </Typography>
        </Hidden>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button 
            variant="contained"
            className={classNames({[classes.disableBtn]: count === 1})}
            color="green" 
            onClick={() => { dispacth({type: 'REMOVE_ITEM_FROM_CART', payload: {...cartItem, quantity: 1}}) }}
          >
            -
          </Button>
          <Typography variant="body1">
            { cartItem.quantity }
          </Typography>
          <Button variant="contained" color="green" onClick={() => dispacth({type: 'ADD_ITEM_TO_CART', payload : {...cartItem, quantity: 1}})}>
            +
          </Button>
        </Box>
           
      </Box>
      <Box className={classes.total} display="flex" alignItems="center" justifyContent="space-between">
        <Hidden mdUp>
          <Typography variant="body1" className={classes.fieldTitle}>
            Delsumma
          </Typography>
        </Hidden>
        <Typography variant="body1">
          { `${ numberFormat(cartItem.quantity * cartItem.selling_price) } ${currency}` }
        </Typography>
      </Box>
    </Box>
  )
}

export default CartItem
