import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import { Box, Hidden } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Button from "components/CustomForms/Button"
import TextField from "components/CustomForms/TextField"
import CustomModal from "components/CustomModal"
import ModalHeader from "components/CustomModal/ModalHeader"
import ModalContents from "components/CustomModal/ModalContents"
import RadioCard from "components/RadioCard"

import styles from "assets/jss/views/admin/userViewStyle"

const useStyles = makeStyles(styles)

const EditUser = (props) => {

  const classes = useStyles()
  
  const { open, closeModal } = props

  const [gender, setGender] = useState("Male")

  const handleChangeGender = (e) => {
    setGender(e.target.value)
  }

  return (
    <CustomModal open={open} closeModal={closeModal} >
      <ModalHeader title="Edit User" closeModal={closeModal} />
      <ModalContents>
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Fullständiga namn"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input first name",
                  defaultValue: "Nungky Reza"
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="E-postadress"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input phone number",
                  defaultValue: "nungkyreza02@gmail.com"
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Telefonnummer"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input street name and number",
                  defaultValue: "+628557042809"
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" className={classes.radioLabel}>
                Gender <span>*</span>
              </Typography>
              <Hidden smDown>
                <FormControl component="fieldset">
                  <RadioGroup name="shipping-method" value={gender} onChange={handleChangeGender}>                  
                    <Grid container className={classes.methodContainer}>
                      <Grid item lg={4} md={4}>
                        <RadioCard option={{option: "Male"}} small selected={gender === "Male"} />
                      </Grid>
                      <Grid item lg={4} md={4}>
                        <RadioCard option={{option: "Female"}} small selected={gender === "Female"} />
                      </Grid>
                      <Grid item lg={4} md={4}>
                        <RadioCard option={{option: "Others"}} small selected={gender === "Others"} />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Hidden>
              <Hidden mdUp>
                <Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className={classes.select}
                  fullWidth
                >
                  <MenuItem value="Male">
                    Male
                  </MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </Hidden>              
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Street Address"
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input street name and number",
                  defaultValue: "1901 Thornridge Cir. Shiloh, Hawaii 81063"
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField               
                inputProps={{
                  type: "text",
                  fullWidth: true,
                  placeholder: "Input apartment number, floor, etc(Optional)"                  
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Lösenord"
                inputProps={{
                  type: "password",
                  fullWidth: true,
                  placeholder: "",
                  defaultValue: "123",
                  endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                }} 
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                labelText="Repeat Lösenord"
                inputProps={{
                  type: "password",
                  fullWidth: true,
                  placeholder: "",
                  defaultValue: "123",
                  endAdornment: <InputAdornment position="end"><VisibilityOffOutlinedIcon /></InputAdornment>
                }} 
              />
            </Grid>
          </Grid>
        </Container>        
      </ModalContents>
      <Box className={classes.modalFooter} textAlign="right">
        <Button variant="simple" className={classes.cancelBtn} onClick={ closeModal }>
          Cancel
        </Button>
        <Button variant="contained" color="green" onClick={ closeModal }>
          Uppdatering
        </Button>
      </Box>
    </CustomModal>
  )
}
export default EditUser
