import React from 'react'
import { Switch, Route } from "react-router-dom"

import { Box } from '@material-ui/core';

import MarketNavbar from "components/Navbars/MarketNavbar"
import MarketFooter from "components/Footer/MarketFooter"

import routes from "routes.js"
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export default function Market() {

  const getRoutes = routes => {

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "") {
        if (prop.protected) {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }

      } else {
        return null
      }
    });
  };

  return (
    <Box>
      <MarketNavbar />
      <Switch>
        {getRoutes(routes)}
      </Switch>
      <MarketFooter />
    </Box>
  )
}

const PrivateRoute = (prop) => {
  let { isLoggedIn } = useSelector(item => item.user)
  if(isLoggedIn) {
    return (
      <Route
        path={prop.path}
        component={prop.component}
        key={prop.key}
      />
    )
  }else{
    return (
      <Route
      path={prop.path}
      render={({location}) => (
        <Redirect 
          to={{
            pathname: "/login",
            state: { from: location }
          }}
        />
      )}
      key={prop.key}
    />
    )
  }
  
}
